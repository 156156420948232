import {
  Card,
  Select,
  Space,
  Form,
  Row,
  Button,
  Tooltip,
  Input,
  Spin,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useContext } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useWebSocket } from "../../Context/WebsocketContext/WebsocketContext";
import { useFormValueContext } from "../../Context/FormContext/FormContext";



const DeletionForm = () => {
  const { user } = useContext(UserContext);
  const {connectionID} = useWebSocket();
  const { setProductName } = useFormValueContext();
  const [showLoading, setShowLoading] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [inProgressProjects, setInProgressProjects] = useState([]);
  const navigate = useNavigate();
  const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";
  const authToken = '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';

  const userMail = user.email;
  // const userMail = "chaihr@deloitte.com";
  // const baseURL = process.env.REACT_APP_API_URL;
  // const authToken = process.env.REACT_APP_AUTH_TOKEN;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      let productPaylaod = {};
      productPaylaod = {
        method: "get",
        what_to_get: "productdetailsInProgress",
        username: userMail,
        status: "InProgress",
      };
      console.log(productPaylaod);

      try {
        
        console.log("try inside");
        const prodResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(productPaylaod)
        );
        const data = JSON.parse(prodResponse.data.body);
        const projects = data.InProgressProjects;
        setInProgressProjects(projects);
        setdeleteLoading(false);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };
    fetchProducts();
  }, []);

  console.log(connectionID);

  // destroy Form handle

  const handleSelectChange = (value) => {
    setSelectedProduct(value);
    const selectedProject = inProgressProjects.find(
      (project) => project.ProductName === value
    );
    if (selectedProject) {
      const {
        productName,
        database,
        cloud,
        frontend,
        frontend_techstack,
        frontendService,
        backend,
        microservices,
        backendMicroservices,
        backendService,
        backendtechstack,
        dbType,
      } = selectedProject;
    }
    setProductName(value);
  };

  //extracting name and username from email
  const uName = user.email.split("@")[0];
  const idValue = user.name + " (" + uName + ")";
  const initialValues = {
    userid: idValue,
  };

  const handleDestroyForm = (values) => {
    const productNames = values.productValue;
    console.log(values);
    setShowLoading(true);

    const selectedProject = inProgressProjects.find(
      (project) => project.productname === productNames
    );
    console.log(selectedProject);

    if (selectedProject) {
      const {
        productname,
        database,
        cloud,
        frontend,
        frontend_techstack,
        frontend_service,
        backend,
        microservices,
        backend_microservices,
        backend_service,
        backend_techstack,
      } = selectedProject;

      console.log(
        " data : ",
        productname,
        database,
        cloud,
        frontend,
        frontend_techstack,
        frontend_service,
        backend,
        microservices,
        backend_microservices,
        backend_service,
        backend_techstack,
        database
      );

      let destroyPayload = {};
      if (cloud === "aws") {
        destroyPayload = {
          event_type: "aws-api",
          client_payload: {
            aws_parameter: {
              name: productname,
              username: userMail,
              action: "Destroy",
              frontend: frontend,
              "frontend-techStack": frontend_techstack,
              "frontend-service": frontend_service,
              backend: backend,
              microservices: microservices,
              "backend-microservices": backend_microservices,
              "backend-service": backend_service,
              "backend-techStack": backend_techstack,
              database: database,
              connectionId: connectionID,
            },
          },
        };
      } else if (cloud === "gcp") {
        destroyPayload = {
          event_type: "gcp-api",
          client_payload: {
            gcp_parameter: {
              name: productname,
              username: userMail,
              action: "Destroy",
              frontend: frontend,
              "frontend-techStack": frontend_techstack,
              "frontend-service": frontend_service,
              backend: backend,
              microservices: microservices,
              "backend-microservices": backend_microservices,
              "backend-service": backend_service,
              "backend-techStack": backend_techstack,
              database: database,
              connectionId: connectionID,
            },
          },
        };
      } else if (cloud === "azure") {
        destroyPayload = {
          event_type: "azure-api",
          client_payload: {
            azure_parameter: {
              name: productname,
              username: userMail,
              action: "Destroy",
              frontend: frontend,
              "frontend-techStack": frontend_techstack,
              "frontend-service": frontend_service,
              backend: backend,
              microservices: microservices,
              "backend-microservices": backend_microservices,
              "backend-service": backend_service,
              "backend-techStack": backend_techstack,
              database: database,
              connectionId: connectionID,
            },
          },
        };
      }

      console.log(destroyPayload);

      axios
        .post(
          `https://api.github.com/repos/Deloitte-US/Prod-HiCloud/dispatches`,
          JSON.stringify(destroyPayload),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((response) => {
          console.log("API Response:", response);
          setTimeout(() => {
            const state = {
              product: productname,
            };
            console.log(state);
            navigate("/deleting", { state });
            setShowLoading(false);
          }, 7 * 1000);
        })
        .catch((error) => {
          console.error("Error calling API:", error);
        });
    }
  };

  return (
    <div>
      <Row justify="center" align="center" style={{ marginTop: "20vh" }}>
        <Card justify="center" style={{ width: "35rem" }}>
         
            <h1 level={5}  style={{ marginLeft: "2rem" }} >
              Infrastructure Deletion Form
              <Tooltip title="View user manual">
                <a
                   href="https://amedeloitte.sharepoint.com/:p:/s/HUDevopsSupport-Relay/Ee5A8mwOirNLoIU6CVRkaOUBy19BPriE6d_9NaCfe2L7Ww?e=eUWcZr&nav=eyJzSWQiOjI2OCwiY0lkIjoyMzQ2MjM5ODE2fQ"
                  target=" _blank"
                >
                  <QuestionCircleOutlined
                    style={{ color: "black", fontSize: "16px", padding: 8 }}
                  />
                </a>
              </Tooltip>
            </h1>
            {console.log(inProgressProjects)}
            <Space direction="vertical" align="center">
              
            <Spin spinning={showLoading}>
           
              <Form
                style={{ marginLeft: "2rem" }}
                layout="vertical"
                onFinish={handleDestroyForm}
                initialValues={initialValues}
              >
                {/* Display user name */}
                <Form.Item
                  required="true"
                  label={<span className="custom-label">Username</span>}
                  name="userid"
                >
                  <Input disabled style={{ fontweight: "bold" }} />
                </Form.Item>
                {/* select the product name */}
                <Form.Item
                  label={<span className="custom-label">Select Product</span>}
                  value="productName"
                  name="productValue"
                  extra="Please select the product whose infrastructure has to be deleted"
                  rules={[
                    {
                      required: true,
                      message: "Select a product to delete ",
                    },
                  ]}
                >
                  
                  <Select onChange={handleSelectChange} value={selectedProduct}>
                    {deleteLoading ? (<Spin size="small"/>) : <> 
                    {inProgressProjects.map((project) => (
                      <Select.Option
                        key={project.productname}
                        value={project.productname}
                      >
                        {project.productname}
                      </Select.Option>
                    ))}
                    </>}
                  </Select>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Destroy
                </Button>
              </Form>
            </Spin>
          </Space>
        </Card>
      </Row>
    </div>
  );
};

export default DeletionForm;

