import { Button, Card, Col, Form, Input, Row, Space } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React from "react";

const AddGlobalAdmin = () => {
  const [addAdminForm] = Form.useForm();
  const baseURL = process.env.REACT_APP_API_URL;

  const onFinishAdmin = async (values) => {
    console.log(values);

    let addAdminPayload = {};
    addAdminPayload = {
      method: "store",
      what_to_store: "admin_detail",
      username: values.adminName,
    };

    console.log("paylaoad:", addAdminPayload);

    try {
      const additionResponse = await axios.post(`${baseURL}/Prod/store`, JSON.stringify(addAdminPayload));
      console.log("Addition Response", additionResponse);
      addAdminForm.resetFields();
    }
    catch(error){
      console.log("addition Error", error);
    }


  };
  return (
    <>
      <div>
        <Row justify="center" align="center">
          <Card
            style={{
              marginTop: "15vh",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              width: "650px",
            }}
          >
            <Space>
              <Title level={5}>Add Global Admin</Title>
            </Space>
            <p>
              Please fill the below details to add the global admin to the tool
            </p>
            <br />

            <Form
              form={addAdminForm}
              layout="vertical"
              onFinish={onFinishAdmin}
            >
              <Form.Item
                label={<span>Enter the email id of the user </span>}
                name="adminName"
                extra="Please enter the email of the user"
                rules={[
                  {
                    required: true,
                    message: "Please enter the email of the user",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/* <Form.Item
                label={<span>Please enter the github id of the user </span>}
                name="gitUserId"
                rules={[
                  {
                    required: true,
                    message: "Please enter the github-id of the user",
                  },
                ]}
              >
                <Input />
              </Form.Item> */}
              <Col span={3}>
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
              </Col>
            </Form>
          </Card>
        </Row>
      </div>
    </>
  );
};

export default AddGlobalAdmin;
