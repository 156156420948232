import {
  Card,
  Form,
  Input,
  Button,
  Radio,
  Drawer,
  Select,
  message,
  Descriptions,
  InputNumber,
  Tooltip,
  Divider,
  Row,
  Col,
  Modal,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { React, useState, useEffect, useContext } from "react";
import "./InfraForm.css";
import axios from "axios";
import { calculateCost } from "../../components/InfraForm/costCalculator";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContext/UserContext";
import { useFormValueContext } from "../../Context/FormContext/FormContext";
import { useWebSocket } from "../../Context/WebsocketContext/WebsocketContext";

const InfraForm = () => {
  const [openDrawerAws, setOpenDrawerAws] = useState(false);
  const [openDrawerGcp, setOpenDrawerGcp] = useState(false);
  const [openDrawerAzure, setOpenDrawerAzure] = useState(false);
  const [reviewData, setReviewData] = useState({});
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);
  const {connectionID} =useWebSocket();
  const {setProductName} =useFormValueContext();
  const [gcpBackendService, setgcpBackendService] = useState("");
  const [backendTechstackGcp, setbackendTechstackGcp] = useState("");
  const [azureBackendService, setAzureBackendService] = useState("");
  const [backendTechstackAzure, setbackendTechstackAzure] = useState("");
  const navigate = useNavigate();
  const [gitName, setgitName] = useState("");
  const [status, setstatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [cost, setCost] = useState(0);
  const [error, setError] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [aws, setaws] = useState(false);
  const [gcp, setgcp] = useState(false);
  const [azure, setazure] = useState(false);
  const [prodName, setProdName] = useState("");
  const [setNameStatus] = useState(false);
  const [productnameStatus, setProductNameStatus] = useState(false);
  const [isNameVerified, setIsNameVerified] = useState(false);
  const [redirected] = useState(false);
  const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";
  const authToken = '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';

  let userName = user.name;
    function reorderName(name) {
    const nameParts = name.split(",").map((part) => part.trim());
    const reversedName = nameParts.reverse();
    const correctOrderName = reversedName.join(" ");
    return correctOrderName;
  }
  const formattedName = reorderName(userName);

  const date = new Date().toLocaleString();

  useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.clientHeight;
    document.documentElement.scrollLeft = document.documentElement.clientWidth;
    window.scrollTo(0, 0);
    
  }, []);

  const handleawsChange = () => {
    setaws(true);
    setgcp(false);
    setazure(false);
  };

  const handlegcpChange = () => {
    setaws(false);
    setgcp(true);
    setazure(false);
  };
  const handleazureChange = () => {
    setaws(false);
    setgcp(false);
    setazure(true);
  };

  const reviewValues = (changedValues, allValues) => {
    setReviewData(allValues);
    if (aws || gcp || azure) {
      const calculatedCost = calculateCost(allValues);
      if (!isDoneButtonDisabled ()) {
        if (calculatedCost > 20) {
          setCost(calculatedCost);
        } else if (cost < 20) {
          setCost(20);
        }
      }
    } 
  };

  const isDoneButtonDisabled = () => {
    if (openDrawerAws) {
      const {
        repoTypeAws,
        frontendAws,
        frontendTechstackAws,
        frontendServiceAws,
        backendAws,
        backendtechstackAws,
        backendServiceAws,
        dbTypeAws,
        microservicesAws,
      } = form.getFieldsValue();

      // Check if Repo Type is undefined or not selected
      if (repoTypeAws === undefined) {
        return true;
      }

      // Check if both Frontend and Backend are selected as "No" or not selected at all
      if (
        (frontendAws === "No" || frontendAws === undefined) &&
        (backendAws === "No" || backendAws === undefined)
      ) {
        return true;
      }

      // Check if Frontend is selected as "Yes" but its subfields are undefined or not selected
      if (
        frontendAws === "Yes" &&
        (!frontendTechstackAws ||
          !frontendServiceAws ||
          frontendTechstackAws === undefined ||
          frontendServiceAws === undefined)
      ) {
        return true;
      }

      // Check if Backend is selected as "Yes" but its subfields are undefined or not selected
      if (
        (backendAws === "Yes" || backendAws === undefined) &&
        (!backendtechstackAws ||
          !backendServiceAws ||
          backendtechstackAws === undefined ||
          backendServiceAws === undefined ||
          dbTypeAws === undefined ||
          microservicesAws === undefined ) 
      ) {
        return true;
      }

      return false;
    } else if (openDrawerGcp) {
      const {
        repoTypeGcp,
        frontendGcp,
        frontendTechstackGcp,
        frontendServiceGcp,
        backendGcp,
        backendTechstackGcp,
        backendServiceGcp,
        dbTypeGcp,
        microservicesGcp,
      } = form.getFieldsValue();

      // Check if Repo Type is undefined or not selected
      if (repoTypeGcp === undefined) {
        return true;
      }

      // Check if both Frontend and Backend are selected as "No" or not selected at all
      if (
        (frontendGcp === "No" || frontendGcp === undefined) &&
        (backendGcp === "No" || backendGcp === undefined)
      ) {
        return true;
      }

      // Check if Frontend is selected as "Yes" but its subfields are undefined or not selected
      if (
        frontendGcp === "Yes" &&
        (!frontendTechstackGcp ||
          !frontendServiceGcp ||
          frontendTechstackGcp === undefined ||
          frontendServiceGcp === undefined)
      ) {
        return true;
      }

      // Check if Backend is selected as "Yes" but its subfields are undefined or not selected
      if (
        (backendGcp === "Yes" || backendGcp === undefined) &&
        (!backendTechstackGcp ||
          !backendServiceGcp ||
          backendTechstackGcp === undefined ||
          backendServiceGcp === undefined ||
          dbTypeGcp === undefined ||
          microservicesGcp === undefined)
      ) {
        return true;
      }

      return false;
    } else if (openDrawerAzure) {
      const {
        repoTypeAzure,
        frontendAzure,
        frontendTechstackAzure,
        frontendServiceAzure,
        backendAzure,
        backendTechstackAzure,
        backendServiceAzure,
        dbTypeAzure,
        microservicesAzure,
      } = form.getFieldsValue();

      // Check if Repo Type is undefined or not selected
      if (repoTypeAzure === undefined) {
        return true;
      }

      // Check if both Frontend and Backend are selected as "No" or not selected at all
      if (
        (frontendAzure === "No" || frontendAzure === undefined) &&
        (backendAzure === "No" || backendAzure === undefined)
      ) {
        return true;
      }

      // Check if Frontend is selected as "Yes" but its subfields are undefined or not selected
      if (
        frontendAzure === "Yes" &&
        (!frontendTechstackAzure ||
          !frontendServiceAzure ||
          frontendTechstackAzure === undefined ||
          frontendServiceAzure === undefined)
      ) {
        return true;
      }

      // Check if Backend is selected as "Yes" but its subfields are undefined or not selected
      if (
        (backendAzure === "Yes" || backendAzure === undefined) &&
        (!backendTechstackAzure ||
          !backendServiceAzure ||
          backendTechstackAzure === undefined ||
          backendServiceAzure === undefined ||
          dbTypeAzure === undefined ||
          microservicesAzure === undefined)
      ) {
        return true;
      }

      return false;
    }
  };



  const handleGcpBackendService = (value) => {
    setgcpBackendService(value);
    setbackendTechstackGcp("");
  };

  const handleAzureBackendService = (value) => {
    setAzureBackendService(value);
    setbackendTechstackAzure("");
  };

  const showDrawerAws = () => {
    setOpenDrawerAws(true);
    handleawsChange();
  };
  const onCloseAws = () => {
    setOpenDrawerAws(false);
  };
  // Drawer functions for GCP
  const showDrawerGCP = () => {
    setOpenDrawerGcp(true);
    handlegcpChange();
  };
  const onCloseGcp = () => {
    setOpenDrawerGcp(false);
  };
  // Drawer functions for Azure
  const showDrawerAzure = () => {
    setOpenDrawerAzure(true);
    handleazureChange();
  };
  const onCloseAzure = () => {
    setOpenDrawerAzure(false);
  };
  const handleReset = () => {
    form.resetFields();
    setReviewData(false);
    setIsVerified(false);
    setstatus(false);
    setIsNameVerified(false);
    setNameStatus(false);
    setProductNameStatus(false);
    setaws(false);;
    setgcp(false);
    setazure(false);
    setCost(0);
  };

  const handleGitChange = (event) => {
    setgitName(event.target.value);
  };
  const handleNameChange = (event) => {
    const store = event.target.value;
    setProdName(store);
  };

  const inputStyles = {
    backgroundColor: status === "success" ? "#fff" : undefined,
  };
  // name Validator
  const handleNameVerification = async (rule, value, callback) => {
    // let converted = prodName.toLowerCase().replace(/[^a-z0-9]/g, "");
    let converted= prodName;
    if (converted.length < 3) {
      setProductNameStatus("error");
      setIsNameVerified(false);
      callback("Please enter a name");
      return;
    } else if (!(/^[a-z0-9]+$/.test(converted))){
      setProductNameStatus("error");
      setIsNameVerified (false);
      callback("Special Characters are not allowed");
    }
    else {
      let namePayload = {};
      namePayload = {
        method: "get",
        what_to_get: "productname",
      };
      try {
        const nameResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(namePayload)
        );
        const namesArray = nameResponse.data.body;
        if (
          converted !== "" &&
          converted !== undefined &&
          converted.length > 3
        ) {
          if (!namesArray.includes(converted)) {
            setProductNameStatus("success");
            setIsNameVerified(true);
            callback();
          } else {
            setProductNameStatus("error");
            setIsNameVerified(false);
            callback(
              "The given name already exists. Please try a different one."
            );
          }
        }
      } catch (error) {
        // console.log("validation failed", error);
        setNameStatus("error");
        setIsNameVerified(false);
        callback("An error occurred while validating the name.");
      }
    }
  };

  // git verification custom validator
  const handleVerification = (rule, value, callback) => {
    if (gitName) {
      const verifyStatus = async () => {
        try {
          const response = await axios.get(
            `https://api.github.com/orgs/Deloitte-US/members/${gitName}`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );

          const status = response.status;
          if (status === 204) {
            setstatus("success");
            setIsVerified(true);
            callback();
          } else {
            setstatus("error");
            callback("verification failed!");
            setIsVerified(false);
          }
        } catch (error) {
          // console.log("validation failed", error);
          setstatus("error");
          setIsVerified(false);
        }
      };
      verifyStatus();
    }
  };

  const handleCreateButton= ()=>{
    if (isVerified && isNameVerified){
      return false;
    } else {
      return true;
    }
  };

  //extracting name and username from email
  const uName = user.email.split("@")[0];
  const idValue = user.name + " (" + uName + ")";
  const initialValues = {
    userid: idValue,
  };
  const userMail = user.email;

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && validateFileType(file)) {
      setSelectedFile(file);
      setError("");
    } else {
      setSelectedFile(null);
      setError("Unsupported file format. Please select an image file.");
    }
  };
  const validateFileType = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    return allowedTypes.includes(file.type);
  };
  const handleModalClose = () => {
    if (!redirected) {
      navigate("/");
      Modal.destroyAll();
    }
  };
  // console.log(connectionID);

  const emailApi = async (emailValues, prodStatus, mailType) => {
    const converted = emailValues.name.toLowerCase().replace(/[^a-z0-9]/g, "");
    const cloudtype = emailValues.cloudProvider;

    let emailPayload = {};
    if (cloudtype === "aws") {
      const backendValueAws = emailValues.backendAws;
      const frontendValueAws = emailValues.frontendAws;
      if (backendValueAws === "No" && frontendValueAws === "Yes") {
        emailPayload = {
          productname: converted,
          cloud: cloudtype,
          username: userMail,
          uname: formattedName,
          mail_type: mailType,
          database: "Service not selected",
          repo_type: emailValues.repoTypeAws,
          frontend: emailValues.frontendAws,
          frontend_techstack: emailValues.frontendTechstackAws,
          frontend_service: emailValues.frontendServiceAws,
          backend: "Service not selected",
          microservices: "Service not selected",
          backend_service: "Service not selected",
          backend_techstack: "Service not selected",
          backend_microservices: "Service not selected",
          product_description: emailValues.description,
          status: prodStatus,
        };
      } else if (backendValueAws === "Yes" && frontendValueAws === "No") {
        let msString = (emailValues.backendMicroservicesAws ?? "").toString();
        emailPayload = {
          productname: converted,
          username: userMail,
          uname: formattedName,
          cloud: cloudtype,
          database: emailValues.dbTypeAws,
          mail_type: mailType,
          repo_type: emailValues.repoTypeAws,
          frontend: "Service not selected",
          frontend_techstack: "Service not selected",
          frontend_service: "Service not selected",
          backend: emailValues.backendAws,
          microservices: emailValues.microservicesAws,
          backend_service: emailValues.backendServiceAws,
          backend_techstack: emailValues.backendtechstackAws,
          backend_microservices: msString,
          product_description: emailValues.description,
          status: prodStatus,
        };
      } else if (backendValueAws === "No" && frontendValueAws === "No") {
        emailPayload = {
          productname: converted,
          username: userMail,
          uname: formattedName,
          cloud: cloudtype,
          database: "Service not selected",
          mail_type: mailType,
          repo_type: emailValues.repoTypeAws,
          frontend: "Service not selected",
          frontend_techstack: "Service not selected",
          frontend_service: "Service not selected",
          backend: "Service not selected",
          microservices: "Service not selected",
          backend_service: "Service not selected",
          backend_techstack: "Service not selected",
          backend_microservices: "Service not selected",
          product_description: emailValues.description,
          status: prodStatus,
        };
      } else {
        let msString = (emailValues.backendMicroservicesAws ?? "").toString();
        emailPayload = {
          productname: converted,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: emailValues.dbTypeAws,
          repo_type: emailValues.repoTypeAws,
          frontend: emailValues.frontendAws,
          frontend_techstack: emailValues.frontendTechstackAws,
          frontend_service: emailValues.frontendServiceAws,
          backend: emailValues.backendAws,
          microservices: emailValues.microservicesAws,
          backend_service: emailValues.backendServiceAws,
          backend_techstack: emailValues.backendtechstackAws,
          backend_microservices: msString,
          product_description: emailValues.description,
          status: prodStatus,
        };
      }
    } else if (cloudtype === "gcp") {
      const backendValueGcp = emailValues.backendGcp;
      const frontendValueGcp = emailValues.frontendGcp;

      if (backendValueGcp === "No" && frontendValueGcp === "Yes") {
        emailPayload = {
          productname: converted,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: "Service not selected",
          repo_type: emailValues.repoTypeGcp,
          frontend: emailValues.frontendGcp,
          frontend_techstack: emailValues.frontendTechstackGcp,
          frontend_service: emailValues.frontendServiceGcp,
          backend: "Service not selected",
          backend_microservices: "Service not selected",
          microservices: "Service not selected",
          backend_service: "Service not selected",
          backend_techstack: "Service not selected",
          product_description: emailValues.description,
          status: prodStatus,
        };
      } else if (backendValueGcp === "Yes" && frontendValueGcp === "No") {
        let msString = (emailValues.backendMicroservicesGcp ?? "").toString();
        emailPayload = {
          productname: converted,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: emailValues.dbTypeGcp,
          repo_type: emailValues.repoTypeGcp,
          frontend: "Service not selected",
          frontend_techstack: "Service not selected",
          frontend_service: "Service not selected",
          backend: emailValues.backendGcp,
          microservices: emailValues.microservicesGcp,
          backend_service: emailValues.backendServiceGcp,
          backend_techstack: emailValues.backendTechstackGcp,
          backend_microservices: msString,
          product_description: emailValues.description,
          status: prodStatus,
        };
      } else if (backendValueGcp === "No" && frontendValueGcp === "No") {
        emailPayload = {
          productname: converted,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: null,
          repo_type: emailValues.repoTypeGcp,
          frontend: "Service not selected",
          backend: "Service not selected",

          product_description: emailValues.description,
          status: prodStatus,
        };
      } else {
        let msString = (emailValues.backendMicroservicesGcp ?? "").toString();
        emailPayload = {
          productname: converted,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: emailValues.dbTypeGcp,
          repo_type: emailValues.repoTypeGcp,
          frontend: emailValues.frontendGcp,
          frontend_techstack: emailValues.frontendTechstackGcp,
          frontend_service: emailValues.frontendServiceGcp,
          backend: emailValues.backendGcp,
          microservices: emailValues.microservicesGcp,
          backend_service: emailValues.backendServiceGcp,
          backend_techstack: emailValues.backendTechstackGcp,
          backend_microservices: msString,
          product_description: emailValues.description,
          status: prodStatus,
        };
      }
    } else if (cloudtype === "azure") {
      const backendValueAzure = emailValues.backendAzure;
      const frontendValueAzure = emailValues.frontendAzure;
      if (backendValueAzure === "No" && frontendValueAzure === "Yes") {
        emailPayload = {
          productname: converted,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: "Service not selected",
          repo_type: emailValues.repoTypeAzure,
          frontend: emailValues.frontendAzure,
          frontend_techstack: emailValues.frontendTechstackAzure,
          frontend_service: emailValues.frontendServiceAzure,
          backend: "Service not selected",
          microservices: "Service not selected",
          backend_service: "Service not selected",
          backend_techstack: "Service not selected",
          backend_microservices: "Service not selected",
          product_description: emailValues.description,
          status: prodStatus,
        };
      } else if (backendValueAzure === "Yes" && frontendValueAzure === "No") {
        let msString = (emailValues.backendMicroservicesAzure ?? "").toString();
        emailPayload = {
          productname: converted,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: emailValues.dbTypeAzure,
          repo_type: emailValues.repoTypeAzure,
          frontend: "Service not selected",
          frontend_techstack: "Service not selected",
          frontend_service: "Service not selected",
          backend: emailValues.backendAzure,
          microservices: emailValues.microservicesAzure,
          backend_service: emailValues.backendServiceAzure,
          backend_techstack: emailValues.backendTechstackAzure,
          backend_microservices: msString,
          product_description: emailValues.description,
          status: prodStatus,
        };
      } else if (backendValueAzure === "No" && frontendValueAzure === "No") {
        emailPayload = {
          productname: converted,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          database: null,
          mail_type: mailType,
          repo_type: emailValues.repoTypeAzure,
          frontend: "Service not selected",
          backend: "Service not selected",
          product_description: emailValues.description,
          status: prodStatus,
        };
      } else {
        let msString = (emailValues.backendMicroservicesAzure ?? "").toString();
        emailPayload = {
          productname: converted,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: emailValues.dbTypeAzure,
          repo_type: emailValues.repoTypeAzure,
          frontend: emailValues.frontendAzure,
          frontend_techstack: emailValues.frontendTechstackAzure,
          frontend_service: emailValues.frontendServiceAzure,
          backend: emailValues.backendAzure,
          microservices: emailValues.microservicesAzure,
          backend_service: emailValues.backendServiceAzure,
          backend_techstack: emailValues.backendTechstackAzure,
          backend_microservices: msString,
          product_description: emailValues.description,
          status: prodStatus,
        };
      }
    }

    

    try {
      // email ack of the selection
      const emailResponse = await axios.post(
        `${baseURL}/Prod/send_mail`,
        JSON.stringify(emailPayload)
      );
      console.log("email resp",emailResponse);
    } catch (error) {
      console.log("Email Error:", error);
    }
  };

  const storeProdApi = async (storeValues, productStatus) => {
    const converted = storeValues.name.toLowerCase().replace(/[^a-z0-9]/g, "");

    const cloudtype = storeValues.cloudProvider;

    let storeProductPayload = {};
    if (cloudtype === "aws") {
      const backendValueAws = storeValues.backendAws;
      const frontendValueAws = storeValues.frontendAws;
      if (backendValueAws === "No" && frontendValueAws === "Yes") {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: null,
          repo_type: storeValues.repoTypeAws,
          frontend: storeValues.frontendAws,
          frontend_techstack: storeValues.frontendTechstackAws,
          frontend_service: storeValues.frontendServiceAws,
          backend: storeValues.backendAws,
          microservices: null,
          backend_service: null,
          backend_techstack: null,
          backend_microservices: 0,
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
          co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      } else if (backendValueAws === "Yes" && frontendValueAws === "No") {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: storeValues.dbTypeAws,
          repo_type: storeValues.repoTypeAws,
          frontend: storeValues.frontendAws,
          frontend_techstack: null,
          frontend_service: null,
          backend: storeValues.backendAws,
          microservices: storeValues.microservicesAws,
          backend_service: storeValues.backendServiceAws,
          backend_techstack: storeValues.backendtechstackAws,
          backend_microservices: storeValues.backendMicroservicesAws,
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
           co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      } else if (backendValueAws === "No" && frontendValueAws === "No") {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: null,
          repo_type: storeValues.repoTypeAws,
          frontend: storeValues.frontendAws,
          frontend_techstack: null,
          frontend_service: null,
          backend: storeValues.backendAws,
          microservices: null,
          backend_service: null,
          backend_techstack: null,
          backend_microservices: null,
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
           co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      } else {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: storeValues.dbTypeAws,
          repo_type: storeValues.repoTypeAws,
          frontend: storeValues.frontendAws,
          frontend_techstack: storeValues.frontendTechstackAws,
          frontend_service: storeValues.frontendServiceAws,
          backend: storeValues.backendAws,
          microservices: storeValues.microservicesAws,
          backend_service: storeValues.backendServiceAws,
          backend_techstack: storeValues.backendtechstackAws,
          backend_microservices: storeValues.backendMicroservicesAws,
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
           co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      }
    } else if (cloudtype === "gcp") {
      const backendValueGcp = storeValues.backendGcp;
      const frontendValueGcp = storeValues.frontendGcp;

      if (backendValueGcp === "No" && frontendValueGcp === "Yes") {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: null,
          repo_type: storeValues.repoTypeGcp,
          frontend: storeValues.frontendGcp,
          frontend_techstack: storeValues.frontendTechstackGcp,
          frontend_service: storeValues.frontendServiceGcp,
          backend: storeValues.backendGcp,
          microservices: null,
          backend_service: null,
          backend_techstack: null,
          backend_microservices: 0,
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
           co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      } else if (backendValueGcp === "Yes" && frontendValueGcp === "No") {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: storeValues.dbTypeGcp,
          repo_type: storeValues.repoTypeGcp,
          frontend: storeValues.frontendGcp,
          frontend_techstack: null,
          frontend_service: null,
          backend: storeValues.backendGcp,
          microservices: storeValues.microservicesGcp,
          backend_service: storeValues.backendServiceGcp,
          backend_techstack: storeValues.backendTechstackGcp,
          backend_microservices: storeValues.backendMicroservicesGcp,
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
           co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      } else if (backendValueGcp === "No" && frontendValueGcp === "No") {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: null,
          repo_type: storeValues.repoTypeGcp,
          frontend: storeValues.frontendGcp,
          frontend_techstack: null,
          frontend_service: null,
          backend: storeValues.backendGcp,
          microservices: null,
          backend_service: null,
          backend_techstack: null,
          backend_microservices: null,
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
           co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      } else {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: storeValues.dbTypeGcp,
          repo_type: storeValues.repoTypeGcp,
          frontend: storeValues.frontendGcp,
          frontend_techstack: storeValues.frontendTechstackGcp,
          frontend_service: storeValues.frontendServiceGcp,
          backend: storeValues.backendGcp,
          microservices: storeValues.microservicesGcp,
          backend_service: storeValues.backendServiceGcp,
          backend_techstack: storeValues.backendTechstackGcp,
          backend_microservices: storeValues.backendMicroservicesGcp,
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
           co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      }
    } else if (cloudtype === "azure") {
      const backendValueAzure = storeValues.backendAzure;
      const frontendValueAzure = storeValues.frontendAzure;
      if (backendValueAzure === "No" && frontendValueAzure === "Yes") {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: null,
          repo_type: storeValues.repoTypeAzure,
          frontend: storeValues.frontendAzure,
          frontend_techstack: storeValues.frontendTechstackAzure,
          frontend_service: storeValues.frontendServiceAzure,
          backend: storeValues.backendAzure,
          microservices: null,
          backend_service: null,
          backend_techstack: null,
          backend_microservices: 0,
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
           co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      } else if (backendValueAzure === "Yes" && frontendValueAzure === "No") {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: storeValues.dbTypeAzure,
          repo_type: storeValues.repoTypeAzure,
          frontend: storeValues.frontendAzure,
          frontend_techstack: null,
          frontend_service: null,
          backend: storeValues.backendAzure,
          microservices: storeValues.microservicesAzure,
          backend_service: storeValues.backendServiceAzure,
          backend_techstack: storeValues.backendTechstackAzure,
          backend_microservices: storeValues.backendMicroservicesAzure,
          runner_id: "",
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
           co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      } else if (backendValueAzure === "No" && frontendValueAzure === "No") {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: null,
          repo_type: storeValues.repoTypeAzure,
          frontend: storeValues.frontendAzure,
          frontend_techstack: null,
          frontend_service: null,
          backend: storeValues.backendAzure,
          microservices: null,
          backend_service: null,
          backend_techstack: null,
          backend_microservices: null,
          runner_id: "",
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
           co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      } else {
        storeProductPayload = {
          method: "store",
          what_to_store: "product_detail",
          productname: converted,
          status: productStatus,
          username: userMail,
          cloud: cloudtype,
          uname: uName,
          database: storeValues.dbTypeAzure,
          repo_type: storeValues.repoTypeAzure,
          frontend: storeValues.frontendAzure,
          frontend_techstack: storeValues.frontendTechstackAzure,
          frontend_service: storeValues.frontendServiceAzure,
          backend: storeValues.backendAzure,
          microservices: storeValues.microservicesAzure,
          backend_service: storeValues.backendServiceAzure,
          backend_techstack: storeValues.backendTechstackAzure,
          backend_microservices: storeValues.backendMicroservicesAzure,
          runner_id: "",
          product_description: storeValues.description,
          date_time: date,
          github_id: storeValues.git_userName,
           co_owner: storeValues.coOwner,
          watchlistuser: storeValues.watchlist,
        };
      }
    }

    try {
      // store api call
      const storeResponse = await axios.post(
        `${baseURL}/Prod/store`,
        JSON.stringify(storeProductPayload)
      );

      console.log("store payload", storeResponse);
    } catch (error) {
      console.log("Storing Error:", error);
    }
  };

  // form finish handle function
  const onFinishForm = async (values) => {
    
    const frontend =
      values.frontendAws || values.frontendAzure || values.frontendGcp;
    const backend =
      values.backendAws || values.backendAzure || values.backendGcp;
  
    if (
      (frontend === "No" && backend === "No") ||
      (frontend === undefined && backend === undefined)
    ) {
      Modal.error({
        title: "Error",
        content: "Please choose atleast one of the infrastructure to continue",
      });
    } else {
      Modal.confirm({
        content: (
          <p>Are you sure you want to proceed with these selected Services? </p>
        ),
        onOk: () => {
          if (cost >= 100) {
            Modal.info({
              title: "Approval is required",
              content: (
                <div>
                  <p>
                    As your product's infrastructure cost is more than the
                    permitted limit. It <b>requires an approval</b> we've
                    notified the concerned people. Please take the necessary
                    actions once you receive the response mail.{" "}
                  </p>
                  <br />{" "}
                  <span style={{ fontSize: "11px" }}>
                    {" "}
                    For any further assistance you can contact{" "}
                    <a href="mailto:USHashedinHiCloudSupport@deloitte.com">
                      {" "}
                      USHashedinHiCloudSupport@deloitte.com
                    </a>
                  </span>{" "}
                </div>
              ),
              onCancel: handleModalClose,
              onOk: handleModalClose,
            });
            emailApi(values, "Pending", "approval");
            emailApi(values, "Pending", "acknowledgement");
            storeProdApi(values, "Pending");
            try {
              const reader = new FileReader();
              reader.onload = async () => {
                const binaryData = reader.result;
                const response = await fetch(
                  `https://gd8i4oq4d0.execute-api.us-east-1.amazonaws.com/Prod/get-image/relay-staging-client-s3/relay2.0-architecture/${selectedFile.name}`,
                  {
                    method: "PUT",
                    body: binaryData,
                    headers: {
                      "Content-Type": "application/octet-stream",
                    },
                  }
                );

                if (response.ok) {
                  console.log("Image uploaded successfully.");
                } else {
                  console.log("Image upload failed.");
                }
              };
              reader.readAsArrayBuffer(selectedFile);
            } catch (error) {
              console.log("Error occurred while uploading the image:", error);
            }
          } else if (cost < 100) {
            handleApiCalls(values);
            const converted = values.name
              .toLowerCase()
              .replace(/[^a-z0-9]/g, "");
            
            // storing image file
            try {
              const reader = new FileReader();
              reader.onload = async () => {
                const binaryData = reader.result;
                const response = await fetch(
                  `https://gd8i4oq4d0.execute-api.us-east-1.amazonaws.com/Prod/get-image/relay-staging-client-s3/relay2.0-architecture/${selectedFile.name}`,
                  {
                    method: "PUT",
                    body: binaryData,
                    headers: {
                      "Content-Type": "application/octet-stream",
                    },
                  }
                );

                if (response.ok) {
                  console.log("Image uploaded successfully.");
                } else {
                  console.log("Image upload failed.");
                }
              };
              reader.readAsArrayBuffer(selectedFile);
            } catch (error) {
              console.log("Error occurred while uploading the image:", error);
            }
            setTimeout(() => {
              form.resetFields();
              setProductName(converted);
              navigate("/creating");
            }, 2 * 1000);
          }
        },
        onCancel: () => {
          Modal.destroyAll();
        },
      });
    }
  };

  const handleApiCalls = async (payloadValues) => {
    const converted = payloadValues.name
      .toLowerCase()
      .replace(/[^a-z0-9]/g, "");

    // workflow payload
    let workFlowPayload = {};
    if (payloadValues.cloudProvider === "aws") {
      workFlowPayload = {
        event_type: "aws-api",
        client_payload: {
          aws_parameter: {
            name: converted,
            username: userMail,
            git_username: payloadValues.git_userName,
            "repo-type": payloadValues.repoTypeAws,
            action: "Create",
            frontend: payloadValues.frontendAws,
            "frontend-techStack": payloadValues.frontendTechstackAws,
            "frontend-service": payloadValues.frontendServiceAws,
            backend: payloadValues.backendAws,
            microservices: payloadValues.microservicesAws,
            "backend-microservices": payloadValues.backendMicroservicesAws,
            "backend-service": payloadValues.backendServiceAws,
            "backend-techStack": payloadValues.backendtechstackAws,
            database: payloadValues.dbTypeAws,
            connectionId: connectionID,
          },
        },
      };
    } else if (payloadValues.cloudProvider === "gcp") {
      workFlowPayload = {
        event_type: "gcp-api",
        client_payload: {
          gcp_parameter: {
            name: converted,
            username: userMail,
            git_username: payloadValues.git_userName,
            "repo-type": payloadValues.repoTypeGcp,
            action: "Create",
            frontend: payloadValues.frontendGcp,
            "frontend-techStack": payloadValues.frontendTechstackGcp,
            "frontend-service": payloadValues.frontendServiceGcp,
            backend: payloadValues.backendGcp,
            microservices: payloadValues.microservicesGcp,
            "backend-microservices": payloadValues.backendMicroservicesGcp,
            "backend-service": payloadValues.backendServiceGcp,
            "backend-techStack": payloadValues.backendTechstackGcp,
            database: payloadValues.dbTypeGcp,
            connectionId: connectionID,
          },
        },
      };
    } else if (payloadValues.cloudProvider === "azure") {
      workFlowPayload = {
        event_type: "azure-api",
        client_payload: {
          azure_parameter: {
            name: converted,
            username: userMail,
            git_username: payloadValues.git_userName,
            "repo-type": payloadValues.repoTypeAzure,
            action: "Create",
            frontend: payloadValues.frontendAzure,
            "frontend-techStack": payloadValues.frontendTechstackAzure,
            "frontend-service": payloadValues.frontendServiceAzure,
            backend: payloadValues.backendAzure,
            microservices: payloadValues.microservicesAzure,
            "backend-microservices": payloadValues.backendMicroservicesAzure,
            "backend-service": payloadValues.backendServiceAzure,
            "backend-techStack": payloadValues.backendTechstackAzure,
            database: payloadValues.dbTypeAzure,
            connectionId: connectionID,
          },
        },
      };
    }

    try {
      emailApi(payloadValues, "Creating", "acknowledgement");
      storeProdApi(payloadValues, "Approved_ByDefault");
      // Dispatch API for triggering the creation
      const creationWorkFlowResponse = await axios.post(
        `https://api.github.com/repos/Deloitte-US/Prod-HiCloud/dispatches`,
        JSON.stringify(workFlowPayload),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(" Dispatch Response:", creationWorkFlowResponse);
    } catch (error) {
      console.log("Error:", error);
      if (error.reposnse) {
        const { status } = error.response;
        if (status === 400) {
          message.error("Bad Request");
        } else if (status === 401) {
          message.error("Unauthorised");
        } else if (status === 422) {
          message.error("data undefined");
        } else {
          message.error("An error occured");
        }
      } else {
        message.error("An error occured");
      }
    }
  };

  return (
    <div className="infra-form-layout">
      <Card
        style={{
          marginTop: "4vh",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          width: "800px",
        }}
      >
        <div className="form-header">
          <h1>
            Infrastructure Creation Form
            <Tooltip title="View User Manual">
              <a
                href="https://amedeloitte.sharepoint.com/:p:/s/HUDevopsSupport-Relay/Ee5A8mwOirNLoIU6CVRkaOUBy19BPriE6d_9NaCfe2L7Ww?e=27b8Cu&nav=eyJzSWQiOjI2NywiY0lkIjozNTc3MjY4MjQxfQ"
                target=" _blank"
              >
                <QuestionCircleOutlined
                  style={{ color: "black", fontSize: "16px", padding: 8 }}
                />
              </a>
            </Tooltip>
          </h1>
        </div>
        <div className="infra-form">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinishForm}
            onValuesChange={reviewValues}
            initialValues={initialValues}
          >
            <Form.Item required="true" label="Username" name="userid">
              <Input disabled style={{ fontweight: "bold" }} />
            </Form.Item>

            {/* Github username and verification  */}
            <Form.Item
              label={
                <span className="custom-label"> Deloitte Github User-id</span>
              }
              required="true"
            >
              <Row gutter={8} style={inputStyles}>
                <Col span={24} style={inputStyles}>
                  <Form.Item
                    style={inputStyles}
                    name="git_userName"
                    onChange={handleGitChange}
                    hasFeedback
                    validateStatus={status}
                    help={
                      status === "error" && (
                        <span className="custom-rule">
                          {" "}
                          Please enter the Deloitte-github id
                        </span>
                      )
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <span className="custom-rule">
                            {" "}
                            Please enter and verify your github username before
                            proceeding"
                          </span>
                        ),
                      },
                      { validator: handleVerification },
                    ]}
                  >
                    <Input style={inputStyles} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            {/* Product name */}
            <Form.Item
              style={{ marginTop: "-15px" }}
              label={<span className="custom-label"> Product Name</span>}
              name="name"
              onChange={handleNameChange}
              hasFeedback
              validateStatus={productnameStatus}
              extra={
                <span className="custom-extra">
                  Product name doesn't allow duplicate names, special characters, blank spaces and capital letters. 
                </span>
              }
              rules={[
                {
                  required: true,
                  message: (
                    <span className="custom-rule">
                      Please enter the Product Name!
                    </span>
                  ),
                },
                {
                  validator: handleNameVerification,
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* Co-Owner */}
            <Form.Item 
            required="true"
            name="coOwner"
            label={<span className="custom-label">Enter email of the Co-Owner</span>}
            
            rules={[
              {
                required: true,
                message: (
                  <span className="custom-rule">
                    Co-owner is required!!
                  </span>
                ),
              },
            ]}
            >
              <Input />
            </Form.Item>

            {/* WatchList User */}

            <Form.Item 
            required="true"
            name="watchlist"
            label={<span className="custom-label">Enter the email of WatchList Users</span>}
            extra={
              <span className="custom-extra">
                Watch list users only have access and view Logs .
              </span>
            }
            rules={[
              {
                required: true,
                message: (
                  <span className="custom-rule">
                    Please enter atleast 1 watchlist User
                  </span>
                ),
              },
            ]}
            >
              <Input />
            </Form.Item>

            {/* Product description field */}
            <Form.Item
              required="true"
              name="description"
              label={<span className="custom-label">Product description</span>}
              rules={[
                {
                  required: true,
                  message: (
                    <span className="custom-rule">
                      Please enter the Product Description!
                    </span>
                  ),
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* image upload */}
            <Form.Item
              className="upload-button"
              name="archImage"
              label={
                <span className="custom-label">
                  Upload architecture diagram
                </span>
              }
            >
              <input
                type="file"
                className="file-input"
                onChange={handleFileChange}
              />
              {error && <p>{error}</p>}
            </Form.Item>

            {/* select a cloud Provider */}
            <Form.Item
              label={
                <span className="custom-label">Select a Cloud Provider </span>
              }
              name="cloudProvider"
              rules={[
                {
                  required: true,
                  message: (
                    <span className="custom-rule">
                      Please select a cloud type to create the Infrastructre{" "}
                    </span>
                  ),
                },
              ]}
            >
              <Radio.Group>
                <Radio.Button value="aws" onClick={showDrawerAws}>
                  AWS
                </Radio.Button>
                <Radio.Button value="gcp" onClick={showDrawerGCP}>
                  GCP
                </Radio.Button>
                <Radio.Button value="azure" onClick={showDrawerAzure}>
                  Azure
                </Radio.Button>
              </Radio.Group>
            </Form.Item>

            {/* AWS Drawer */}
            <Drawer
              title="Infrastructre Specification"
              placement="right"
              open={openDrawerAws}
              onClose={onCloseAws}
              width={720}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <Divider orientation="left"> Repository Requirements</Divider>
              {/* repo type field */}
              <Form.Item
                name="repoTypeAws"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 9 }}
                label="Repository Type"
                rules={[
                  {
                    required: aws,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(
                          "Please select a repository type to create the Product Infrastructure"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="MonoRepo">Monorepo</Radio>

                  {!azure && <Radio value="SeparateRepo">Separate repo</Radio>}
                </Radio.Group>
              </Form.Item>
              <Divider orientation="left"> Frontend Requirements</Divider>
              <Form.Item
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 9 }}
                label="Frontend"
                name="frontendAws"
                rules={[
                  {
                    required: aws,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>
              {/* frontend subselection */}
              <Form.Item
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                shouldUpdate={(preValues, curValues) =>
                  preValues.frontendAws !== curValues.frontendAws
                }
              >
                {({ getFieldValue }) => {
                  const isYesSelected = getFieldValue("frontendAws") === "Yes";

                  return (
                    <>
                      {isYesSelected && (
                        <>
                          {/* select frontend Techstack */}
                          <Form.Item
                            label=" Frontend framework"
                            name="frontendTechstackAws"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            rules={[
                              {
                                required: aws,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="react">React</Select.Option>
                              <Select.Option value="angular">
                                Angular
                              </Select.Option>
                            </Select>
                          </Form.Item>

                          {/* Select for frontend Service Selection */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Frontend Service"
                            name="frontendServiceAws"
                            rules={[
                              {
                                required: aws,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="aws_s3_cf">
                                AWS S3 and CloudFront
                              </Select.Option>
                              <Select.Option value="aws_appruner">
                                AppRunner
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </>
                      )}
                    </>
                  );
                }}
              </Form.Item>

              <Divider orientation="left"> Backend Requirements</Divider>
              <Form.Item
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 9 }}
                label="Backend"
                name="backendAws"
                rules={[
                  {
                    required: aws,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>

              {/* backend subselection */}

              <Form.Item
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                shouldUpdate={(preValues, curValues) =>
                  preValues.backendAws !== curValues.backendAws
                }
              >
                {({ getFieldValue }) => {
                  const isYesSelected = getFieldValue("backendAws") === "Yes";

                  return (
                    <>
                      {isYesSelected && (
                        <>
                          {/* Select for backend techstack Selection */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Backend Framework"
                            name="backendtechstackAws"
                            rules={[
                              {
                                required: aws,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="Java">Java</Select.Option>
                              <Select.Option value="Python">
                                Python
                              </Select.Option>
                            </Select>
                          </Form.Item>

                          {/* backend Service selection */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Backend Service"
                            name="backendServiceAws"
                            rules={[
                              {
                                required: aws,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="aws_apprunner">
                                AppRunner
                              </Select.Option>
                              <Select.Option value="aws_lambda_ecr">
                                Lambda and API gateway
                              </Select.Option>
                            </Select>
                          </Form.Item>

                          {/* database selection */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Database Type"
                            name="dbTypeAws"
                            rules={[
                              {
                                required: aws,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="mysql">MySQL</Select.Option>
                              <Select.Option value="psql">
                                PostgreSQL
                              </Select.Option>
                              <Select.Option value="No">
                                No Database
                              </Select.Option>
                            </Select>
                          </Form.Item>
                          {/* Microservice boolean selection */}
                          <Form.Item
                            label=" Need Microservices?"
                            name="microservicesAws"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            rules={[
                              {
                                required: aws,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="Yes">Yes</Select.Option>
                              <Select.Option value="No">No</Select.Option>
                            </Select>
                          </Form.Item>
                          {/* microservice number */}
                          <Form.Item
                            shouldUpdate={(preValues, curValues) =>
                              preValues.microservicesAws !==
                              curValues.microservicesAws
                            }
                          >
                            {({ getFieldValue }) => {
                              const isYesSelected =
                                getFieldValue("microservicesAws") === "Yes";

                              return (
                                <>
                                  {isYesSelected && (
                                    <Form.Item
                                      style={{ marginTop: "-5px" }}
                                      labelCol={{ span: 7 }}
                                      wrapperCol={{ span: 9 }}
                                      name="backendMicroservicesAws"
                                      label="Number of microservices "
                                      rules={[
                                        {
                                          required: aws,
                                          type: "number",
                                          min: 1,
                                          max: 10,
                                          message:
                                            "Please enter a number between 1 and 10",
                                        },
                                      ]}
                                    >
                                      <InputNumber />
                                    </Form.Item>
                                  )}
                                </>
                              );
                            }}
                          </Form.Item>
                        </>
                      )}
                    </>
                  );
                }}
              </Form.Item>
              <Form.Item>
                {/* note */}
                <p style={{ marginLeft: "15px" }}>
                  {" "}
                  <span style={{ fontSize: "14px" }}> Note :</span>
                  <span style={{ fontSize: "12px" }}>
                    {" "}
                    For any additional resources or services, Please write us at{" "}
                    <a
                      target="_blank"
                      href="mailto:USHashedinHiCloudSupport@deloitte.com"
                    >
                      {" "}
                      USHashedinHiCloudSupport@deloitte.com
                    </a>{" "}
                    post creation of the product. One of the Devops person will
                    contact you soon.
                  </span>
                </p>
              </Form.Item>

              <br />

              <Form.Item>
                <Button
                  type="primary"
                  onClick={onCloseAws}
                  disabled={isDoneButtonDisabled()}
                >
                  Done
                </Button>
              </Form.Item>
            </Drawer>
            {/* Aws drawer end  */}

            {/* GCP Drawer Start */}
            <Drawer
              title="Infrastructre Specification"
              placement="right"
              onClose={onCloseGcp}
              open={openDrawerGcp}
              width={720}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <Divider orientation="left"> Repository Requirements</Divider>
              {/* repo type field */}
              <Form.Item
                name="repoTypeGcp"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 9 }}
                label="Repository Type"
                rules={[
                  {
                    required: gcp,
                    message: (
                      <span className="custom-rule">
                        Please select a repository type to create the Product
                        Infrastructure
                      </span>
                    ),
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="MonoRepo">Monorepo</Radio>

                  {!azure && <Radio value="SeparateRepo">Separate repo</Radio>}
                </Radio.Group>
              </Form.Item>
              <Divider orientation="left"> Frontend Requirements</Divider>
              <Form.Item
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 9 }}
                label="Frontend"
                name="frontendGcp"
                rules={[
                  {
                    required: gcp,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>
              {/* frontend subselection */}
              <Form.Item
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                shouldUpdate={(preValues, curValues) =>
                  preValues.frontendGcp !== curValues.frontendGcp
                }
              >
                {({ getFieldValue }) => {
                  const isYesSelected = getFieldValue("frontendGcp") === "Yes";

                  return (
                    <>
                      {isYesSelected && (
                        <>
                          {/* frontend techstack selection */}
                          <Form.Item
                            label=" Frontend framework"
                            name="frontendTechstackGcp"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            rules={[
                              {
                                required: gcp,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="react">React</Select.Option>
                              <Select.Option value="angular">
                                Angular
                              </Select.Option>
                            </Select>
                          </Form.Item>

                          {/* Select for Frontend Service Selection */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Frontend Service"
                            name="frontendServiceGcp"
                            rules={[
                              {
                                required: gcp,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="gcs-cdn">
                                Google Cloud Storage and CDN
                              </Select.Option>
                              <Select.Option value="cloudrun">
                                CloudRun
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </>
                      )}
                    </>
                  );
                }}
              </Form.Item>

              <Divider orientation="left"> Backend Requirements</Divider>
              <Form.Item
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 9 }}
                label="Backend"
                name="backendGcp"
                rules={[
                  {
                    required: gcp,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>
              {/* backend subselection */}
              <Form.Item
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                shouldUpdate={(preValues, curValues) =>
                  preValues.backendGcp !== curValues.backendGcp
                }
              >
                {({ getFieldValue }) => {
                  const isYesSelected = getFieldValue("backendGcp") === "Yes";

                  return (
                    <>
                      {isYesSelected && (
                        <>
                          {/* backend service selection */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Backend Service"
                            name="backendServiceGcp"
                            rules={[
                              {
                                required: gcp,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select
                              onChange={handleGcpBackendService}
                              value={gcpBackendService}
                            >
                              <Select.Option value="gcp_cloud_function">
                                CloudFunction
                              </Select.Option>
                              <Select.Option value="gcp_cloud_run">
                                CloudRun
                              </Select.Option>
                            </Select>
                          </Form.Item>

                          {/* Select for backend techstack Selection */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Backend Framework"
                            name="backendTechstackGcp"
                            rules={[
                              {
                                required: gcp,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select
                              onChange={setbackendTechstackGcp}
                              value={backendTechstackGcp}
                              disabled={!gcpBackendService}
                            >
                              {gcpBackendService === "gcp_cloud_function" && (
                                <>
                                  <Select.Option value="Python">
                                    Python
                                  </Select.Option>
                                  <Select.Option value="Nodejs">
                                    Node JS
                                  </Select.Option>
                                </>
                              )}

                              {gcpBackendService === "gcp_cloud_run" && (
                                <>
                                  <Select.Option value="Java">
                                    Java
                                  </Select.Option>
                                  <Select.Option value="Python">
                                    Python
                                  </Select.Option>
                                </>
                              )}
                            </Select>
                          </Form.Item>

                          {/* Select database for backend (gcp) */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Database Type"
                            name="dbTypeGcp"
                            rules={[
                              {
                                required: gcp,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="mysql">MySQL</Select.Option>
                              <Select.Option value="psql">
                                PostgreSQL
                              </Select.Option>
                              <Select.Option value="No">
                                No Database
                              </Select.Option>
                            </Select>
                          </Form.Item>

                          {/* select microservice */}
                          <Form.Item
                            label=" Need Microservices?"
                            name="microservicesGcp"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            rules={[
                              {
                                required: gcp,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="Yes">Yes</Select.Option>
                              <Select.Option value="No">No</Select.Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            shouldUpdate={(preValues, curValues) =>
                              preValues.microservicesGcp !==
                              curValues.microservicesGcp
                            }
                          >
                            {({ getFieldValue }) => {
                              const isYesSelected =
                                getFieldValue("microservicesGcp") === "Yes";

                              return (
                                <>
                                  {/* number of microservice */}
                                  {isYesSelected && (
                                    <Form.Item
                                      style={{ marginTop: "-5px" }}
                                      labelCol={{ span: 7 }}
                                      wrapperCol={{ span: 9 }}
                                      name="backendMicroservicesGcp"
                                      label="Number of microservices "
                                      rules={[
                                        {
                                          required: gcp,
                                          type: "number",
                                          min: 0,
                                          max: 10,
                                          message:
                                            "Please enter a number between 1 and 10",
                                        },
                                      ]}
                                    >
                                      <InputNumber />
                                    </Form.Item>
                                  )}
                                </>
                              );
                            }}
                          </Form.Item>
                        </>
                      )}
                    </>
                  );
                }}
              </Form.Item>
              <Form.Item>
                {/* note */}
                <p style={{ marginLeft: "15px" }}>
                  {" "}
                  <span style={{ fontSize: "14px" }}> Note :</span>
                  <span style={{ fontSize: "12px" }}>
                    {" "}
                    For any additional resources or services, Please write us at{" "}
                    <a
                      target="_blank"
                      href="mailto:USHashedinHiCloudSupport@deloitte.com"
                    >
                      {" "}
                      USHashedinHiCloudSupport@deloitte.com
                    </a>{" "}
                    post creation of the product. One of the Devops person will
                    contact you soon.
                  </span>
                </p>
              </Form.Item>

              <br />

              <Button
                type="primary"
                onClick={onCloseGcp}
                disabled={isDoneButtonDisabled()}
              >
                Done
              </Button>
            </Drawer>
            {/* GCP Drawer end */}

            {/* Azure Drawer start */}
            <Drawer
              title="Infrastructre Specification"
              placement="right"
              onClose={onCloseAzure}
              open={openDrawerAzure}
              width={720}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <Divider orientation="left"> Repository Requirements</Divider>
              {/* repo type field */}
              <Form.Item
                name="repoTypeAzure"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 9 }}
                label="Repository Type"
                rules={[
                  {
                    required: azure,
                    message: (
                      <span className="custom-rule">
                        Please select a repository type to create the Product
                        Infrastructure
                      </span>
                    ),
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="MonoRepo">Monorepo</Radio>
                </Radio.Group>
              </Form.Item>

              <Divider orientation="left"> Frontend Requirements</Divider>
              <Form.Item
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 9 }}
                label="Frontend"
                name="frontendAzure"
                required="true"
                rules={[
                  {
                    required: azure,
                    message: (
                      <span className="custom-rule">
                        Please select an option!
                      </span>
                    ),
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>
              {/* frontend subselection */}
              <Form.Item
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                shouldUpdate={(preValues, curValues) =>
                  preValues.frontendAzure !== curValues.frontendAzure
                }
              >
                {({ getFieldValue }) => {
                  const isYesSelected =
                    getFieldValue("frontendAzure") === "Yes";

                  return (
                    <>
                      {isYesSelected && (
                        <>
                          <Form.Item
                            label=" Frontend framework"
                            name="frontendTechstackAzure"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            rules={[
                              {
                                required: azure,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="react">React</Select.Option>
                              <Select.Option value="angular">
                                Angular
                              </Select.Option>
                            </Select>
                          </Form.Item>

                          {/* Select for backend techstack Selection */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Frontend Service"
                            name="frontendServiceAzure"
                            rules={[
                              {
                                required: azure,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="azure_webapp">
                                Azure Webapp
                              </Select.Option>
                              <Select.Option value="azure-blob-cdn">
                                Azure Blob Storage and CDN
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </>
                      )}
                    </>
                  );
                }}
              </Form.Item>

              <Divider orientation="left"> Backend Requirements</Divider>
              <Form.Item
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 9 }}
                label="Backend"
                name="backendAzure"
                rules={[
                  {
                    required: azure,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>
              {/* backend subselection */}
              <Form.Item
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                shouldUpdate={(preValues, curValues) =>
                  preValues.backendAzure !== curValues.backendAzure
                }
              >
                {({ getFieldValue }) => {
                  const isYesSelected = getFieldValue("backendAzure") === "Yes";

                  return (
                    <>
                      {isYesSelected && (
                        <>
                          {/* backend Service selection */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Backend Service"
                            name="backendServiceAzure"
                            rules={[
                              {
                                required: azure,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select
                              onChange={handleAzureBackendService}
                              value={azureBackendService}
                            >
                              <Select.Option value="azure_webapp">
                                Azure Web App
                              </Select.Option>
                              <Select.Option value="azure_functions_zip">
                                Azure Functions
                              </Select.Option>
                            </Select>
                          </Form.Item>
                          {/* techstack/ framework for backend */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Backend Framework"
                            name="backendTechstackAzure"
                            rules={[
                              {
                                required: azure,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select
                              onChange={setbackendTechstackAzure}
                              value={backendTechstackAzure}
                              disabled={!azureBackendService}
                            >
                              {azureBackendService === "azure_webapp" && (
                                <>
                                  <Select.Option value="Python">
                                    Python
                                  </Select.Option>
                                  <Select.Option value="Java">
                                    Java
                                  </Select.Option>
                                </>
                              )}

                              {azureBackendService ===
                                "azure_functions_zip" && (
                                <>
                                  <Select.Option value="Python">
                                    Python
                                  </Select.Option>
                                </>
                              )}
                            </Select>
                          </Form.Item>

                          {/* Database selection */}
                          <Form.Item
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            label="Database Type"
                            name="dbTypeAzure"
                            rules={[
                              {
                                required: azure,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="mysql">MySQL</Select.Option>
                              <Select.Option value="psql">
                                PostgreSQL
                              </Select.Option>
                              <Select.Option value="No">
                                No Database
                              </Select.Option>
                            </Select>
                          </Form.Item>

                          {/* Microservice boolean selection  */}
                          <Form.Item
                            label=" Need Microservices?"
                            name="microservicesAzure"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 9 }}
                            rules={[
                              {
                                required: azure,
                                message: (
                                  <span className="custom-rule">
                                    Please select an option!
                                  </span>
                                ),
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="Yes">Yes</Select.Option>
                              <Select.Option value="No">No</Select.Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            shouldUpdate={(preValues, curValues) =>
                              preValues.microservicesAzure !==
                              curValues.microservicesAzure
                            }
                          >
                            {/* Microservice number */}
                            {({ getFieldValue }) => {
                              const isYesSelected =
                                getFieldValue("microservicesAzure") === "Yes";

                              return (
                                <>
                                  {isYesSelected && (
                                    <Form.Item
                                      style={{ marginTop: "-5px" }}
                                      labelCol={{ span: 7 }}
                                      wrapperCol={{ span: 9 }}
                                      name="backendMicroservicesAzure"
                                      label="Number of microservices "
                                      rules={[
                                        {
                                          required: azure,
                                          type: "number",
                                          min: 1,
                                          max: 10,
                                          message:
                                            "Please enter a number between 1 and 10",
                                        },
                                      ]}
                                    >
                                      <InputNumber />
                                    </Form.Item>
                                  )}
                                </>
                              );
                            }}
                          </Form.Item>
                        </>
                      )}
                    </>
                  );
                }}
              </Form.Item>
              <Form.Item>
                {/* note */}
                <p style={{ marginLeft: "15px" }}>
                  {" "}
                  <span style={{ fontSize: "14px" }}> Note :</span>
                  <span style={{ fontSize: "12px" }}>
                    {" "}
                    For any additional resources or services, Please write us at{" "}
                    <a
                      target="_blank"
                      href="mailto:USHashedinHiCloudSupport@deloitte.com"
                    >
                      {" "}
                      USHashedinHiCloudSupport@deloitte.com
                    </a>{" "}
                    post creation of the product. One of the Devops person will
                    contact you soon.
                  </span>
                </p>
              </Form.Item>

              <br />

              <Form.Item>
                <Button
                  type="primary"
                  onClick={onCloseAzure}
                  disabled={isDoneButtonDisabled()}
                >
                  Done
                </Button>
              </Form.Item>
            </Drawer>
            {/* Azure Drawer end */}

            {/* Aws review Values */}
            {Object.keys(reviewData).length > 0 &&
              reviewData.cloudProvider === "aws" && (
                <div>
                  <Descriptions
                    title="Review Data"
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                  >
                    <Descriptions.Item label="Frontend">
                      {reviewData.frontendAws}
                    </Descriptions.Item>
                    {reviewData.frontendAws !== "No" &&
                      reviewData.frontendAws !== undefined && (
                        <>
                          <Descriptions.Item label="Frontend Techstack">
                            {reviewData.frontendAws === "No" ||
                            reviewData.frontendAws === undefined ||
                            reviewData.frontendTechstackAws === undefined
                              ? "N/A"
                              : reviewData.frontendTechstackAws === "react"
                              ? "React"
                              : "Angular"}
                          </Descriptions.Item>

                          <Descriptions.Item label="Frontend Service">
                            {reviewData.frontendAws === "No" ||
                            reviewData.frontendAws === undefined ||
                            reviewData.frontendServiceAws === undefined
                              ? "N/A"
                              : reviewData.frontendServiceAws === "aws_s3_cf"
                              ? "AWS S3 and CloudFront"
                              : "AppRunner"}
                          </Descriptions.Item>
                        </>
                      )}

                    <Descriptions.Item label="BackendAws">
                      {reviewData.backendAws}
                    </Descriptions.Item>

                    {reviewData.backendAws !== "No" &&
                      reviewData.backendAws !== undefined && (
                        <>
                          <Descriptions.Item label="Microservices">
                            {reviewData.backendAws !== "No"
                              ? reviewData.microservicesAws
                              : "N/A"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Number of Microservices">
                            {reviewData.backendAws === "No" ||
                            (reviewData.backendAws === "Yes" &&
                              reviewData.microservicesAws === "No")
                              ? "N/A"
                              : reviewData.backendMicroservicesAws}{" "}
                          </Descriptions.Item>
                          <Descriptions.Item label="Backend Service">
                            {reviewData.backendAws === "No" ||
                            reviewData.backendAws === undefined ||
                            reviewData.backendServiceAws === undefined
                              ? "N/A"
                              : reviewData.backendServiceAws === "aws_apprunner"
                              ? "AppRunner"
                              : "Lambda and API gateway"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Backend Techstack">
                            {reviewData.backendAws === "No" ||
                            reviewData.backendAws === undefined ||
                            reviewData.backendServiceAws === undefined
                              ? "N/A"
                              : reviewData.backendtechstackAws}{" "}
                          </Descriptions.Item>
                          <Descriptions.Item label="Database">
                            {reviewData.backendAws === "No" ||
                            reviewData.dbTypeAws === undefined ||
                            reviewData.dbTypeAws === "No"
                              ? "N/A"
                              : reviewData.dbTypeAws === "mysql"
                              ? "Mysql"
                              : "PostgreSQL"}
                          </Descriptions.Item>
                        </>
                      )}
                  </Descriptions>
                </div>
              )}

            {/* GCP review Values */}

            {Object.keys(reviewData).length > 0 &&
              reviewData.cloudProvider === "gcp" && (
                <div>
                  <Descriptions
                    title="Review Data"
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                  >
                    <Descriptions.Item label="Frontend">
                      {reviewData.frontendGcp}{" "}
                    </Descriptions.Item>

                    {reviewData.frontendGcp !== "No" && (
                      <>
                        <Descriptions.Item label="Frontend Techstack">
                          {reviewData.frontendGcp === "No" ||
                          reviewData.frontendGcp === undefined || reviewData.frontendTechstackGcp === undefined
                            ? "N/A"
                            : reviewData.frontendTechstackGcp === "react"
                            ? "React"
                            : "Angular"}{" "}
                        </Descriptions.Item>
                        <Descriptions.Item label="Frontend Service">
                          {reviewData.frontendGcp === "No" ||
                          reviewData.frontendGcp === undefined ||
                          reviewData.frontendServiceGcp === undefined
                            ? "N/A"
                            : reviewData.frontendServiceGcp === "gcs-cdn"
                            ? "Google Cloud Storage and CDN"
                            : "CloudRun"}{" "}
                        </Descriptions.Item>
                      </>
                    )}

                    <Descriptions.Item label="Backend">
                      {reviewData.backendGcp}{" "}
                    </Descriptions.Item>
                    {reviewData.backendGcp !== "No" && (
                      <>
                        <Descriptions.Item label="Microservices">
                          {reviewData.backendGcp !== "No"
                            ? reviewData.microservicesGcp
                            : "N/A"}{" "}
                        </Descriptions.Item>
                        <Descriptions.Item label="Number of Microservices">
                          {reviewData.backendGcp === "No" ||
                          (reviewData.backendGcp === "Yes" &&
                            reviewData.microservicesGcp === "No")
                            ? "N/A"
                            : reviewData.backendMicroservicesGcp}{" "}
                        </Descriptions.Item>
                        <Descriptions.Item label="Backend Service">
                          {reviewData.backendGcp === "No" ||
                          reviewData.backendGcp === undefined ||
                          reviewData.backendServiceGcp === undefined
                            ? "N/A"
                            : reviewData.backendServiceGcp ===
                              "gcp_cloud_function"
                            ? "CloudFunction"
                            : "CloudRun"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Backend Techstack">
                          {reviewData.backendGcp !== "No"
                            ? reviewData.backendTechstackGcp
                            : "N/A"}{" "}
                        </Descriptions.Item>
                        <Descriptions.Item label="Database">
                          {reviewData.backendGcp === "No" ||
                          reviewData.dbTypeGcp === undefined ||
                          reviewData.dbTypeGcp === "No"
                            ? "N/A"
                            : reviewData.dbTypeGcp === "mysql"
                            ? "Mysql"
                            : "PostgreSQL"}
                        </Descriptions.Item>
                      </>
                    )}
                  </Descriptions>
                </div>
              )}

            {/* Azure review Values */}
            {Object.keys(reviewData).length > 0 &&
              reviewData.cloudProvider === "azure" && (
                <div>
                  <Descriptions
                    title="Review Data"
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                  >
                    <Descriptions.Item label="Frontend">
                      {reviewData.frontendAzure}{" "}
                    </Descriptions.Item>

                    {reviewData.frontendAzure !== "No" && (
                      <>
                        <Descriptions.Item label="Frontend Techstack">
                          {reviewData.frontendAzure === "No" || reviewData.frontendAzure === undefined||
                          reviewData.frontendTechstackAzure === undefined 
                            ? "N/A"
                            : reviewData.frontendTechstackAzure === "react" ?
                            "React" : "Angular"
                          }
                        </Descriptions.Item>
                        <Descriptions.Item label="Frontend Service">
                          {reviewData.frontendAzure === "No" ||
                          reviewData.frontendAzure === undefined ||
                          reviewData.frontendServiceAzure === undefined
                            ? "N/A"
                            : reviewData.frontendServiceAzure === "azure_webapp"
                            ? "Azure Webapp"
                            : "Azure Blob Storage and CDN"}{" "}
                        </Descriptions.Item>
                      </>
                    )}
                    <Descriptions.Item label="Backend">
                      {reviewData.backendAzure}{" "}
                    </Descriptions.Item>
                    {reviewData.backendAzure !== "No" && (
                      <>
                        <Descriptions.Item label="Backend Service">
                          {reviewData.backendAzure === "No" ||
                          reviewData.backendAzure === undefined ||
                          reviewData.backendServiceAzure === undefined
                            ? "N/A"
                            : reviewData.backendServiceAzure === "azure_webapp"
                            ? "Azure WebApp"
                            : "Azure Functions"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Microservices">
                          {reviewData.backendAzure !== "No"
                            ? reviewData.microservicesAzure
                            : "N/A"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Number of Microservices">
                          {reviewData.backendAzure === "No" ||
                          (reviewData.backendAzure === "Yes" &&
                            reviewData.microservicesAzure === "No")
                            ? "N/A"
                            : reviewData.backendMicroservicesAzure}
                        </Descriptions.Item>
                        <Descriptions.Item label="Backend Techstack">
                          {reviewData.backendAzure !== "No"
                            ? reviewData.backendTechstackAzure
                            : "N/A"}{" "}
                        </Descriptions.Item>
                        <Descriptions.Item label="Database">
                          {reviewData.backendAzure === "No" ||
                          reviewData.dbTypeAzure === undefined ||
                          reviewData.dbTypeAzure === "No"
                            ? "N/A"
                            : reviewData.dbTypeAzure === "mysql"
                            ? "Mysql"
                            : "PostgreSQL"}
                        </Descriptions.Item>
                      </>
                    )}
                  </Descriptions>
                </div>
              )}
            <br />

            <table
              style={{
                border: "1px solid #00000030",
                borderRadius: "10px",
                padding: 5,
              }}
            >
              <th style={{ fontStyle: "normal" }}> Cost :</th>
              <td style={{ paddingLeft: 5 }}>${cost}/ month </td>
            </table>

            <br />

            <Row gutter={2}>
              <Col span={3}>
                <Form.Item>
                  <Button type="primary" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Button type="primary" htmlType="submit" disabled={handleCreateButton()}>
                  Create
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default InfraForm;
