import React, { createContext, useContext, useState } from 'react';

const FormValueContext = createContext();

export const FormValueContextProvider = ({ children }) => {
  const [infraFromData, setInfraFromData] = useState({});
  const [productName, setProductName] = useState(null);

  return (
    <FormValueContext.Provider value={{ infraFromData, setInfraFromData, productName, setProductName }}>
      {children}
    </FormValueContext.Provider>
  );
};

export const useFormValueContext = () =>{
  return useContext(FormValueContext);
}
