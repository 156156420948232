import React from "react";
import { Pie, PieChart, Cell } from "recharts";
import { Space } from "antd";

const Doughnut = ({ activeNumber, inactiveNumber, pendingNumber }) => {
  const data = [
    { name: "Active", value: activeNumber },
    { name: "Inactive", value: inactiveNumber },
    { name: "Pending", value: pendingNumber },
  ];
  const COLORS = ["#77b81e", "#c82c29", "#eb965e"];

  return (
    <>
      <Space direction="horizontal">
        <div>
          {data.map((entry, index) => (
            <div
              key={`legend-${index}`}
              className="legend-item"
              style={{ marginBottom: "10px" }}
            >
              <Space direction="horizontal" align="baseline" gap={3}>
                <div
                  className="legend-color"
                  style={{
                    backgroundColor: COLORS[index],
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    marginLeft: "-10px",
                  }}
                ></div>
                <div className="legend-label">
                  <Space direction="horizontal" align="start">
                    <span className="legend-name" style={{ fontSize: "12px" }}>
                      {entry.name}:{" "}
                    </span>
                    <span className="legend-value" style={{ fontSize: "12px" }}>
                      {" "}
                      {entry.value}
                    </span>
                  </Space>
                </div>
              </Space>
            </div>
          ))}
        </div>
        <div st>
          <PieChart width={200} height={100}>
            <Pie
              data={data}
              cx={27}
              innerRadius={25}
              outerRadius={30}
              dataKey="value"
              isAnimationActive={true}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </div>
      </Space>
    </>
  );
};

export default Doughnut;
