import { Space } from "antd";
import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import {
  CheckCircleFilled,
  InfoCircleFilled,
  DeleteFilled,
  ClockCircleFilled,
} from "@ant-design/icons";
import axios from "axios";

import BarChart from "../../components/BarChart/BarChart";
import DoughNut from "../../components/Doughnut/Doughnut";


const AdminDashboard = () => {
  const baseURL = process.env.REACT_APP_API_URL;
  const [activeProduct, setActiveProduct] = useState(0);
  const [inactiveProduct, setInActiveProduct] = useState(0);
  const [pendingProduct, setPendingProduct] = useState(0);
  const [approvedProduct, setApprovedProduct] = useState(0);
  const [combinedResponses, setCombinedResponses] = useState([]);
  const [awsCount, setAwsCount] = useState(0);
  const [gcpCount, setGcpCount] = useState(0);
  const [azureCount, setAzureCount] = useState(0);

  useEffect(() => {
    const fetchActiveProducts = async () => {
      let activePaylaod = {};
      activePaylaod = {
        method: "get",
        what_to_get: "productdetails_admin",
        status: "InProgress",
      };

      try {
        // console.log("b", baseURL);
        const activeResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(activePaylaod)
        );
        console.log("resp active", activeResponse);
        const activeData = activeResponse.data.body;
        setCombinedResponses ((prevResponses) => [...prevResponses, ...activeData])
        setActiveProduct(Object.keys(activeData).length);
    
        const awsProductsCount = activeData.filter(product => product.cloud === 'aws').length;
        const gcpProductsCount = activeData.filter(product => product.cloud === 'gcp').length;
        const azureProductsCount = activeData.filter(product => product.cloud === 'azure').length;
    
        console.log("AWS Count:", awsProductsCount);
        console.log("GCP Count:", gcpProductsCount);
        console.log("Azure Count:", azureProductsCount);

        setAwsCount(awsProductsCount);
        setGcpCount(gcpProductsCount);
        setAzureCount(azureProductsCount);


      } catch (error) {
        console.log(error);
      }
    };
    fetchActiveProducts();

    const fetchInactiveProducts = async () => {
      let inActivePayload = {};
      inActivePayload = {
        method: "get",
        what_to_get: "productdetails_admin",
        status: "Deleted",
      };
      try {
        const inActiveResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(inActivePayload)
        );
        console.log("resp inactive", inActiveResponse);
        const inActiveData = inActiveResponse.data.body;
        setCombinedResponses ((prevResponses) => [...prevResponses, ...inActiveData])
        setInActiveProduct(Object.keys(inActiveData).length);
        console.log("inActive count:", inactiveProduct);
      } catch (error) {
        console.log(error);
      }
    };
    fetchInactiveProducts();

    const fetchpendingProducts = async () => {
      let pendingPayload = {};
      pendingPayload = {
        method: "get",
        what_to_get: "productdetails_admin",
        status: "Pending",
      };
      try {
        const pendingResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(pendingPayload)
        );
        const pendingData = pendingResponse.data.body;
        const pendingCount = Object.keys(pendingData).length;
        setPendingProduct(pendingCount);
        console.log("pending count", pendingProduct);
      } catch (error) {
        console.log(error);
      }
    };
    fetchpendingProducts();

    const fetchapprovedProducts = async () => {
      let approvedPayload = {};
      approvedPayload = {
        method: "get",
        what_to_get: "productdetails_admin",
        status: "Approved",
      };
      try {
        const approvedResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(approvedPayload)
        );
        console.log("resp approved", approvedResponse);
        const approvedData = approvedResponse.data.body;
        setApprovedProduct(Object.keys(approvedData).length);
        console.log("approved prod:", approvedProduct);
        console.log("combined",combinedResponses);
      } catch (error) {
        console.log(error);
      } 
    };
    fetchapprovedProducts();
  }, []);

  return (
    <div className="admin-container">
      <div class="card-row">
        <div class="card">
          <Space direction="horizontal" align="center" size={15}>
            <div>
              <Space direction="vertical" size={5}>
                <span
                  style={{
                    fontSize: "30px",
                    color: "#4A8AC6",
                    fontWeight: 800,
                    wordWrap: "break-word",
                  }}
                >
                  {" "}
                  {activeProduct}
                </span>{" "}
                <br />
                <span
                  style={{
                    fontSize: "16px",
                    color: "#4A8AC6",
                    fontWeight: 800,
                    wordWrap: "break-word",
                  }}
                >
                  Active Products
                </span>
              </Space>
            </div>
            <div style={{ fontSize: "66px", color: "green" }}>
              <CheckCircleFilled />
            </div>
          </Space>
        </div>
        <div class="card">
          <Space direction="horizontal" align="center" size={30}>
            <div>
              <Space direction="vertical" size={6}>
                <span
                  style={{
                    fontSize: "30px",
                    color: "#4A8AC6",
                    fontWeight: 800,
                    wordWrap: "break-word",
                  }}
                >
                  {" "}
                  {pendingProduct}
                </span>{" "}
                <br />
                <span
                  style={{
                    fontSize: "15px",
                    color: "#4A8AC6",
                    fontWeight: 800,
                    wordWrap: "break-word",
                    textAlign: "justify",
                  }}
                >
                  Products to be Approved
                </span>
              </Space>
            </div>
            <div style={{ fontSize: "66px", color: "#FFB347" }}>
              <InfoCircleFilled />
            </div>
          </Space>
        </div>
        <div class="card">
          <Space direction="horizontal" align="center" size={30}>
            <div>
              <Space direction="vertical" size={5}>
                <span
                  style={{
                    fontSize: "30px",
                    color: "#4A8AC6",
                    fontWeight: 800,
                    wordWrap: "break-word",
                  }}
                >
                  {" "}
                  {approvedProduct}
                </span>{" "}
                <br />
                <span
                  style={{
                    fontSize: "16px",
                    color: "#4A8AC6",
                    fontWeight: 800,
                    wordWrap: "break-word",
                  }}
                >
                  {" "}
                  Approved Products
                </span>
              </Space>
            </div>
            <div style={{ fontSize: "66px", color: "#808080" }}>
              <div>
                <ClockCircleFilled />
              </div>
            </div>
          </Space>
        </div>
        <div class="card">
          <Space direction="horizontal" align="center" size={30}>
            <div>
              <Space direction="vertical" size={5}>
                <span
                  style={{
                    fontSize: "30px",
                    color: "#4A8AC6",
                    fontWeight: 800,
                    wordWrap: "break-word",
                    paddingBottom: "-10px",
                  }}
                >
                  {" "}
                  {inactiveProduct}
                </span>{" "}
                <br />
                <span
                  style={{
                    fontSize: "16px",
                    color: "#4A8AC6",
                    fontWeight: 800,
                    wordWrap: "break-word",
                  }}
                >
                  {" "}
                  Deleted Products
                </span>
              </Space>
            </div>
            <div style={{ fontSize: "66px", color: "#C82C29" }}>
              <DeleteFilled />
            </div>
          </Space>
        </div>
        <div class="card">
          <Space direction="horizontal" align="center" size={30}>
            <div>
              <Space direction="vertical" size={5}>
                <span
                  style={{
                    fontSize: "30px",
                    color: "#4A8AC6",
                    fontWeight: 800,
                    wordWrap: "break-word",
                  }}
                >
                  {" "}
                  {activeProduct +
                    inactiveProduct +
                    approvedProduct +
                    pendingProduct}
                </span>{" "}
                <br />
                <span
                  style={{
                    fontSize: "16px",
                    color: "#4A8AC6",
                    fontWeight: 800,
                    wordWrap: "break-word",
                  }}
                >
                  {" "}
                  All Products
                </span>
              </Space>
            </div>
            <div style={{ fontSize: "66px", color: "#808080" }}>
              <div>
                <ClockCircleFilled />
              </div>
            </div>
          </Space>
        </div>
      </div>
      <div class="graph-row">
        <Space direction="horizontal" size={99}>
          <div className="card piechart-card">
            <div className="doughnut-content">
              <DoughNut awsCount={awsCount} gcpCount={gcpCount} azureCount={azureCount} />
            </div>
          </div>
          <div className="card barchart-card">
            <div className="barchart-content">
              <BarChart combinedResponse={combinedResponses} />
            </div>
          </div>
        </Space>
      </div>
    </div>
  );
};

export default AdminDashboard;
