import { useState, createContext } from "react";
const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [admin, setAdmin] = useState(false);
  const [user, setUser] = useState({});
  const [userRole, setUserRole] = useState(null);

  return (
    <UserContext.Provider value={{ user, setUser, admin,setAdmin,userRole,setUserRole }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
