import React, { useContext, useEffect, useState } from "react";
import ActiveProducts from "./ActiveProducts";
import InactiveProducts from "./InactiveProducts";
import PendingProducts from "./PendingProducts";
import { Card, Col, Row, Tooltip } from "antd";
import axios from "axios";
import { UserContext } from "../../Context/UserContext/UserContext";
import AllProducts from "./AllProducts";
import { QuestionCircleOutlined } from "@ant-design/icons";

const contentList = {
  tab1: <AllProducts />,
  tab2: <ActiveProducts />,
  tab3: <InactiveProducts />,
  tab4: <PendingProducts />,
};

const ProductDetails = () => {
  const { user } = useContext(UserContext);
  const [activeTabKey1, setActiveTabKey1] = useState("tab1");
  const [activeCount, setactiveCount] = useState();
  const [inactiveCount, setinactiveCount] = useState();
  const [pendingCount, setpendingCount] = useState();
  const [approvedCount, setapprovedCount] = useState();
  // const baseURL = process.env.REACT_APP_API_URL;
  const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";
  const userMail = user.email;

  const tabList = [
    {
      key: "tab1",
      tab: (
        <p>
          All ({activeCount + inactiveCount + pendingCount + approvedCount})
        </p>
      ),
    },
    {
      key: "tab2",
      tab: <p> Active ({activeCount})</p>,
    },
    {
      key: "tab3",
      tab: <p>Inactive ({inactiveCount})</p>,
    },
    {
      key: "tab4",
      tab: <p>Pending ({pendingCount + approvedCount})</p>,
    },
  ];

  useEffect(() => {
    const fetchactiveCount = async () => {
      let activePaylaod = {};
      activePaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "InProgress",
      };

      try {
        const activeResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(activePaylaod)
        );
        const activedata = activeResponse.data.body;
        setactiveCount(Object.keys(activedata).length);

      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchactiveCount();
    const fetchInactiveCount = async () => {
      let inActivePaylaod = {};
      inActivePaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Deleted",
      };

      try {
        const inActiveResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(inActivePaylaod)
        );
        const inActivedata = inActiveResponse.data.body;
        setinactiveCount(Object.keys(inActivedata).length);

      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchInactiveCount();
    const fetchPendingCount = async () => {
      let pendingPaylaod = {};
      pendingPaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Pending",
      };

      try {
        const pendingResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(pendingPaylaod)
        );
        const pendingdata = pendingResponse.data.body;
        setpendingCount(Object.keys(pendingdata).length);

      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchPendingCount();

    const fetchApprovedCount = async () => {
      let approvedPaylaod = {};
      approvedPaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Approved",
      };

      try {
        const approvedResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(approvedPaylaod)
        );
        const approveddata = approvedResponse.data.body;
        setapprovedCount(Object.keys(approveddata).length);

      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchApprovedCount();
  }, []);

  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };

  return (
    <div>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col>
          <Card
            style={{
              width: "130vh",
            }}
            title={
              <h1>
                Product Lists
                <Tooltip title="View User Manual">
                  <a
                    href="https://amedeloitte.sharepoint.com/:p:/s/HUDevopsSupport-Relay/Ee5A8mwOirNLoIU6CVRkaOUBy19BPriE6d_9NaCfe2L7Ww?e=cQE5hr&nav=eyJzSWQiOjI3OCwiY0lkIjozNTg0NDM0MDA5fQ"
                    target=" _blank"
                  >
                    <QuestionCircleOutlined
                      style={{ color: "black", fontSize: "16px", padding: 8 }}
                    />
                  </a>
                </Tooltip>
              </h1>
            }
            tabList={tabList}
            activeTabKey={activeTabKey1}
            onTabChange={onTab1Change}
          >
            {contentList[activeTabKey1]}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProductDetails;
