import { Layout, Menu, Button } from "antd";
import Sider from "antd/es/layout/Sider";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FileTextOutlined,
  UserOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
  ProfileOutlined,
  DashboardOutlined,
  UserSwitchOutlined ,
  UserAddOutlined
} from "@ant-design/icons";
import hidlr from "../../assets/HiD Logo Dark rotate.png";
import { React, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./LeftSider.css";
import { UserContext } from "../../Context/UserContext/UserContext";

// function getItem(label, key, icon) {
//   return {
//     key,
//     icon,
//     label,
//   };
// }

// const items = [
//   getItem("User Profile", "/", <UserOutlined style={{ fontSize: "18px" }} />),
//   getItem(
//     "Infra Create",
//     "/create-infra",
//     <FileTextOutlined style={{ fontSize: "18px" }} />
//   ),
//    getItem("Product Lists", "/products-lists", <UnorderedListOutlined style={{ fontSize: "18px" }} />),
//    getItem(
//     "Infra Delete",
//     "/delete-infra",
//     <DeleteOutlined style={{ fontSize: "20px" }} />
//   ),
//   // getItem ("Logs Page", "/logs", <ProfileOutlined style={{ fontSize: "20px" }}/>)
// ];

const LeftSider = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const {admin, userRole} = useContext(UserContext);
  const [userRoleToUse, setUserRoleToUse] = useState("owner");
  const [filteredItems, setfilteredItems] = useState();

  const selectedKey = location.pathname === "/" ? "/" : location.pathname;

  console.log("adin", admin);

  // useEffect(() => {
  //   if (admin === true) {
  //     setUserRoleToUse("admin");
  //   } else if (userRole === null){
  //     setUserRoleToUse("owner");
  //   } else {
  //     setUserRoleToUse(userRole);
  //   }
  // }, [admin, userRole]);

  useEffect(() => {
    if (userRoleToUse) {
      console.log("user role ", userRoleToUse);
      setfilteredItems(
        allItems.filter((item) => {
          return rolePermissions[userRoleToUse].includes(item.label);
        })
      );
    }
  }, [userRoleToUse]);

  const allItems = [
    {
      key: "/",
      icon: <UserOutlined style={{ fontSize: "18px" }} />,
      label: "User Profile",
    },
    {
      key: "/create-infra",
      icon: <FileTextOutlined style={{ fontSize: "18px" }} />,
      label: "Infra Create",
    },
    {
      key: "/products-lists",
      icon: <UnorderedListOutlined style={{ fontSize: "18px" }} />,
      label: "Product Lists",
    },
    {
      key: "/logs",
      icon: <ProfileOutlined style={{ fontSize: "20px" }} />,
      label: "Logs Page",
    },
    {
      key: "/delete-infra",
      icon: <DeleteOutlined style={{ fontSize: "20px" }} />,
      label: "Infra Delete",
    },
    {
      key: "/admin-dashboard",
      icon: <DashboardOutlined  style={{ fontSize: "20px" }} />,
      label: "Dashboard",
    },
    {
      key: "/admin-products-list",
      icon: <UnorderedListOutlined style={{ fontSize: "20px" }} />,
      label: "Products List",
    },
    {
      key: "/add-user-role",
      icon: <UserSwitchOutlined style={{ fontSize: "20px" }} />,
      label: "Manage User Roles",
    },
    {
      key: "/add-admin",
      icon: <UserAddOutlined  style={{ fontSize: "20px" }} />,
      label: "Add New Admin",
    },
  ];

  // const userRoles = ["admin", "owner", "coOwner", "watchList"]; // Example roles

  const rolePermissions = {
    admin: ["Dashboard", "Products List", "Manage User Roles", "Add New Admin"],
    owner: [
      "User Profile",
      "Infra Create",
      "Product Lists",
      "Logs Page",
      "Infra Delete",
    ],
    watchlistuser: ["User Profile", "Logs Page"],
  };

  
  // if ( admin === true){
  //  const  userRoleToUse = "admin";
  // }
  // else {
  //   userRoleToUse=userRole;

  // }
  // const userRoleToUse = admin ? "admin" : userRole || "owner" ;
  // const filteredItems = allItems.filter((item) => {
  //   return rolePermissions[userRoleToUse].includes(item.label);
  // });


  return (
    <div data-testid="leftSider">
      <Layout
        className="left-side-nav"
        style={{
          marginTop: "6vh",
          minHeight: "94vh",
          fontSize: "50px",
          height: "90%",
        }}
      >
        <Sider
          trigger={null}
          collapsed={collapsed}
          style={{
            background: "#4A8AC6",
          }}
        >
          <Button
            type="text"
            onClick={() => setCollapsed(!collapsed)}
            style={{
              width: 64,
              height: 64,
              marginLeft: "10px ",
              color: "white",
              background: "rgba(0, 0, 0, 0)",
            }}
          >
            {collapsed ? (
              <MenuUnfoldOutlined style={{ fontSize: "18px" }} />
            ) : (
              <MenuFoldOutlined
                style={{ fontSize: "18px", marginLeft: "55px" }}
              />
            )}
          </Button>

          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={({ key }) => {
              navigate(key);
            }}
            items={filteredItems} // Use the filtered items array
            style={{
              background: "rgba(0, 0, 0, 0)",
              color: "white",
              fontSize: "18px",
            }}
          />

          
          <div className="sider-logo">
            <img
              src={hidlr}
              alt="Logo"
              className={`logo-image ${collapsed ? "" : "rotated"}`}
            />
          </div>
        </Sider>
      </Layout>
    </div>
  );
};

export default LeftSider;
