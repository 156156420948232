import React, { createContext, useContext, useState } from 'react'

const ApplogContext = createContext ();

export const useApplogContext = ()=>{
    const context =useContext (ApplogContext);
    if(!context){
        throw new Error("useApplogContext must be used within a Provider");
    }
    return context;
};

export const ApplogProvider = ({children}) =>{
    const [awsAppLog, setAwsAppLog] = useState(null);
    const [gcpAppLog, setGcpAppLog] = useState(null);
    const [productName, setProductName] = useState(null);
    const [serviceName, setServiceName] = useState(null);
    const [cloud,setCloud]= useState(null);

    return(
        <ApplogContext.Provider value={{awsAppLog,setAwsAppLog,gcpAppLog,setGcpAppLog, productName,setProductName,serviceName,setServiceName, cloud, setCloud}}>
            {children}
        </ApplogContext.Provider>
    );
};
