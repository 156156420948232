import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd";
import Title from "antd/es/typography/Title";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

const AddNewUser = () => {
  const [addRoleForm] = Form.useForm();
  const [productData, setProductData] = useState([]);
  const [activeNames, setactiveNames] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchProducts = async () => {
      let productPaylaod = {};
      productPaylaod = {
        method: "get",
        what_to_get: "productdetails_admin",
        status: "InProgress",
      };

      console.log(productPaylaod);

      try {
        console.log("try inside");
        const prodResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(productPaylaod)
        );
        console.log("prodResponse", prodResponse);
        const data = prodResponse.data.body;
        const projects = data;
        setProductData(projects);
        const productNames = projects.map((item) => item.name);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };
    fetchProducts();
  }, []);

  const handleSelectChange = (value) => {
    setSelectedProduct(value);
    console.log(selectedProduct);
  };

  const handleReset = () => {
    addRoleForm.resetFields();
    setSelectedProduct();
  };

  const onAddRoleFormFinish = async (values) => {
    console.log(values);

    let addRolePayload = {};

    addRolePayload = {
      method: "update",
      what_to_update: "user_role",
      productname: values.productName,
      email: values.userMail,
      role: values.userRole,
    };
    console.log("payload", addRolePayload);

    try {
      const addRoleResponse = await axios.post(
        `${baseURL}/dev/update`,
        JSON.stringify(addRolePayload)
      );
      console.log("role Response", addRoleResponse);
      handleReset();
    } catch (error) {
      console.log("role error", error);
    }
  };

  return (
    <div>
      <Row justify="center" align="center">
        <Card
          style={{
            marginTop: "15vh",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            width: "650px",
          }}
        >
          <Space>
            <Title level={5}> Manage User Roles</Title>
          </Space>
          <p style={{ fontSize: "12px", marginTop: "-5px" }}>
            {" "}
            Select and Fill the below Particulars to add a new User
          </p>
          <br />
          <Form
            form={addRoleForm}
            layout="vertical"
            onFinish={onAddRoleFormFinish}
          >
            <Form.Item
              label={<span className="custom-label">Select Product Name</span>}
              name="productName"
              extra="Please Select a product to add a new user"
              rules={[
                {
                  required: true,
                  message: "Select a product ",
                },
              ]}
            >
              <Select value={selectedProduct} onChange={handleSelectChange}>
                {productData.map((project) => (
                  <Select.Option key={project.name} value={project.name}>
                    {project.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <span className="custom-label">
                  Enter the Email id of the User
                </span>
              }
              name="userMail"
              extra="Enter the deloitte mail id of the user to be added to the product"
              rules={[
                {
                  required: true,
                  message: "enter the email id ",
                },
              ]}
            >
              <Input />
            </Form.Item>
            
            <Form.Item
              label={
                <span className="custom-label">Select the Type of Access</span>
              }
              name="userRole"
              rules={[
                {
                  required: true,
                  message: "enter the email id ",
                },
              ]}
            >
              <Select>
                {/* <Select.Option value="productOwner">Product Owner</Select.Option> */}
                <Select.Option value="co_owner">
                  Product Co-Owner
                </Select.Option>
                <Select.Option value="watchlistuser">
                  Product Viewer
                </Select.Option>
              </Select>
            </Form.Item>
            <Row gutter={2}>
              <Col span={3}>
                <Form.Item>
                  <Button type="primary" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  htmlType="submit"
                  // disabled={handleCreateButton()}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Row>
    </div>
  );
};

export default AddNewUser;
