import axios from "axios";
import {
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext/UserContext";

const { Title, Paragraph } = Typography;

const ActiveProducts = () => {
  const { user } = useContext(UserContext);
  const [productData, setProductData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductLinks, setSelectedProductLinks] = useState(null);
  const [links, setLinks] = useState([]);
  const [activeNames, setactiveNames] = useState([]);
  const [detailsLoading, setdetailsLoading] = useState(true);
  const userMail = user.email;
  // const baseURL = process.env.REACT_APP_API_URL;
  const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";


  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchProducts = async () => {
      let productPaylaod = {};
      productPaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "InProgress",
      };


      try {

        const prodResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(productPaylaod)
        );
        const data = prodResponse.data.body;
        const projects = data;
        setProductData(projects);
        const productNames = projects.map((item) => item.name);
        setactiveNames(productNames);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    if (activeNames.length > 0) {
      const fetchData = async () => {
        const fetchDataPayload = {
          method: "get",
          what_to_get: "endpoint",
        };

        try {
          const response = await axios.post(
            `${baseURL}/Prod/get`,
            JSON.stringify(fetchDataPayload)
          );
          const products = JSON.parse(response.data.body);
          const filteredProducts = products.filter((item) =>
            activeNames.includes(item.productname)
          );

          const formattedData = filteredProducts.map((item, index) => {
            const { productname, frontendurl, backendurl, repourl } = item;
            return {
              name: productname,
              frontendLink:
                frontendurl && frontendurl.length > 0 ? frontendurl[0] : [],
              backendLinks:
                backendurl && backendurl.length > 0 ? backendurl : [],
              repourl: repourl,
            };
          });
          setLinks(formattedData);
          setdetailsLoading(false);

        } catch (error) {

        }
      };
      fetchData();
    } else {
      setdetailsLoading(false);
    }
  }, [activeNames]);

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => handleViewDetails(record)}>View Details</Button>
      ),
    },
  ];

  const dataSource = productData.map((product) => ({
    key: product.name,
    name: product.name,
    created_by: product.username,
    created_date: product.date_time,
  }));

  const handleViewDetails = (record) => {
    const fullProduct = productData.find(
      (product) => product.name === record.name
    );
    setSelectedProduct(fullProduct);
    setModalVisible(true);
    const selectedProdLinks = links.find((item) => item.name === record.name);
    setSelectedProductLinks(selectedProdLinks);

  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      {/* <Row align="center" style={{ marginTop: "40px" }}>
        <Col span={16}> */}
      {detailsLoading && activeNames.length === 0 ? (
        <Spin size="medium" />
      ) : activeNames.length === 0 && productData.length === 0 ? (
        <Typography.Text>No active products found</Typography.Text>
      ) : (
        <Table dataSource={dataSource} columns={columns} bordered />
      )}
      
      
      <Modal
        title={<p>Product Details</p>}
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedProduct && (
          <div>
            <Title level={4} style={{ fontStyle: "normal" }}>
              Product Name: <span>{selectedProduct.productname} </span>
            </Title>
            <Paragraph>
              <span className="modal-style">Cloud: </span>{" "}
              <span>
                {selectedProduct.cloud === "aws"
                  ? "AWS"
                  : selectedProduct.cloud === "gcp"
                  ? "GCP"
                  : "Azure"}
              </span>
            </Paragraph>
            <Paragraph>
              <span className="modal-style">Product Created on: </span>{" "}
              <span>{selectedProduct.date_time}</span>
            </Paragraph>

            <Paragraph>
              <span className="modal-style">Repo Link: </span> <br />
              <a
                href={selectedProductLinks.repourl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {selectedProductLinks.repourl}{" "}
              </a>
            </Paragraph>

            {/* Add other Paragraphs for additional sections */}
            {selectedProduct.frontend === "Yes" && (
              <div>
                <Divider orientation="left" orientationMargin={0}>
                  <b>Frontend Specifications </b>
                </Divider>
                <Paragraph>
                  <span className="modal-style">Frontend Techstack: </span>
                  <span>
                    {selectedProduct.frontend_techstack === "react"
                      ? "React"
                      : "Angular"}
                  </span>
                </Paragraph>
                <Paragraph>
                  <span className="modal-style">Frontend Service: </span>
                  <span>{selectedProduct.frontend_service}</span>
                </Paragraph>
              </div>
            )}
            {selectedProduct.backend === "Yes" && (
              <div>
                <Divider orientation="left" orientationMargin={0}>
                  <b> Backend Specifications</b>
                </Divider>
                <Paragraph>
                  <span className="modal-style">Backend Techstack: </span>{" "}
                  <span>{selectedProduct.backend_techstack}</span>
                </Paragraph>
                <Paragraph>
                  <span className="modal-style">Backend Service: </span>{" "}
                  <span>{selectedProduct.backend_service}</span>
                </Paragraph>
                <Paragraph>
                  <span className="modal-style">Microservices: </span>{" "}
                  <span>{selectedProduct.microservices}</span>
                </Paragraph>
                <Paragraph>
                  <span className="modal-style">Backend Microservices: </span>
                  <span>{selectedProduct.backend_microservices}</span>
                </Paragraph>
                <Paragraph>
                  <span className="modal-style">Database: </span>
                  <span>{selectedProduct.database}</span>
                </Paragraph>
              </div>
            )}
            <Divider orientation="left" orientationMargin={0}>
              <b>Deployed Enpoints </b>
            </Divider>
            {selectedProductLinks.frontendLink[0] !== null && (
              <Paragraph>
                <span className="modal-style">Frontend Link: </span>
                <br />
                <a
                  href={selectedProductLinks.frontendLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selectedProductLinks.frontendLink}
                </a>
              </Paragraph>
            )}
            {selectedProductLinks.backendLinks.length > 0 && (
              <div className="backend-links">
                <Paragraph>
                  <span className="modal-style">Backend Links: </span>
                </Paragraph>
                <ul>
                  {selectedProductLinks.backendLinks.map((link, index) => (
                    <li key={index}>
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </Modal>
      {/* </Col>
      </Row> */}
    </>
  );
};

export default ActiveProducts;
