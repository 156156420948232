import React, { createContext, useContext, useState } from 'react';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [receivedData, setReceivedData] = useState({});
  const [connectionID, setConnectionID] = useState(null);
  const [logs, setLogs] = useState([]);

  return (
    <WebSocketContext.Provider value={{ receivedData, setReceivedData, connectionID, setConnectionID, logs, setLogs }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
