import React, { useEffect, useRef, useState } from "react";
import { Card, List, Row, Space, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as DeleteStill } from "../../assets/Completed-pana.svg";
import { default as deleteAnimate } from "../../assets/deletion1.gif";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
// import { useWebSocket } from "../../Context/WebsocketContext/WebSocketContext";
import { useFormValueContext } from "../../Context/FormContext/FormContext";

// const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";

const Deletion = () => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  // const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isCompleted, setisCompleted] = useState(false);
  const [logs, setlogs] = useState([]);
  const [lastLog, setLastLog] = useState(null);
  // const baseURL = process.env.REACT_APP_API_URL;
  const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";
  // const { product } = location.state;
  // const {logs} = useWebSocket();
  // const [delLogs, setdelLogs] = useState([]);
  const {productName} = useFormValueContext();
  // console.log(product);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(productName);

  const fetchDeleteLogs = async () => {
    let logsPayload = {};
    logsPayload = {
      method: "get",
      what_to_get: "logs",
      productname: productName,
    };
    try {
      const logsResponse = await axios.post(
        `${baseURL}/Prod/get`,
        JSON.stringify(logsPayload)
      );
      console.log("logs Response", logsResponse.data.body);
      const message = logsResponse.data.body;
      const msg = JSON.parse(message);
      // const msg=receivedData.message;
      // console.log(msg[0].log);
      const logValue = msg[0].log;
      const error_flag = message[0].error;
      if (logValue !== lastLog) {
        setlogs((prevLogs) => [...prevLogs, logValue]);
        setLastLog(logValue);
        console.log(logs);
      }
    } catch (error) {
      console.log(error);
    }
    if (logs.includes("Infrastructure Deleted..")) {
      console.log("abcd");
      setLoading(false);
      console.log(loading);
      const timer = setTimeout(() => {
        console.log("abcdefg");
        navigate("/");
      }, 30 * 1000);
      return () => {
        setlogs([]);
        clearTimeout(timer);
      };
    }
  };
  console.log(logs);

  useEffect(() => {
    const intervalId2 = setInterval(() => {
      if (!logs.includes("Infrastructure Deleted..")) {
        fetchDeleteLogs();
      }
      if (logs.includes("Infrastructure Deleted..")) {
        console.log("abcd");
        setLoading(false);
        setisCompleted(true);
        console.log(loading);

        const timer = setTimeout(() => {
          setlogs([]);
          navigate("/");
        }, 30 * 1000);
        return () => {
          setlogs([]);
          clearTimeout(timer);
        };
      }
    }, 2000);

    return () => clearInterval(intervalId2);
  }, [logs,loading,isCompleted]);

  // useEffect(() => {
  //   const intervalId2 = setInterval(() => {
  //     if (!delLogs.includes("Infrastructure Deleted..")) {
  //       if (logs.length > 0) {
  //         const setupIndex = logs.indexOf("Setup Of Infrastructure Done..");
  //         if (setupIndex !== -1) {
  //           const relevantLogs = logs.slice(setupIndex + 1);
  //           setdelLogs(relevantLogs);
  //         } else {
  //           setdelLogs(logs);
  //         }
  //       } 
  //       console.log("loggguu", logs);
  //     }
  //     if (delLogs.includes("Infrastructure Deleted..")) {
  //       setisCompleted(true);
  //     }

  //     if (delLogs.includes("Infrastructure Deleted..") && !isCompleted) {
  //       setLoading(false);
  //       setisCompleted(true);
  //       console.log(loading);
  //       const timer = setTimeout(() => {
  //         navigate("/");
  //         setdelLogs([]);
  //       }, 30 * 1000);
  //       return () => {
  //         clearTimeout(timer);
  //       };
  //     }
  //   }, 2000);

  //   return () => clearInterval(intervalId2);
  // }, [logs, isCompleted]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }, [logs]);

  const showScrollBar = logs.length > 280 / 20;

  return (
    <div>
      <Row justify="center" align="middle">
        <Card
          justify="center"
          style={{
            marginTop: "10vh",
            width: "60rem",
            backgroundColor: "#4a8ac620",
          }}
        >
          <Space direction="horizontal" gap={10} align="start">
            <div>
              <Space justify="center" align="center" direction="vertical">
              <p>
                  <span style={{ color: "red" }}>*</span> Please do not refresh
                  the page till the process is completed.
                </p>
                {" "}
                {isCompleted ? (
                  <DeleteStill
                    style={{
                      width: "350px",
                      height: "350px",
                    }}
                  />
                ) : (
                  <img
                    src={deleteAnimate}
                    alt="Process in Progress"
                    style={{
                      borderRadius: "20px",
                      height: "350px",
                      width: "350px",
                    }}
                  />
                )}{" "}
                
              </Space>
            </div>
            <div>
              {" "}
              <Space direction="vertical">
                <div>
                  <p style={{ fontSize: "18px" }}> Product Name: {productName}</p>

                  <p style={{ fontSize: "14px" }}>
                    Destroying the Infrastructure
                  </p>
                  <div className="separator" style={{ width: "340px" }}></div>
                  <div
                    style={{
                      marginTop: "10px",
                      border: "1px solid #00000080",
                      height: "280px",
                      width: "360px",
                      borderRadius: "20px",
                    }}
                  >
                    <div
                      ref={scrollContainerRef}
                      style={{
                        whiteSpace: "nowrap",
                        overflowY: "auto",
                        maxHeight: "280px",
                        scrollbarWidth: "thin",
                        scrollbarColor: "dark",
                      }}
                    >
                      {logs?.length > 0 ?( 
                      <List
                        style={{ whiteSpace: "normal" }}
                        size="small"
                        dataSource={logs.filter((log) => log !== undefined)}
                        renderItem={(log, index) => (
                          <div className="list-item-container" key={index}>
                            <List.Item>{log}</List.Item>{" "}
                          </div>
                        )}
                      />
                      ) : ( <p> No logs available yet</p>)}
                    </div>
                  </div>
                  <div>
                    {loading ? (
                      <Space size="middle">
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 24 }} spin />
                          }
                        />
                      </Space>
                    ) : (
                      <Space size="middle">
                        <CheckCircleOutlined
                          style={{
                            fontSize: 24,
                            color: "green",
                            marginLeft: "10px",
                          }}
                        />
                      </Space>
                    )}
                  </div>
                </div>
              </Space>
            </div>
          </Space>
        </Card>
      </Row>
    </div>
  );
};

export default Deletion;
