import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Form,
  Row,
  Card,
  Space,
  Tooltip,
  Select,
  Radio,
  Button,
  Col,
  Spin,
  Modal,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import "./logspage.css";
import { UserContext } from "../../Context/UserContext/UserContext";
import { useApplogContext } from "../../Context/AppLogsContext/ApplogContext";

const LogsScreen = () => {
  const { user } = useContext(UserContext);
  const {
    setAwsAppLog,
    awsAppLog,
    gcpAppLog,
    setGcpAppLog,
    setProductName,
    productName,
    serviceName,
    setServiceName,
    setCloud,
  } = useApplogContext();
  const [form] = Form.useForm();
  const [productData, setProductData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [frontendEnabled, setFrontendEnabled] = useState(false);
  const [backendEnabled, setBackendEnabled] = useState(false);
  const [cloud, setcloud] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [microserviceOptions, setMicroserviceOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [azureModalVisible, setAzureModalVisible] = useState(false);
  const [azureName, setAzureName] = useState(null);
  // const baseURL = process.env.REACT_APP_API_URL;
  const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";
  // const authToken = process.env.REACT_APP_AUTH_TOKEN;
const authToken = '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';
  const userMail = user.email;

  useEffect(() => {
    const fetchProducts = async () => {
      let productPaylaod = {};
      productPaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "InProgress",
      };
      // console.log(productPaylaod);

      try {
        console.log("try inside");
        const prodResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(productPaylaod)
        );
        const data = prodResponse.data.body;
        const projects = data;
        console.log("product", projects);
        setProductData(projects);
        const productNames = projects.map((item) => item.name);
        console.log(productNames);
        // setactiveNames(productNames);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };
    fetchProducts();
  }, []);

  const handleReset = () => {
    form.resetFields();
    setSelectedServiceType();
    setBackendEnabled();
    setFrontendEnabled();
    setSelectedProduct();
    setcloud();
    setAwsAppLog(null);
    setGcpAppLog(null);
    setProductDetails([]);
  };

  const generateMicroserviceOptions = (
    cloud,
    cloudService,
    totalMicroservices,
    productName
  ) => {
    if (cloud === "aws") {
      console.log("hsdfkjs: ", cloudService);

      if (cloudService === "aws_apprunner") {
        console.log("hsdfkjs2");
        return Array.from({ length: totalMicroservices }, (_, i) => ({
          label: `${productName}-apprunner-ms-${i + 1}`,
          value: `${productName}-apprunner-ms-${i + 1}`,
        }));
      } else if (cloudService === "aws_lambda_ecr") {
        return Array.from({ length: totalMicroservices }, (_, i) => ({
          label: `${productName}-ms-${i + 1}`,
          value: `${productName}-ms-${i + 1}`,
        }));
      }
    } else if (cloud === "gcp") {
      if (cloudService === "gcp_cloud_run") {
        return Array.from({ length: totalMicroservices }, (_, i) => ({
          label: `${productName}-ms-${i + 1}`,
          value: `${productName}-ms-${i + 1}`,
        }));
      } else if (cloudService === "gcp_cloud_function") {
        return Array.from({ length: totalMicroservices }, (_, i) => ({
          label: `${productName}-ms-${i + 1}`,
          value: `${productName}-ms-${i + 1}`,
        }));
      }
    } else if (cloud === "azure") {
      if (cloudService === "azure_webapp") {
        return Array.from({ length: totalMicroservices }, (_, i) => ({
          label: `${productName}-webapp-ms-${i + 1}`,
          value: `${productName}-webapp-ms-${i + 1}`,
        }));
      }
    }
    return [];
  };

  const handleProductSelect = (value) => {
    const selectedProductData = productData.find(
      (product) => product.name === value
    );

    console.log(value);
    setSelectedProduct(value);
    console.log("selected", selectedProductData);
    setProductDetails(selectedProductData);

    if (selectedProductData) {
      const cloudService = selectedProductData.backend_service; // Access the backend service property

      console.log("Selected product:", value);
      console.log("Selected product data:", selectedProductData);
      console.log("Cloud service:", cloudService);

      const microservicesOptions = generateMicroserviceOptions(
        selectedProductData.cloud,
        cloudService,
        selectedProductData.backend_microservices,
        selectedProductData.name
      );

      console.log("Microservice options:", microservicesOptions);

      setMicroserviceOptions(microservicesOptions);
      setFrontendEnabled(selectedProductData.frontend === "Yes");
      setBackendEnabled(selectedProductData.backend === "Yes");
      setcloud(selectedProductData.cloud);
      setProductName(selectedProductData.name);
      setServiceName(cloudService);
      setAzureName(null);
      setCloud(selectedProductData.cloud);
    }
    setSelectedServiceType(null);
  };

  // console.log(selectedProduct);

  const cloudServiceOptions = {
    aws: {
      frontend: [
        // { label: "S3 and Cloud front", value: "aws_s3_cf" },
        { label: "Apprunner", value: "AppRunner" },
      ],
      backend: [
        { label: "Apprunner", value: "AppRunner" },
        { label: "Lambda and API Gateway", value: "Lambda" },
      ],
    },
    gcp: {
      frontend: [
        // { label: "GCS & cDN", value: "gcp_gcs_cdn" },
        { label: "CloudRun", value: "cloud_run" },
      ],
      backend: [
        { label: "Cloud Function", value: "cloud_function" },
        { label: "Cloud Run", value: "cloud_run" },
      ],
    },
    azure: {
      frontend: [
        // { label: "Blob Storage & CDN", value: "azure_blob_cdn" },
        { label: "WebApp", value: "azure_webapp" },
      ],
      backend: [
        { label: "WebApp", value: "azure_webapp" },
        // { label: "Azure Functions", value: "azure_functions" },
      ],
    },
  };

  const getServiceOptions = () => {
    const cloudServices = cloudServiceOptions[cloud];

    if (selectedServiceType === "frontend") {
      if (
        productDetails.frontend_service === "aws_s3_cf" ||
        productDetails.frontend_service === "gcs-cdn" ||
        productDetails.frontend_service === "azure-blob-cdn" ||
        productDetails.backend_service === "azure_functions"
      ) {
        return [
          {
            label: "No logs are available for the selected service",
            value: "No logs",
          },
        ];
      } else {
        return frontendEnabled ? cloudServices.frontend : [];
      }
    } else if (selectedServiceType === "backend") {
      return backendEnabled ? cloudServices.backend : [];
    }
    return [];
  };

  const downloadLogs = () => {
    console.log("clicked");
    // console.log("data", awsAppLog.data.body.body);
    const formattedData =
      cloud === "aws"
        ? JSON.stringify(awsAppLog.data.body.body, null, 2)
        : JSON.stringify(gcpAppLog, null, 2);

    if (cloud === "aws" || cloud === "gcp") {
      console.log("inside", awsAppLog);
      // setDownloadModalVisible(true);
      const blob = new Blob([formattedData], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `logs_${productName}_${serviceName}.txt`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      handleReset();
      setDownloadModalVisible(false);
    } else if (cloud === "azure") {
      // setLoading(true);

      setTimeout(() => {
        // setAzureModalVisible(true);
      }, 60000);
    }
  };

  const handleAzureDownload = () => {
    // console.log("calling");
    // window.open(`https://hicloud.azureedge.net/logs/${productName}.zip`,);
    // setAzureModalVisible(false);

    // const link = document.createElement("a");
    // link.href = `https://hicloud.azureedge.net/logs/${productName}.zip`;
    // link.target = "_blank";
    // link.setAttribute("download", `${productName}.zip`); // Sets the filename for download
    // document.body.appendChild(link);
    // link.click();
    // setAzureModalVisible(false);

    fetch(`https://hicloud.azureedge.net/logs/${azureName}.zip`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${azureName}.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
    setAzureModalVisible(false);
  };

  const isMicroservicesEnabled = productDetails.microservices === "Yes";

  const handleLogCalls = async (payload) => {
    console.log(payload);
    let logsPayload = {};
    //AWS logs fetch
    if (cloud === "aws") {
      if (selectedServiceType === "frontend") {
        let newName = payload.productValue + "-apprunner-frontend";
        logsPayload = {
          Service_Type: payload.serviceName,
          Service_Name: newName,
        };
        console.log(logsPayload);
      }
      if (selectedServiceType === "backend") {
        if (productDetails.microservices === "No") {
          if (payload.serviceName === "AppRunner") {
            let newName = payload.productValue + "-apprunner-ms-1";
            logsPayload = {
              Service_Type: payload.serviceName,
              Service_Name: newName,
            };
            console.log("logs", logsPayload);
          } else if (payload.serviceName === "Lambda") {
            let newName = payload.productValue + "-ms-1";
            logsPayload = {
              Service_Type: payload.serviceName,
              Service_Name: newName,
            };
            console.log(logsPayload);
          }
        } else if (productDetails.microservices === "Yes") {
          logsPayload = {
            Service_Type: payload.serviceName,
            Service_Name: payload.microserviceNumber,
          };
        }
      }

      console.log("payload out of if", logsPayload);
      const logResponse = await axios.post(
        `https://s9ugmpx22k.execute-api.us-east-1.amazonaws.com/dev/exportlogs`,
        JSON.stringify(logsPayload)
      );
      console.log(logResponse.data);
      setAwsAppLog(logResponse);
      if (logResponse.status === 200) {
        setLoading(false);
        // downloadLogs();
        setDownloadModalVisible(true);
      } else {
        setLoading(true);
      }

      //GCP logs fetch
    } else if (cloud === "gcp") {
      if (selectedServiceType === "frontend") {
        let newName = payload.productValue + "-cr-frontend";
        logsPayload = {
          service_name: newName,
          service_type: payload.serviceName,
        };
        console.log("pay :", logsPayload);
      } else if (selectedServiceType === "backend") {
        console.log("inside gcp");
        console.log("gcpayload", payload);
        if (productDetails.microservices === "No") {
          console.log("inside gcp no");
          let newName = payload.productValue + "-ms-1";
          logsPayload = {
            service_name: newName,
            service_type: payload.serviceName,
          };
        } else if (productDetails.microservices === "Yes") {
          console.log("inside gcp microservice");
          logsPayload = {
            service_name: payload.microserviceNumber,
            service_type: payload.serviceName,
          };
          console.log("gcp payload", logsPayload);
        }

        // service_name: "saikatdev5-ms-1",
        // service_type: "cloud_function",
      }
      console.log("gcp payload", logsPayload);
      const logsResponse = await axios.post(
        `https://us-west2-us-gcp-ame-con-116-npd-1.cloudfunctions.net/devcloudrunlogs4-ms-1`,
        JSON.stringify(logsPayload),
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      console.log(logsResponse);
      setGcpAppLog(logsResponse.data);

      if (logsResponse.status === 200) {
        setLoading(false);
        setDownloadModalVisible(true);
        // navigate("/show-logs");
      } else {
        setLoading(true);
      }
    } else if (cloud === "azure") {
      if (selectedServiceType === "frontend") {
        let newName = payload.productValue + "-webapp";
        setAzureName(newName);
        logsPayload = {
          event_type: "azure-api-logs",
          client_payload: {
            azure_parameter: {
              servicename: newName,
            },
          },
          
        };
        console.log(" before if azurePayload", logsPayload);
      } else if (selectedServiceType === "backend") {
        if (productDetails.microservices === "Yes") {
          console.log("inside gcp microservice");
          setAzureName(payload.microserviceNumber);
          logsPayload = {
            event_type: "azure-api-logs",
            client_payload: {
              azure_parameter: {
                servicename: payload.microserviceNumber,
              },
            },
          };
          console.log(" before og azurePayload", logsPayload);
        } else if (productDetails.microservices === "No") {
          console.log("inside gcp microservice");
          let newName = payload.microserviceNumber + "-webapp-ms-1";
          setAzureName(newName);
          logsPayload = {
            event_type: "azure-api-logs",
            client_payload: {
              azure_parameter: {
                servicename: payload.microserviceNumber,
              },
            },
          };
        }
      }
      console.log("azurePayload", logsPayload);
      const dispatchResponse = await axios.post(
        `https://api.github.com/repos/Deloitte/Dev-HiCloud/dispatches`,
        JSON.stringify(logsPayload),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("azure dispatch", dispatchResponse);
      if (dispatchResponse.status === 204) {
        setTimeout(() => {
          setAzureModalVisible(true);
          setLoading(false);
          handleReset();
        }, 60000);
        setLoading(true);
      } else {
        setLoading(true);
      }
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    console.log(values);
    handleLogCalls(values);
  };

  return (
    <div>
      <Row justify="center" align="center">
        <Card
          style={{
            marginTop: "5vh",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            width: "700px",
          }}
        >
          <Space>
            <Title level={5}>
              Services Logs Form
              <Tooltip title="View user manual">
                <a
                  href="https://amedeloitte.sharepoint.com/:p:/s/HUDevopsSupport-Relay/Ee5A8mwOirNLoIU6CVRkaOUBy19BPriE6d_9NaCfe2L7Ww?e=eUWcZr&nav=eyJzSWQiOjI2OCwiY0lkIjoyMzQ2MjM5ODE2fQ"
                  target=" _blank"
                >
                  <QuestionCircleOutlined
                    style={{ color: "black", fontSize: "16px", padding: 8 }}
                  />
                </a>
              </Tooltip>
            </Title>
          </Space>{" "}
          <p
            style={{ fontSize: "12px", marginTop: "-5px", marginLeft: "10px" }}
          >
            {" "}
            Select the below particulars to get the logs for a service
          </p>
          <p style={{ fontSize: "12px" }}>
            <span style={{ color: "red" }}> *</span>{" "}
            <strong>
              {" "}
              Please note that logs are not available for S3 and Cloudfront, GCS
              and CDN, Azure Blob Storage and CDN{" "}
            </strong>{" "}
            <br />
            <p>
              {" "}
              <span style={{ color: "red" }}> *</span>{" "}
              <strong>
                {" "}
                Please click on reset button or refersh the page before filling
                the details for a different service{" "}
              </strong>
            </p>
          </p>
          <br />
          <Spin spinning={loading}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Form.Item
                label={<span className="custom-label">Select Product</span>}
                name="productValue"
                extra="Please select the product to view the logs"
                rules={[
                  {
                    required: true,
                    message: "Select a product ",
                  },
                ]}
              >
                <Select value={selectedProduct} onChange={handleProductSelect}>
                  {productData.map((project) => (
                    <Select.Option key={project.name} value={project.name}>
                      {project.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {selectedProduct === "" ||
                (selectedProduct !== undefined && (
                  <>
                    <p className="cloudTitle">
                      Cloud :{" "}
                      {productDetails.cloud !== "aws" ? (
                        productDetails.cloud !== "gcp" ? (
                          <span>Azure</span>
                        ) : (
                          <span>Gcp</span>
                        )
                      ) : (
                        "AWS"
                      )}
                    </p>

                    {productDetails.frontend === "Yes" && (
                      <div>
                        <p className="cloudTitle">Frontend Details</p>
                        <table className="detailsTable">
                          <thead>
                            <tr>
                              <th>Frontend</th>
                              <th>Frontend Service</th>
                              <th>Frontend Techstack</th>
                            </tr>
                          </thead>
                          <tbody className="tableValues">
                            <tr>
                              <td>{productDetails.frontend}</td>
                              <td>{productDetails.frontend_techStack}</td>
                              <td>{productDetails.frontend_service}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                    {productDetails.backend === "Yes" && (
                      <div>
                        <p className="cloudTitle">Backend Details</p>
                        <table className="detailsTable">
                          <thead>
                            <tr>
                              <th>Backend Service</th>
                              <th>Backend Techstack</th>
                              <th>Backend Microservice</th>
                              {productDetails.microservices === "Yes" && (
                                <th>Number of Microservice</th>
                              )}
                              {productDetails.database === "Yes" && (
                                <th>Database</th>
                              )}
                            </tr>
                          </thead>
                          <tbody className="tableValues">
                            <tr>
                              <td>{productDetails.backend_techstack}</td>
                              <td>{productDetails.backend_service}</td>
                              <td>{productDetails.microservices}</td>
                              {productDetails.microservices === "Yes" && (
                                <td>{productDetails.backend_microservices}</td>
                              )}
                              {productDetails.database === "Yes" && (
                                <td>{productDetails.database}</td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                ))}

              <Form.Item
                label={
                  <span className="custom-label">
                    Select the type of service
                  </span>
                }
                name="stackType"
                extra="Please select if the service belongs of frontend or Backend "
                rules={[
                  {
                    required: true,
                    message: "Select either frontend or Backend ",
                  },
                ]}
              >
                <Radio.Group
                  value={selectedServiceType}
                  onChange={(e) => setSelectedServiceType(e.target.value)}
                >
                  <Radio value="frontend" disabled={!frontendEnabled}>
                    Frontend
                  </Radio>
                  <Radio value="backend" disabled={!backendEnabled}>
                    Backend
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label={
                  <span className="custom-label">
                    {" "}
                    Please select the Cloud Service below
                  </span>
                }
                name="serviceName"
                rules={[
                  {
                    required: true,
                    message: "Select either frontend or Backend ",
                  },
                ]}
                extra={
                  <span className="custom-extra">
                    Select the Service from the List{" "}
                  </span>
                }
              >
                <Select>
                  {getServiceOptions().map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {isMicroservicesEnabled && selectedServiceType === "backend" && (
                <Form.Item
                  label="Select the microservice which you want to check the logs"
                  name="microserviceNumber"
                  rules={[
                    {
                      required: productDetails.microservices === "Yes",
                      message: "Select the service number",
                    },
                  ]}
                >
                  {console.log("microserviceOptions: ", microserviceOptions)}
                  <Select>
                    {microserviceOptions.map((option, index) => (
                      <Select.Option key={index} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              {/* <Form.Item
  label="Select the microservice which you want to check the logs"
  name="microserviceNumber"
  rules={[
    {
      required:
        isMicroservicesEnabled && selectedServiceType === "backend", // Only required for backend
      message: "Select the service number",
    },
  ]}
>
  <Select>
    {microserviceOptions.map((option, index) => (
      <Select.Option key={index} value={option.value}>
        {option.label}
      </Select.Option>
    ))}
  </Select>
</Form.Item> */}

              <Row gutter={6}>
                <Col span={4}>
                  <Form.Item>
                    <Button type="primary" onClick={handleReset}>
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Button type="primary" htmlType="submit">
                    Get Logs
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
          <Modal
            title="Download Logs"
            open={downloadModalVisible}
            onOk={downloadLogs}
            onCancel={() => setDownloadModalVisible(false)}
          >
            <p>
              The logs are ready for download. Click the button below to
              download.
            </p>{" "}
            <br />
            <Button type="primary" onClick={downloadLogs}>
              Download
            </Button>
          </Modal>
          {azureModalVisible && (
            <Modal
              title="Azure Download"
              open={azureModalVisible}
              onOk={handleAzureDownload}
              onCancel={() => setAzureModalVisible(false)}
            >
              <p>Click the button below to download Azure logs.</p>
              <Button type="primary" onClick={handleAzureDownload}>
                Download
              </Button>
              <br />
              <p>
                Please check the Downloads to find the logs downloaded as a .zip
                file.{" "}
              </p>

              <p>
                To check the logs, extract the file and navigate to the LogFiles
                folder.
              </p>
            </Modal>
          )}
        </Card>
      </Row>
    </div>
  );
};

export default LogsScreen;
