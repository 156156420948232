import axios from "axios";
import {
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext/UserContext";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const AllProducts = () => {
  const { user } = useContext(UserContext);
  const [activeData, setActiveData] = useState([]);
  const [inactiveData, setInactiveData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [approvedData, setapprovedData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [detailsLoading, setdetailsLoading] = useState(true);
  const userMail = user.email;
  // const baseURL = process.env.REACT_APP_API_URL;
  const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";

  useEffect(() => {
    const fetchActiveData = async () => {
      let activePaylaod = {};
      activePaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "InProgress",
      };
      try {
        const activeResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(activePaylaod)
        );
        const activedata = activeResponse.data.body;
        setActiveData(activedata);
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };

    const fetchInactiveData = async () => {
      let inActivePaylaod = {};
      inActivePaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Deleted",
      };

      try {
        const inActiveResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(inActivePaylaod)
        );
        const inActivedata = inActiveResponse.data.body;
        setInactiveData(inActivedata);
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };

    const fetchPendingData = async () => {
      let pendingPaylaod = {};
      pendingPaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Pending",
      };

      try {
        const pendingResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(pendingPaylaod)
        );
        const pendingdata = pendingResponse.data.body;
        setPendingData(pendingdata);
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };

    const fetchApprovedData = async () => {
      let approvedPaylaod = {};
      approvedPaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Approved",
      };

      try {
        const approvedResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(approvedPaylaod)
        );
        const approveddata = approvedResponse.data.body;
        setapprovedData(approveddata);
        setdetailsLoading(false);
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };

    fetchActiveData();
    fetchInactiveData();
    fetchPendingData();
    fetchApprovedData();
  }, []);

  useEffect(() => {
    const combined = [
      ...activeData.map((item) => ({ ...item, status: "Active" })),
      ...approvedData.map((item) => ({ ...item, status: "Approved" })),
      ...pendingData.map((item) => ({ ...item, status: "Pending" })),
      ...inactiveData.map((item) => ({ ...item, status: "Inactive" })),
    ];
    setCombinedData(combined);
  }, [activeData, inactiveData, pendingData]);

  const getColorForstatus = (status) => {
    if (status === "Active") {
      return "#50C87880";
    } else if (status === "Inactive") {
      return "gray";
    } else if (status === "Pending") {
      return "#ffcc0090";
    } else if (status === "Approved") {
      return "#6495ED70";
    }
    return "#6495ED";
  };

  const statusDescriptions = {
    Active: "This product is created and active.",
    Pending: "This product is pending for approval.",
    Inactive: "This product is inactive and not operational.",
    Approved: "This product has been approved but not created yet.",
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created By",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Created Date",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <>
          <Tag color={getColorForstatus(status)}>
            {" "}
            <span style={{ color: "black" }}>{status} </span>{" "}
            <Tooltip title={statusDescriptions[status]}>
              <InfoCircleOutlined style={{ color: "black" }} />{" "}
            </Tooltip>
          </Tag>{" "}
        </>
      ),
      filters: [
        { text: "Active", value: "Active" },
        { text: "Pending", value: "Pending" },
        { text: "Inactive", value: "Inactive" },
        { text: "Approved", value: "Approved" },
      ],
      onFilter: (value, record) => record.status === value,
    },
  ];



  return (
    <div>
      {detailsLoading ? (
        <Spin />
      ) : combinedData.length === 0 ? (
        <Typography.Text>No products found </Typography.Text>
      ) : (
        <Table
          dataSource={combinedData}
          columns={columns}
          rowKey="id"
          bordered
        />
      )}
    </div>
  );
};

export default AllProducts;
