import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin, Button } from "antd";
import { UserContext } from "../Context/UserContext/UserContext";
import { Login, activeAWS } from "./CognitoService";
import { signOut } from "./CognitoService";
import axios from "axios";
export default (props) => {
  const [loggedStatus, setLoggedStatus] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(true);
  const { setUser, user, setAdmin, setUserRole } = useContext(UserContext);
  const redirectHistory = useNavigate();
  const location = useLocation();
  const baseURL = process.env.REACT_APP_API_URL;

  // const adminResponse = ['shredeshpande@deloitte.com',"shilph@deloitte.com","darshb@deloitte.com",
  // "gunass@deloitte.com",  "dmaharajanm@deloitte.com", "saikaghosh@deloitte.com", "chaihr@deloitte.com", "cvijaykumar@deloitte.com"]

  // async function userIsAdmin(email) {
  //   const apiUrl = `${baseURL}/Prod/get`;
  //   const adminPayload = {
  //     method: "get",
  //     what_to_get: "projectadmin",
  //   };
  //   // console.log("mail", user.email);
  //   const userRolePayload = {
  //     method: "get",
  //     what_to_get: "project_user_role",
  //     username: user.email,
  //   };
  //   console.log("adminPay", adminPayload);
  //   try {
  //     // const adminResponse = await fetch(apiUrl, {
  //     //   method: 'POST',
  //     //   headers: {
  //     //     'Content-Type': 'application/json',
  //     //   },
  //     //   body: JSON.stringify(adminPayload),
  //     // });
  //     const adminResponse = await axios.post(
  //       apiUrl,
  //       JSON.stringify(adminPayload)
  //     );
  //     console.log("admin resp", adminResponse.data);

  //     if (adminResponse.status === 200) {
  //       // const data = await adminResponse.body.json();
  //       console.log("container :", adminResponse.data.body);
  //       console.log("mai mail", adminResponse.body.includes(email));

  //       if (adminResponse.body.data.includes(email)) {
  //         // setAdmin(true);
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     } else {
  //       console.error("API call failed with status:", adminResponse.status);
  //     }
  //   } catch (error) {
  //     console.error("API call error:", error);
  //     setAdmin(false);
  //   }
  //   // try {
  //   //   const roleResponse = await fetch(apiUrl, {
  //   //     method: "POST",
  //   //     headers: {
  //   //       "Content-Type": "application/json",
  //   //     },
  //   //     body: JSON.stringify(userRolePayload),
  //   //   });

  //   //   if (roleResponse.ok) {
  //   //     const roleData = await roleResponse.json();
  //   //     const userRole = roleData.role;
  //   //     setUserRole(userRole);
  //   //   } else {
  //   //     console.error("User Role API call failed with status:", roleResponse.status);
  //   //   }
  //   // } catch (error) {
  //   //   console.error("User Role API call error:", error);
  //   // }
  // }
  // async function userIsAdmin(email) {
  //   console.log("returns", adminResponse.includes(email));
  //   return adminResponse.includes(email);
  // }

  async function LoginDetails() {
    const { loggedIn, user: cognitoUser } = await Login();

    cognitoUser &&
      setUser((user) => {
        return { ...user, ...cognitoUser };
      });

    setLoadingLogin(false);
    if (cognitoUser !== null) {
      setLoggedStatus(true);
      setTimeout(() => {
        setUser(null);
        signOut();
      }, 21600000);
    } else {
      setLoggedStatus(false);
    }
    if (loggedIn) {
      const redirectUrl = localStorage.getItem("redirect_url");
      setLoggedStatus(true);
      if (redirectUrl) {
        localStorage.removeItem("redirect_url");
      }
      else{
        redirectHistory( location.pathname == "/");
      }
    }
    // if (loggedIn) {
    //   const redirectUrl = localStorage.getItem("redirect_url");
    //   setLoggedStatus(true);
    //   if (redirectUrl) {
    //     localStorage.removeItem("redirect_url");
    //   } else {
        
    //     const flag = userIsAdmin(user.email);
    //     if (flag) {
    //       setLoggedStatus(true);
    //       console.log("Loggedin user is admin");
    //       setAdmin(true);
    //       redirectHistory("/admin-dashboard");
    //     } else if (!flag) {
    //       console.log(("flag is false", flag));
    //       setLoggedStatus(true);
    //       // redirectHistory( location.pathname === "/");
    //       redirectHistory("/");
    //     }
    //   }
    // }

    // if (loggedIn) {
    //   const redirectUrl = localStorage.getItem("redirect_url");
    //   setLoggedStatus(true);
      
    //   if (redirectUrl) {
    //     localStorage.removeItem("redirect_url");
    //   } else {
    //     const isAdmin = await userIsAdmin(user.email);

    //     // const isAdmin = await userIsAdmin("");
    //     if (isAdmin) {
    //       setAdmin(true);
    //       redirectHistory("/admin-dashboard");
    //     } else {
    //       redirectHistory("/");
    //     }
    //   }
    // }
  }

  useEffect(() => {
    LoginDetails();
  }, []);

  // useEffect(() => {
  //   // getPodUsersData()
  //   // isAdmin()
  // }, [user]);

  // useEffect(() => {
  //   if (user.email) {
  //     userIsAdmin(user.email);
  //   }
  // }, [user.email]);

  useEffect(() => {
    LoginDetails();
  }, [user.email]);

  return loadingLogin ? (
    <div className="loginContainer">
      <div style={{ marginTop: "20vh", marginLeft: "90vh" }}>
        <Spin size="large" />
      </div>
    </div>
  ) : loggedStatus ? (
    <>{props.children}</>
  ) : (
    <div style={{ marginTop: "10rem", marginLeft: "77vh" }}>
      <a href={activeAWS.loginUrl}>
        <Button type="primary" size="large" style={{ height: "40px" }}>
          Login to Continue{" "}
        </Button>
      </a>
    </div>
  );
};
