import axios from "axios";
import {
  Button,
  Divider,
  Modal,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext/UserContext";

const { Title, Paragraph } = Typography;

const AdminActiveProducts = () => {
  const { user } = useContext(UserContext);
  const [productData, setProductData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductLinks, setSelectedProductLinks] = useState(null);
  const [links, setLinks] = useState([]);
  const [activeNames, setactiveNames] = useState([]);
  const [detailsLoading, setdetailsLoading] = useState(true);
  const userMail = user.email;
  const baseURL = process.env.REACT_APP_API_URL;
  console.log("Base URL:", baseURL);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchProducts = async () => {
      let productPaylaod = {};
      productPaylaod = {
        method: "get",
        what_to_get: "productdetails_admin",
        status: "InProgress",
      };
      
      console.log(productPaylaod);

      try {
        console.log("try inside");
        const prodResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(productPaylaod)
        );
        const data = prodResponse.data.body;
        const projects = data;
        console.log("product", projects);
        setProductData(projects);
        const productNames = projects.map((item) => item.name);
        console.log(productNames);
        setactiveNames(productNames);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    if (activeNames.length > 0) {
      const fetchData = async () => {
        const fetchDataPayload = {
          method: "get",
          what_to_get: "endpoint",
        };
        console.log(productData);
        try {
          const response = await axios.post(
            `${baseURL}/Prod/get`,
            JSON.stringify(fetchDataPayload)
          );
          const products = JSON.parse(response.data.body);
          const filteredProducts = products.filter((item) =>
            activeNames.includes(item.productname)
          );
          console.log("filtered", filteredProducts);
          console.log("inside format");

          const formattedData = filteredProducts.map((item, index) => {
            const { productname, frontendurl, backendurl, repourl } = item;
            return {
              name: productname,
              frontendLink:
                frontendurl && frontendurl.length > 0 ? frontendurl[0] : [],
              backendLinks:
                backendurl && backendurl.length > 0 ? backendurl : [],
              repourl: repourl,
            };
          });
          setLinks(formattedData);
          setdetailsLoading(false);
          console.log("format", formattedData);
        } catch (error) {
          console.log(console.error);
        }
      };
      fetchData();
    }
  }, [activeNames]);

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => handleViewDetails(record)}>View Details</Button>
      ),
    },
  ];

  const dataSource = productData.map((product) => ({
    key: product.name,
    name: product.name,
    created_by: product.username,
    created_date: product.date_time,
  }));

  const handleViewDetails = (record) => {
    const fullProduct = productData.find(
      (product) => product.name === record.name
    );
    setSelectedProduct(fullProduct);
    setModalVisible(true);
    const selectedProdLinks = links.find((item) => item.name === record.name);
    setSelectedProductLinks(selectedProdLinks);
    console.log(selectedProductLinks);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      {/* <Row  style={{ marginTop: "40px" }}>
        <Col span={16}> */}
      {detailsLoading && activeNames.length !== 0  ? (
        <Spin size="medium" />
      ) : activeNames.length === 0 && productData.length === 0 ? (
        <Typography.Text>No active products found yet</Typography.Text>
      ) : (
        <Table dataSource={dataSource} columns={columns} bordered />
      )}
      {console.log(links)}
      {/* {console.log(links[0].repourl)} */}
      <Modal
        title={<p>Product Details</p>}
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedProduct && (
          <div>
            <Title level={4} style={{ fontStyle: "normal" }}>
              Product Name: <span>{selectedProduct.name} </span>
            </Title>
            <Paragraph>
              <span className="modal-style">Cloud: </span>{" "}
              <span>
                {selectedProduct.cloud === "aws"
                  ? "AWS"
                  : selectedProduct.cloud === "gcp"
                  ? "GCP"
                  : "Azure"}
              </span>
            </Paragraph>
            <Paragraph>
              <span className="modal-style">Product Created on: </span>{" "}
              <span>{selectedProduct.date_time}</span>
            </Paragraph>

            <Paragraph>
              <span className="modal-style">Repo Link: </span> <br />
              <a
                href={selectedProductLinks.repourl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {selectedProductLinks.repourl}{" "}
              </a>
            </Paragraph>
            {selectedProduct.cloud === "aws" && (
              <>
                {/* Add other Paragraphs for additional sections */}
                {selectedProduct.frontend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b>Frontend Specifications </b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Frontend Techstack: </span>
                      <span>
                        {selectedProduct.frontend_techstack === "react"
                          ? "React"
                          : "Angular"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Frontend Service: </span>
                      <span>
                        {selectedProduct.frontend_service === "aws_s3_cf"
                          ? "AWS S3 and CloudFront"
                          : "AppRunner"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                {selectedProduct.backend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b> Backend Specifications</b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Backend Techstack: </span>{" "}
                      <span>{selectedProduct.backend_techstack}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Backend Service: </span>{" "}
                      <span>
                        {selectedProduct.backend_service === "aws_apprunner"
                          ? "AppRunner"
                          : "Lambda and API gateway"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Microservices: </span>{" "}
                      <span>{selectedProduct.microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">
                        Backend Microservices:{" "}
                      </span>
                      <span>{selectedProduct.backend_microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Database: </span>
                      <span>
                        {selectedProduct.database === "mysql"
                          ? "Mysql"
                          : "PostgreSQL"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                <Divider orientation="left" orientationMargin={0}>
                  <b>Deployed Enpoints </b>
                </Divider>
                {selectedProductLinks.frontendLink[0] !== null && (
                  <Paragraph>
                    <span className="modal-style">Frontend Link: </span>
                    <br />
                    <a
                      href={selectedProductLinks.frontendLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedProductLinks.frontendLink}
                    </a>
                  </Paragraph>
                )}
                {selectedProductLinks.backendLinks.length > 0 && (
                  <div className="backend-links">
                    <Paragraph>
                      <span className="modal-style">Backend Links: </span>
                    </Paragraph>
                    <ol>
                      {selectedProductLinks.backendLinks.map((link, index) => (
                        <li key={index}>
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link}
                          </a>
                        </li>
                      ))}
                    </ol>
                  </div>
                )}
              </>
            )}
            {selectedProduct.cloud === "gcp" && (
              <>
                {/* Add other Paragraphs for additional sections */}
                {selectedProduct.frontend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b>Frontend Specifications </b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Frontend Techstack: </span>
                      <span>
                        {selectedProduct.frontend_techstack === "react"
                          ? "React"
                          : "Angular"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Frontend Service: </span>
                      <span>
                        {selectedProduct.frontend_service === "gcs-cdn"
                          ? "Google Cloud Storage and CDN"
                          : "CloudRun"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                {selectedProduct.backend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b> Backend Specifications</b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Backend Techstack: </span>{" "}
                      <span>{selectedProduct.backend_techstack}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Backend Service: </span>{" "}
                      <span>
                        {selectedProduct.backend_service ===
                        "gcp_cloud_function"
                          ? "CloudFunction"
                          : "CloudRun"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Microservices: </span>{" "}
                      <span>{selectedProduct.microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">
                        Backend Microservices:{" "}
                      </span>
                      <span>{selectedProduct.backend_microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Database: </span>
                      <span>
                        {selectedProduct.database === "mysql"
                          ? "Mysql"
                          : "PostgreSQL"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                <Divider orientation="left" orientationMargin={0}>
                  <b>Deployed Enpoints </b>
                </Divider>
                {selectedProductLinks.frontendLink[0] !== null && (
                  <Paragraph>
                    <span className="modal-style">Frontend Link: </span>
                    <br />
                    <a
                      href={selectedProductLinks.frontendLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedProductLinks.frontendLink}
                    </a>
                  </Paragraph>
                )}
                {selectedProductLinks.backendLinks.length > 0 && (
                  <div className="backend-links">
                    <Paragraph>
                      <span className="modal-style">Backend Links: </span>
                    </Paragraph>
                    <ol>
                      {selectedProductLinks.backendLinks.map((link, index) => (
                        <li key={index}>
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link}
                          </a>
                        </li>
                      ))}
                    </ol>
                  </div>
                )}
              </>
            )}
            {selectedProduct.cloud === "azure" && (
              <>
                {/* Add other Paragraphs for additional sections */}
                {selectedProduct.frontend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b>Frontend Specifications </b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Frontend Techstack: </span>
                      <span>
                        {selectedProduct.frontend_techstack === "react"
                          ? "React"
                          : "Angular"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Frontend Service: </span>
                      <span>
                        {selectedProduct.frontend_service === "azure_webapp"
                          ? "Azure Webapp"
                          : "Azure Blob Storage and CDN"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                {selectedProduct.backend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b> Backend Specifications</b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Backend Techstack: </span>{" "}
                      <span>{selectedProduct.backend_techstack}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Backend Service: </span>{" "}
                      <span>
                        {selectedProduct.backend_service === "azure_webapp"
                          ? "Azure WebApp"
                          : "Azure Functions"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Microservices: </span>{" "}
                      <span>{selectedProduct.microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">
                        Backend Microservices:{" "}
                      </span>
                      <span>{selectedProduct.backend_microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Database: </span>
                      <span>
                        {selectedProduct.database === "mysql"
                          ? "Mysql"
                          : "PostgreSQL"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                <Divider orientation="left" orientationMargin={0}>
                  <b>Deployed Enpoints </b>
                </Divider>
                {selectedProductLinks.frontendLink[0] !== null && (
                  <Paragraph>
                    <span className="modal-style">Frontend Link: </span>
                    <br />
                    <a
                      href={selectedProductLinks.frontendLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedProductLinks.frontendLink}
                    </a>
                  </Paragraph>
                )}
                {selectedProductLinks.backendLinks.length > 0 && (
                  <div className="backend-links">
                    <Paragraph>
                      <span className="modal-style">Backend Links: </span>
                    </Paragraph>
                    <ol>
                      {selectedProductLinks.backendLinks.map((link, index) => (
                        <li key={index}>
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link}
                          </a>
                        </li>
                      ))}
                    </ol>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Modal>
      {/* </Col>
      </Row> */}
    </>
  );
};

export default AdminActiveProducts;
