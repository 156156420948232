import React, { useState } from "react";
import { useApplogContext } from "../../Context/AppLogsContext/ApplogContext";
import { Button, Card, Row, Typography } from "antd";
import Title from "antd/es/typography/Title";

const ShowLogs = () => {
  const { Text, Paragraph } = Typography;
  const { awsAppLog, gcpAppLog, productName,serviceName, cloud} = useApplogContext();
  const [showFullResponse, setShowFullResponse] = useState(false);
  if(cloud ==="aws"){
    if (awsAppLog === null) {
        return (
          <Row justify="center" align="center">
            <Card
              justify="center"
              style={{
                marginTop: "10vh",
                width: "40rem",
                backgroundColor: "#4a8ac620",
              }}
            >
              <Title level={5}>Logs are not available</Title>
            </Card>
          </Row>
        );
      }
  }
  

  let maxLines =0;

let formattedData= {};
if(cloud ==="aws"){
  formattedData = awsAppLog;
  maxLines = 6000;
}
else if (cloud ==="gcp"){
    formattedData = JSON.stringify(gcpAppLog, null, 2);
    maxLines=6000;
}

  return (
    <>
      <Row justify="center" align="center">
        <Card
          justify="center"
          style={{
            marginTop: "10vh",
            width: "80rem",
            backgroundColor: "#4a8ac620",
          }}
        >
          <div>
            
            <Title level={4}>Logs of {serviceName} for product: {productName} </Title>
          </div>

          <Paragraph>
            <div
              style={{
                overflowY: "scroll",
                maxHeight: showFullResponse ? "100%" : "500px", // Adjust the maximum height as needed
                border: "1px solid #e8e8e8",
                padding: "8px",
                fontFamily: "monospace",
              }}
            >
              <pre>
                <code>
                  {" "}
                  {formattedData.split("\n").slice(0, maxLines).join("\n")}
                </code>
              </pre>
            </div>

            {!showFullResponse && (
              <Button onClick={() => setShowFullResponse(true)}>
                Show More
              </Button>
            )}
          </Paragraph>
          <p>Please note only the logs of past 24 hours are shown here. For any additional logs or support, please contact your devops POC.</p>
        </Card>
      </Row>
    </>
  );
};

export default ShowLogs;
