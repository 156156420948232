import React, { useEffect, useState } from "react";
import { useWebSocket } from "../../Context/WebsocketContext/WebsocketContext";

function WebSocketClient() {
  const { setReceivedData, setConnectionID, logs, setLogs, connectionID } = useWebSocket();

  useEffect(() => {
    const handleWebSocketMessage = (event) => {
      const message = event.data;
      console.log("Received message from server:", message);
      const parsedData = JSON.parse(message);
      setConnectionID(parsedData.connectionId);
      setLogs((prevLogs) => [...prevLogs, parsedData.logs]);
    };

    const socket = new WebSocket(
      "wss://1o58zzbamc.execute-api.us-east-1.amazonaws.com/prod"
    );
    

    // WebSocket event listeners
    socket.onopen = () => {
      console.log("WebSocket connection opened.");
      socket.send("Hello Server!");
    //   const keepAliveInterval = setInterval(sendKeepAlive, 20 * 60 * 1000);

      return null;
    };

    

    socket.onmessage = handleWebSocketMessage;

    socket.onclose = () => {
      console.log("WebSocket connection closed.");
      setConnectionID(null);
    };

    return () => {
      socket.close();
    };
  }, [setLogs]);

  return null;
}

export default WebSocketClient;
