import React, { useRef } from "react";
import axios from "axios";
import { Card, Row, Space, List, Typography, Spin } from "antd";
import { useState, useEffect } from "react";
import Paragraph from "antd/es/typography/Paragraph";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Stillsvg } from "../../assets/Completed-pana.svg";
import { default as AnimateSvg } from "../../assets/Work time.gif";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
// import { useWebSocket } from "../../Context/WebsocketContext/WebSocketContext";
import { useFormValueContext } from "../../Context/FormContext/FormContext";

// const baseURL = process.env.REACT_APP_API_URL;


const Creating = (visible, onCancel, onOk) => {
  const [showCard, setShowCard] = useState(null);
  const [frontendURL, setfrontendURL] = useState([]);
  const [backendURL, setbackendURL] = useState([]);
  const [logs, setlogs] = useState([]);
  const [createLog, setCreateLog] = useState([]);
  const [isCompleted, setisCompleted] = useState(false);
  const [logssuccess, setlogssuccess] = useState("");
  const [repoLink, setrepoLink] = useState(null);
  const [linkLoading, setLinkLoading] = useState(true);
  const [logStatus, setlogStatus] = useState(null);
  const [lastLogIndex, setLastLogIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [lastLog, setLastLog] = useState(null);
  const { Title } = Typography;
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  // const { logs, errorFlag,setLogs } = useWebSocket();
  const { productName } = useFormValueContext();
  const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";
  // const productName= "oldlogs";

  console.log(productName);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchCreateLogs = async () => {
    let logsPayload = {};
    logsPayload = {
      method: "get",
      what_to_get: "logs",
      productname: productName,
    };

    try {
      const createlogsResponse = await axios.post(
        `${baseURL}/Prod/get`,
        JSON.stringify(logsPayload)
      );
      console.log("logs Response", createlogsResponse);
      const message= createlogsResponse.data.body;
      const msg = JSON.parse(message);
      const error_flag = msg[0].error_flag;
      console.log("msg", msg[0].log);
      console.log("messa", msg[0].error_flag);
      setlogStatus(error_flag);
      const logValue = msg[0].log;
      if (logValue !== lastLog) {
        setlogs((prevLogs) => [...prevLogs, logValue]);
        setLastLog(logValue);
        console.log(logs);
      }
      const isProcessCompleted = message === "Setup Of Infrastructure Done..";
      setisCompleted(isProcessCompleted);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (logStatus === "Failed") {
        console.log("failed");
        setLoading(false);
        setlogssuccess(false);
        const timer = setTimeout(() => {
          navigate("/");
        }, 45000);

        return () => {
          setlogs([]);
          clearTimeout(timer);
        };
      }

      if (!logs.includes("Setup Of Infrastructure Done.." )) {
        fetchCreateLogs();
        console.log("loggguu",logs);
      }
      
      if (logs.includes("Setup Of Infrastructure Done..")) {
        // setlogs("Setup Of Infrastructure Done..");
        const timer1 = setTimeout(() => {
          setShowCard(true);
        }, 7000);
        setlogssuccess(true);
        setLoading(false);
        setLinkLoading(false);
        // setShowCard(true);
        setisCompleted(true);
        console.log("abc");
        console.log(showCard);
        const timer = setTimeout(() => {
          navigate("/");
          setlogs([]);
        }, 45000);

        return () => {
          setlogs([]);
          clearTimeout(timer1);
          clearTimeout(timer);
        };
      }
    }, 2000);
    

    console.log(showCard);
    return () => clearInterval(intervalId);
  }, [logs, showCard]);


  // useEffect(() => {
  //   const intervalId = setInterval(() => {



  //     if (errorFlag === "Failed") {
  //       setLinkLoading(false);
  //       setlogssuccess(false);
  //       const timer = setTimeout(() => {
  //         navigate("/");
  //       }, 45 * 1000);
  //       return () => {
  //         clearTimeout(timer);
  //       };
  //     }
  //     console.log("loggguu", logs);
  //     console.log(createLog);
  //     if (logs.length > lastLogIndex) {
  //       const newLogs = logs.slice(lastLogIndex);
  //       // setCreateLog((prevLogs) => [...prevLogs, ...newLogs]);
  //       setCreateLog(logs); 
  //       setLastLogIndex(logs.length);
  //     }
  //     if (createLog.includes("Setup Of Infrastructure Done..")) {
  //       setisCompleted(true);
  //     }

  //     if (
  //       createLog.includes("Setup Of Infrastructure Done..") &&
  //       isCompleted
  //     ) {
  //       const timer1 = setTimeout(() => {
  //         setShowCard(true);
  //       }, 7 * 1000);
  //       setLinkLoading(false);
  //       setlogssuccess(true);
  //       setisCompleted(true);
  //       const timer = setTimeout(() => {
  //         // setLogs([]);
  //         setCreateLog([]);
  //         navigate("/");
  //         // console.log("logs navigate", logs);
  //         console.log(isCompleted);
  //       }, 60 * 1000);
  //       return () => {
  //         clearTimeout(timer1);
  //         clearTimeout(timer);
  //       };
  //     }
  //   }, 2000);

  //   console.log(showCard);
  //   return () => clearInterval(intervalId);
  // }, [logs, showCard, isCompleted, logssuccess, linkLoading]);

  

  useEffect(() => {
    if (showCard) {
      const fetchData = async () => {
        const fetchDataPayload = {
          method: "get",
          what_to_get: "endpoint",
        };
        try {
          const response = await axios.post(
            `${baseURL}/Prod/get`,
            JSON.stringify(fetchDataPayload)
          );
          // console.log(response.data.body);
          const products = JSON.parse(response.data.body);
          const product = products.find((p) => p.productname === productName);
          console.log(productName);

          if (product) {
            const frontendURLs = product.frontendurl || [];
            const backendURLs = product.backendurl || [];
            const repoURL = product.repourl || [];
            console.log("Repo url", repoURL);
            console.log("Frontend URLs:", frontendURLs);
            console.log("Backend URLs:", backendURLs);
            setfrontendURL(frontendURLs);
            setbackendURL(backendURLs);
            setrepoLink(repoURL);
            setLinkLoading(false);
          } else {
            console.log("No products Yet");
            fetchData();
          }
        } catch (error) {
          console.log(console.error);
        }
      };
      fetchData();
    }
  }, [showCard]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }, [logs]);

  console.log("out of creating useEffect", frontendURL);
  console.log("out of creating useEffect", backendURL);

  return (
    <div className="creating-container">
      {" "}
      <Row justify="center" align="center">
        <Card
          justify="center"
          style={{
            marginTop: "10vh",
            width: "60rem",
            backgroundColor: "#4a8ac620",
          }}
        >
          <Space direction="horizontal" align="start">
            <div>
              <Space justify="center" align="center" direction="vertical">
                <p>
                  {" "}
                  <span style={{ color: "red" }}> *</span> Please do not refresh
                  the page till the process is completed.
                </p>{" "}
                {isCompleted ? (
                  <Stillsvg
                    style={{
                      width: "350px",
                      height: "350px",
                    }}
                  />
                ) : (
                  <img
                    src={AnimateSvg}
                    alt="Process in Progress"
                    style={{
                      borderRadius: "20px",
                      height: "450px",
                      width: "450px",
                    }}
                  />
                )}{" "}
              </Space>
            </div>

            <div>
              <Space direction="vertical">
                <div>
                  <p style={{ fontSize: "18px" }}>
                    {" "}
                    Product name :{productName}
                  </p>

                  <p style={{ fontSize: "14px" }}>
                    Providing the Infrastructure
                  </p>
                  <div className="separator" style={{ width: "340px" }}></div>
                  <div
                    style={{
                      marginTop: "10px",
                      border: "1px solid #00000080",
                      height: "300px",
                      width: "360px",
                      borderRadius: "20px",
                    }}
                  >
                    <div
                      ref={scrollContainerRef}
                      style={{
                        whiteSpace: "nowrap",
                        overflowY: "auto",
                        maxHeight: "280px",
                        scrollbarWidth: "thin",
                        scrollbarColor: "dark",
                      }}
                    >
                      <br />
                      {/* <p>{receivedData.message}</p> */}
                      {logs?.length > 0 ? (
                        <List
                          style={{ whiteSpace: "normal" }}
                          size="small"
                          dataSource={logs.filter(
                            (log) => log !== undefined
                          )}
                          renderItem={(log, index) => (
                            <div className="list-item-container" key={index}>
                              <List.Item>{log}</List.Item>{" "}
                            </div>
                          )}
                        />
                      ) : (
                        <p> No logs available yet</p>
                      )}
                    </div>
                  </div>
                  <div>
                    {linkLoading ? (
                      <Space size="middle">
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 24 }} spin />
                          }
                        />
                      </Space>
                    ) : logssuccess ? (
                      <Space size="middle">
                        <CheckCircleOutlined
                          style={{
                            fontSize: 24,
                            color: "green",
                            margin: "10px",
                          }}
                        />
                      </Space>
                    ) : (
                      <Space size="middle">
                        <StopOutlined
                          style={{ fontSize: 24, color: "maroon" }}
                        />
                      </Space>
                    )}
                  </div>
                </div>
                <div>
                  {showCard ? (
                    <>
                      {console.log("abcdef")}
                      <div className="separator"></div>
                      {productName !== undefined && (
                        <>
                          <div>
                            {frontendURL[0] !== null &&
                              frontendURL.map((flink, index) => (
                                <div>
                                  <Title level={5}>
                                    Deployed Frontend endpoint:{" "}
                                  </Title>
                                  <div key={index}>
                                    <Paragraph>
                                      <a
                                        href={flink}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {flink}
                                      </a>
                                    </Paragraph>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div>
                            {backendURL.length > 0 &&
                              backendURL.map((blink, index) => (
                                <div>
                                  <Title level={5}>
                                    Deployed Backend endpoint {index + 1}:{" "}
                                  </Title>
                                  <div key={index}>
                                    <Paragraph>
                                      <a
                                        href={blink}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {blink}
                                      </a>
                                    </Paragraph>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                      {repoLink !== null && (
                        <>
                          <Title level={5}>Repo link : </Title>
                          <Paragraph>
                            <a href={repoLink} target="_blank" rel="noreferrer">
                              {repoLink}
                            </a>
                          </Paragraph>
                        </>
                      )}
                    </>
                  ) : (
                    <Spin />
                  )}
                </div>
              </Space>
            </div>
          </Space>
        </Card>
      </Row>
    </div>
  );
};

export default Creating;
