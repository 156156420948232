import axios from "axios";
import {
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext/UserContext";

const { Title, Paragraph } = Typography;

const InactiveProducts = () => {
  const { user } = useContext(UserContext);
  const [inactiveProd, setInactiveProd] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductLinks, setSelectedProductLinks] = useState(null);
  const [inactiveNames, setinactiveNames] = useState([]);
  const [inactiveSpin, setinactiveSpin] = useState(true);
  const [links, setLinks] = useState([]);
  // const baseURL = process.env.REACT_APP_API_URL;
const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";
  const userMail = user.email;

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchInactiveProdcuts = async () => {
      let inActivePaylaod = {};
      inActivePaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Deleted",
      };
      try {
        
        const inActiveResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(inActivePaylaod)
        );

        const inActiveData = inActiveResponse.data.body;
        
        setInactiveProd(inActiveData);
        const productNames = inActiveData.map((item) => item.name);
        setinactiveNames(productNames);
      } catch (error) {
        console.log ("Error fetching",error);
        setinactiveSpin(false);
      }
    };
    fetchInactiveProdcuts();
  }, []);

  useEffect(() => {
    
    if (inactiveNames.length > 0) {
      const fetchData = async () => {
        const fetchDataPayload = {
          method: "get",
          what_to_get: "endpoint",
        };
        try {
          
          const response = await axios.post(
            `${baseURL}/Prod/get`,
            JSON.stringify(fetchDataPayload)
          );
          const products = JSON.parse(response.data.body);
          const filteredProducts = products.filter((item) =>
            inactiveNames.includes(item.productname)
          );
          
          

          const formattedData = filteredProducts.map((item, index) => {
            const { productname, frontendurl, backendurl, repourl } = item;
            return {
              name: productname,
              frontendLink:
                frontendurl && frontendurl.length > 0 ? frontendurl[0] : [],
              backendLinks:
                backendurl && backendurl.length > 0 ? backendurl : [],
              repourl: repourl,
            };
          });
          setLinks(formattedData);
          setinactiveSpin(false);
          
        } catch (error) {
          console.log ("Error fetching",error);
          setinactiveSpin(false);
        }
      };
      fetchData();
    }
    else {
      setinactiveSpin(false);
    }
  }, [inactiveNames]);

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Deleted Date",
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => handleViewDetails(record)}>View Details</Button>
      ),
    },
  ];

  const dataSource = inactiveProd.map((product) => ({
    key: product.name,
    name: product.name,
    created_by: product.username,
    created_date: product.date_time,
  }));

  const handleViewDetails = (record) => {
    const fullProduct = inactiveProd.find(
      (product) => product.name === record.name
    );
    setSelectedProduct(fullProduct);
    setModalVisible(true);
    const selectedProdLinks = links.find((item) => item.name === record.name);
    setSelectedProductLinks(selectedProdLinks);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      {inactiveSpin ? (
        <Spin size="medium" />
      ) : (inactiveProd.length === 0) ? (
        <Typography.Text>No inactives products found</Typography.Text>
      ) : (
        <Table dataSource={dataSource} columns={columns} bordered />
      )}
      <Modal
        title="Product Details"
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedProduct && (
          <div className="product-view-modal">
            <Title level={4} style={{ fontStyle: "normal" }}>
              Product Name: <span>{selectedProduct.name} </span>
            </Title>
            <Paragraph>
              <span className="modal-style">Cloud: </span>{" "}
              <span>
                {selectedProduct.cloud === "aws"
                  ? "AWS"
                  : selectedProduct.cloud === "gcp"
                  ? "GCP"
                  : "Azure"}
              </span>
            </Paragraph>
            <Paragraph>
              <span className="modal-style">Product Created on: </span>{" "}
              <span>{selectedProduct.date_time}</span>
            </Paragraph>
          
            <Paragraph>
              <span className="modal-style">Repo Link: </span> <br />
              <a
                href={selectedProductLinks.repourl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {selectedProductLinks.repourl}{" "}
              </a>
            </Paragraph>

            {selectedProduct.cloud === "aws" && (
              <>
                {/* Add other Paragraphs for additional sections */}
                {selectedProduct.frontend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b>Frontend Specifications </b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Frontend Techstack: </span>
                      <span>
                        {selectedProduct.frontend_techstack === "react"
                          ? "React"
                          : "Angular"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Frontend Service: </span>
                      <span>
                        {selectedProduct.frontend_service === "aws_s3_cf"
                          ? "AWS S3 and CloudFront"
                          : "AppRunner"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                {selectedProduct.backend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b> Backend Specifications</b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Backend Techstack: </span>{" "}
                      <span>{selectedProduct.backend_techstack}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Backend Service: </span>{" "}
                      <span>
                        {selectedProduct.backend_service === "aws_apprunner"
                          ? "AppRunner"
                          : "Lambda and API gateway"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Microservices: </span>{" "}
                      <span>{selectedProduct.microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">
                        Backend Microservices:{" "}
                      </span>
                      <span>{selectedProduct.backend_microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Database: </span>
                      <span>
                        {selectedProduct.database === "mysql"
                          ? "Mysql"
                          : "PostgreSQL"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                
              </>
            )}
            {selectedProduct.cloud === "gcp" && (
              <>
                {/* Add other Paragraphs for additional sections */}
                {selectedProduct.frontend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b>Frontend Specifications </b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Frontend Techstack: </span>
                      <span>
                        {selectedProduct.frontend_techstack === "react"
                          ? "React"
                          : "Angular"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Frontend Service: </span>
                      <span>
                        {selectedProduct.frontend_service === "gcs-cdn"
                          ? "Google Cloud Storage and CDN"
                          : "CloudRun"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                {selectedProduct.backend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b> Backend Specifications</b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Backend Techstack: </span>{" "}
                      <span>{selectedProduct.backend_techstack}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Backend Service: </span>{" "}
                      <span>
                        {selectedProduct.backend_service ===
                        "gcp_cloud_function"
                          ? "CloudFunction"
                          : "CloudRun"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Microservices: </span>{" "}
                      <span>{selectedProduct.microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">
                        Backend Microservices:{" "}
                      </span>
                      <span>{selectedProduct.backend_microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Database: </span>
                      <span>
                        {selectedProduct.database === "mysql"
                          ? "Mysql"
                          : "PostgreSQL"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                
              </>
            )}
            {selectedProduct.cloud === "azure" && (
              <>
                {/* Add other Paragraphs for additional sections */}
                {selectedProduct.frontend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b>Frontend Specifications </b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Frontend Techstack: </span>
                      <span>
                        {selectedProduct.frontend_techstack === "react"
                          ? "React"
                          : "Angular"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Frontend Service: </span>
                      <span>
                        {selectedProduct.frontend_service === "azure_webapp"
                          ? "Azure Webapp"
                          : "Azure Blob Storage and CDN"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                {selectedProduct.backend === "Yes" && (
                  <div>
                    <Divider orientation="left" orientationMargin={0}>
                      <b> Backend Specifications</b>
                    </Divider>
                    <Paragraph>
                      <span className="modal-style">Backend Techstack: </span>{" "}
                      <span>{selectedProduct.backend_techstack}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Backend Service: </span>{" "}
                      <span>
                        {selectedProduct.backend_service === "azure_webapp"
                          ? "Azure WebApp"
                          : "Azure Functions"}
                      </span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Microservices: </span>{" "}
                      <span>{selectedProduct.microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">
                        Backend Microservices:{" "}
                      </span>
                      <span>{selectedProduct.backend_microservices}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="modal-style">Database: </span>
                      <span>
                        {selectedProduct.database === "mysql"
                          ? "Mysql"
                          : "PostgreSQL"}
                      </span>
                    </Paragraph>
                  </div>
                )}
                
              </>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default InactiveProducts;
