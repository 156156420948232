import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  Row,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import axios from "axios";
import { UserContext } from "../../Context/UserContext/UserContext";
import Title from "antd/es/typography/Title";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const AdminPendingProducts = () => {
  const { user } = useContext(UserContext);
  const [pendingData, setpendingData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [detailsLoading, setdetailsLoading] = useState(true);
  const [approvedData, setapprovedData] = useState([]);
  const userMail = user.email;
  const [pendingLoading, setpendingLoading] = useState(null);
  const navigate = useNavigate();
  let userName = user.name;
  
  function reorderName(name) {
    const nameParts = name.split(",").map((part) => part.trim());
    const reversedName = nameParts.reverse();
    const correctOrderName = reversedName.join(" ");
    return correctOrderName;
  }
  
  const formattedName = reorderName(userName);
  const date = new Date().toLocaleString();
  const baseURL = process.env.REACT_APP_API_URL;
  const authToken = process.env.REACT_APP_AUTH_TOKEN;

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchPendingProducts = async () => {
      let pendingPaylaod = {};
      pendingPaylaod = {
        method: "get",
        what_to_get: "productdetails_admin",
        status: "Pending",
      };
      console.log(pendingPaylaod);

      try {
        console.log("try inside");
        const prodResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(pendingPaylaod)
        );
        console.log(prodResponse);
        const projects = prodResponse.data.body;
        console.log("product", projects);
        setpendingData(projects);
        console.log("pendingData", pendingData);
        const productNames = projects.map((item) => item.name);
        console.log(productNames);
        setdetailsLoading(false);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };
    fetchPendingProducts();

    const fetchApprovedProducts = async () => {
      let approvedPayload = {};
      approvedPayload = {
        method: "get",
        what_to_get: "productdetails_admin",
        status: "Approved",
      };
      console.log(approvedPayload);

      try {
        console.log("try inside");
        const approvedResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(approvedPayload)
        );

        const projects = approvedResponse.data.body;
        console.log("product", projects);
        setapprovedData(projects);
        console.log("approved", approvedData);
        const productNames = projects.map((item) => item.name);
        console.log(productNames);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };
    fetchApprovedProducts();
  }, []);
  const getColorForStatus = (status) => {
    if (status === "Pending") {
      return "#ffcc0090";
    } else if (status === "Approved") {
      return "#6495ED";
    }
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Requested On",
      dataIndex: "created_date",
      key: "created_date",
      width: "300px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      filters: [
        { text: "Pending", value: "Pending" },
        { text: "Approved", value: "Approved" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => (
        <>
          <Tag color={getColorForStatus(status)}>
            {" "}
            <span style={{ color: "black" }}>{status} </span>{" "}
            <Tooltip title={statusDescriptions[status]}>
              <InfoCircleOutlined style={{ color: "black" }} />{" "}
            </Tooltip>
          </Tag>{" "}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button
          // disabled={record.status === "Approved"}
          // disabled={record.status}
          disabled = "true"
          type="primary"
          onClick={() => handleApprovedClick(record)}
        >
          Approve
        </Button>
      ),
    },
  ];

  console.log("out of calls p:", pendingData);
  console.log("out of calls a:", approvedData);

  const pendingDataWithSource = pendingData.map((product) => ({
    ...product,
    status: "pending",
  }));
  const approvedDataWithSource = approvedData.map((product) => ({
    ...product,
    status: "approved",
  }));

  const combinedData = [...approvedDataWithSource, ...pendingDataWithSource];

  const statusDescriptions = {
    Pending: "This product is pending approval.",
    Approved: "This product has been approved and is not created yet.",
  };

  const dataSource = combinedData.map((product) => ({
    key: product.name,
    name: product.name,
    created_by: product.username,
    created_date: product.date_time,
    status: product.status === "pending" ? "Pending" : "Approved",
  }));

  const handleModalClose = () => {
    setModalVisible(false);
    navigateToCreatingPage();
  };

  const handleClose = () => {
    setModalVisible(false);
  };


  const handleApprovedClick = (record) => {
    console.log(record);
  };

  const navigateToCreatingPage = () => {
    console.log("navigating");
    if (selectedProduct) {
      const state = {
        expectedID: selectedProduct.name,
        frontService: selectedProduct.frontend_service,
      };
      navigate("/creating", { state });
    }
  };

  return (
    <div>
      {/* <Row align="center" style={{ marginTop: "40px" }}>
        <Col span={16}> */}
      {detailsLoading ? (
        <Spin size="medium" />
      ) : pendingData.length === 0 && approvedData.length === 0 ? (
        <Typography.Text>
          No products found for approval or pending
        </Typography.Text>
      ) : (
        <Table dataSource={dataSource} columns={columns} bordered />
      )}

      <Modal
        title={
          <p>
            <ExclamationCircleOutlined style={{ color: "#1677ff" }} /> Creating
            Infrastructure
          </p>
        }
        visible={modalVisible}
        onCancel={handleClose}
        onOk={handleModalClose}
      >
        <div>
          <p>
            Infrastructure Creation has been started. Click OK to check the
            Creation Progress.
          </p>
        </div>
      </Modal>
      {/* </Col>
      </Row> */}
    </div>
  );
};

export default AdminPendingProducts;
