import { Card, Col, Row, Space } from "antd";
import { Modal, Input, Form, Rate } from "antd";
import { React, useState, useEffect } from "react";
import { useContext } from "react";
import axios from "axios";
import "./UserProfile.css";
import welcome from "../../assets/banner.png";
import help from "../../assets/Help.png";
import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";
import { UserContext } from "../../Context/UserContext/UserContext";
import Doughnut from "../../components/UserProfileComponents/Doughnut";
import CardGrid from "../../components/UserProfileComponents/CardGrid";

const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};
const { TextArea } = Input;

const UserProfile = () => {
  const { user } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [links, setLinks] = useState([]);
  const [activeNames, setactiveNames] = useState([]);
  const [activeCount, setactiveCount] = useState();
  const [inactiveCount, setinactiveCount] = useState();
  const [approvedCount, setapprovedCount] = useState();
  const [pendingCount, setpendingCount] = useState();
  const [productData, setProductData] = useState(null);
  const [pendingData, setpendingData] = useState([]);
  // const baseURL = process.env.REACT_APP_API_URL;
  const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";

  function reorderName(name) {
    const nameParts = name.split(",").map((part) => part.trim());
    const reversedName = nameParts.reverse();
    const correctOrderName = reversedName.join(" ");
    return correctOrderName;
  }

  let userName = user.name;
  // let userName = "Vijay Kumar, Chatakondu";
  const formattedName = reorderName(userName);
  // const formattedName = userName.replace(
  //   /(\w+)\s*,\s*(\w+)/,
  //   (_, first, last) => `${last} ${first}`
  // );

  const userMail = user.email;
  


  // modal handling functions
  const handleAnchorClick = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // api calls
  useEffect(() => {
    // fetch inprogress product details
    const fetchProducts = async () => {
      let productPaylaod = {};
      productPaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "InProgress",
      };
      

      try {
        
        const prodResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(productPaylaod)
        );
        const activedata = prodResponse.data.body;
        
        // const sliced = activedata;
        const sliced = activedata.slice(-1);
        setProductData(sliced);
        const productNames = sliced.map((item) => item.name);
        setactiveNames(productNames);
        
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchActiveProducts = async () => {
      let activePaylaod = {};
      activePaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "InProgress",
      };
      try {
        const activeResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(activePaylaod)
        );
        
        const activedata = activeResponse.data.body;
        setProductData(activedata);
        setactiveCount(Object.keys(activedata).length);
        
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchActiveProducts();

    const fetchInactiveProducts = async () => {
      let inActivePaylaod = {};
      inActivePaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Deleted",
      };
      try {
        
        const inActiveResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(inActivePaylaod)
        );

        const inActiveData = inActiveResponse.data.body;
        
        setinactiveCount(Object.keys(inActiveData).length);
        
        
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchInactiveProducts();

    const fetchPendingProducts = async () => {
      let pendingPaylaod = {};
      pendingPaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Pending",
      };
      

      try {
        
        const prodResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(pendingPaylaod)
        );
        
        const projects = prodResponse.data.body;
        
        setpendingData(projects);
        
        setpendingCount(Object.keys(projects).length);
        
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchPendingProducts();

    const fetchapprovedProducts = async () => {
      let approvedPaylaod = {};
      approvedPaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Approved",
      };
      try {
        
        const approvedResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(approvedPaylaod)
        );
        const approvedData = approvedResponse.data.body;
        
        setapprovedCount(Object.keys(approvedData).length);
        
        
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchapprovedProducts();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const fetchDataPayload = {
        method: "get",
        what_to_get: "endpoint",
      };
      try {
        const response = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(fetchDataPayload)
        );
        const products = JSON.parse(response.data.body);
        const filteredProducts = products.filter((item) =>
          activeNames.includes(item.productname)
        );
        
        

        const formattedData = filteredProducts.map((item, index) => {
          const { productname, frontendurl, backendurl, repourl } = item;
          return {
            name: productname,
            frontendLink:
              frontendurl && frontendurl.length > 0 ? frontendurl[0] : [],
            backendLinks: backendurl && backendurl.length > 0 ? backendurl : [],
            repourl: repourl,
          };
        });
        setLinks(formattedData);
        
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchData();
  }, [activeNames, productData]);

  

  return (
    <div className="user-profile-container">
      <Row gutter={[16, 16]} justify="center" style={{ marginLeft: "70px" }}>
        <Col flex={1} xs={24} sm={6} md={4} lg={4} align="center">
          <Card
            style={{
              borderRadius: "20px",
            }}
          >
            <Space direction="vertical" align="center" justify="center">
              <div
                className="profile-img-container"
                // style={{ height: "130px", width: "130px" }}
              >
                <div
                  className="circularBG"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    height: "90%",
                    width: "90%",
                    backgroundColor: "#4a8ac630",
                  }}
                >
                  {user.name.split(",")[1].charAt(1) + user.name.charAt(0)}
                </div>
              </div>
              <div className="text-container">
                <p className="user-name" style={{ fontSize: "13px" }}>
                  <b>{formattedName}</b>
                </p>
                <p style={{ fontSize: "12px" }}>Designation</p>
                <p
                  style={{
                    whiteSpace: "pre-wrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "center",
                    fontSize: "11px",
                  }}
                >
                  {userMail}
                </p>
              </div>
            </Space>
          </Card>
          <Col style={{ marginTop: "40px" }} align="center">
            <div>
              <Card
                align="center"
                title="Product Stats"
                style={{
                  borderRadius: "20px",
                }}
              >
                <Doughnut
                  activeNumber={activeCount}
                  inactiveNumber={inactiveCount}
                  pendingNumber={pendingCount + approvedCount}
                />
              </Card>
            </div>
          </Col>
        </Col>
        {/* Column 2 */}
        <Col span={15} flex={2} xs={24} sm={12} md={10} lg={14}>
          <Card style={{ borderRadius: "15px" }}>
            <Space direction="horizontal" size={22}>
              <div className="welcome-image">
                <img
                  src={welcome}
                  alt="welcome"
                  style={{ width: "370px", height: "210px" }}
                />
              </div>
              <div className="welcome-text">
                <h4 style={{ fontSize: "16px" }}>Welcome To HiCloud Platform </h4>{" "}
                <br />
                <div>
                  <p>One stop solution for cloud infra provision!!!</p> <br />
                  HiCloud empowers developers to effortlessly provision and
                  configure cloud environment in their desired cloud.
                </div>
              </div>
            </Space>
          </Card>

          <div style={{ marginTop: "30px", height: "300px" }}>
            <CardGrid />
          </div>
        </Col>
        {/* column 3 */}
        <Col span={11} flex={1} xs={24} sm={6} md={6} lg={5}>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRadius: "20px",
            }}
          >
            <Space direction="vertical" size={25} align="center">
              <div>
                <img
                  alt="help-img"
                  src={help}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
              <div style={{ fontSize: "12px" }}>
                <div>
                  <div>
                    {" "}
                    <p>
                      Need help ? Check out our <br />{" "}
                      <a
                        href="https://amedeloitte.sharepoint.com/:p:/s/HUDevopsSupport-Relay/Ee5A8mwOirNLoIU6CVRkaOUBy19BPriE6d_9NaCfe2L7Ww?e=8G47Dx&nav=eyJzSWQiOjI2NiwiY0lkIjoyODM5MjExODI3fQ"
                        target="_blank"
                        rel="noreferrer"
                      >
                        User Guide
                      </a>
                      .
                    </p>
                  </div>

                  <div>
                    <p>
                      For HiCloud related feedback or other issues, Please
                      <a target="_blank"
                      href="mailto:USHashedinHiCloudSupport@deloitte.com"> Contact here!!</a>
                    </p>{" "}
                  </div>
                </div>

                {/* <Modal
                  title="Relay"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Form>
                    <Form.Item
                      label="How is the overall experience of this tool?"
                      name="toolRating"
                    >
                      <Rate
                        character={({ index }) => customIcons[index + 1]}
                        style={{ color: "#4a8ac6" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Please describe your issue below."
                      name="contactFormIssue"
                    >
                      <TextArea
                        rows={4}
                        placeholder="Brief your issue here"
                        maxLength={6}
                      />
                    </Form.Item>
                  </Form>
                </Modal> */}
              </div>
            </Space>
          </Card>
          <Row gutter={[16, 16]} justify="center"></Row>
        </Col>
      </Row>
    </div>
  );
};

export default UserProfile;
