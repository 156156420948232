import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  Row,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import axios from "axios";
import { UserContext } from "../../Context/UserContext/UserContext";
import Title from "antd/es/typography/Title";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const PendingProducts = () => {
  const { user } = useContext(UserContext);
  const [pendingData, setpendingData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [detailsLoading, setdetailsLoading] = useState(true);
  const [approvedData, setapprovedData] = useState([]);
  const userMail = user.email;
  const [pendingLoading, setpendingLoading] = useState(null);
  const navigate = useNavigate();
  let userName = user.name;
  const formattedName = userName.replace(
    /(\w+)\s*,\s*(\w+)/,
    (_, first, last) => `${last} ${first}`
  );
  const date = new Date().toLocaleString();
  // const baseURL = process.env.REACT_APP_API_URL;
  const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";
  const authToken = process.env.REACT_APP_AUTH_TOKEN;

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchPendingProducts = async () => {
      let pendingPaylaod = {};
      pendingPaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Pending",
      };

      try {
        const prodResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(pendingPaylaod)
        );
        const projects = prodResponse.data.body;
        setpendingData(projects);
        const productNames = projects.map((item) => item.name);
        setdetailsLoading(false);
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchPendingProducts();

    const fetchApprovedProducts = async () => {
      let approvedPayload = {};
      approvedPayload = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "Approved",
      };


      try {

        const approvedResponse = await axios.post(
          `${baseURL}/Prod/get`,
          JSON.stringify(approvedPayload)
        );

        const projects = approvedResponse.data.body;

        setapprovedData(projects);

        const productNames = projects.map((item) => item.name);

      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchApprovedProducts();
  }, []);

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Requested On",
      dataIndex: "created_date",
      key: "created_date",
      width: "300px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Pending", value: "Pending" },
        { text: "Approved", value: "Approved" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => (
        <>
          <Tag color={getColorForStatus(status)}>
            {" "}
            <span style={{ color: "black" }}>{status} </span>{" "}
            <Tooltip title={statusDescriptions[status]}>
              <InfoCircleOutlined style={{ color: "black" }} />{" "}
            </Tooltip>
          </Tag>{" "}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button
          disabled={record.status === "Pending"}
          type="primary"
          onClick={() => handleApprovedClick(record)}
        >
          Create
        </Button>
      ),
    },
  ];




  const pendingDataWithSource = pendingData.map((product) => ({
    ...product,
    status: "pending",
  }));
  const approvedDataWithSource = approvedData.map((product) => ({
    ...product,
    status: "approved",
  }));

  const combinedData = [...approvedDataWithSource, ...pendingDataWithSource];

  const statusDescriptions = {
    Pending: "This product is pending for approval.",
    Approved: "This product has been approved and is not created yet.",
  };

  const getColorForStatus = (status) => {
    if (status === "Pending") {
      return "#ffcc0090";
    } else if (status === "Approved") {
      return "#6495ED";
    }
  };
  const dataSource = combinedData.map((product) => ({
    key: product.name,
    name: product.name,
    created_by: product.username,
    created_date: product.date_time,
    status: product.status === "pending" ? "Pending" : "Approved",
  }));

  const handleModalClose = () => {
    setModalVisible(false);
    navigateToCreatingPage();
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const emailApi = async (emailValues, prodStatus, mailType) => {
    const cloudtype = emailValues.cloud;




    let emailPayload = {};
    if (cloudtype === "aws") {
      const backendValueAws = emailValues.backend;
      const frontendValueAws = emailValues.frontend;

      if (backendValueAws === "No" && frontendValueAws === "Yes") {
        emailPayload = {
          productname: emailValues.name,
          cloud: cloudtype,
          username: userMail,
          uname: formattedName,
          mail_type: mailType,
          database: "Service not selected",
          repo_type: emailValues.repo_type,
          frontend: emailValues.frontend,
          frontend_techstack: emailValues.frontend_techStack,
          frontend_service: emailValues.frontend_service,
          backend: "Service not selected",
          microservices: "Service not selected",
          backend_service: "Service not selected",
          backend_techstack: "Service not selected",
          backend_microservices: 0,
          status: prodStatus,
          product_description: "Hello",
        };
      } else if (backendValueAws === "Yes" && frontendValueAws === "No") {
        emailPayload = {
          productname: emailValues.name,
          username: userMail,
          uname: formattedName,
          cloud: cloudtype,
          database: emailValues.database,
          mail_type: mailType,
          repo_type: emailValues.repo_type,
          frontend: "Service not selected",
          frontend_techstack: "Service not selected",
          frontend_service: "Service not selected",
          backend: emailValues.backend,
          microservices: emailValues.microservices,
          backend_service: emailValues.backend_service,
          backend_techstack: emailValues.backend_techstack,
          backend_microservices: emailValues.backend_microservices,
          status: prodStatus,
          product_description: "Hello",
        };
      } else if (backendValueAws === "No" && frontendValueAws === "No") {
        emailPayload = {
          productname: emailValues.name,
          username: userMail,
          uname: formattedName,
          cloud: cloudtype,
          database: "Service not selected",
          mail_type: mailType,
          repo_type: emailValues.repo_type,
          frontend: "Service not selected",
          frontend_techstack: "Service not selected",
          frontend_service: "Service not selected",
          backend: "Service not selected",
          microservices: "Service not selected",
          backend_service: "Service not selected",
          backend_techstack: "Service not selected",
          backend_microservices: "Service not selected",
          status: prodStatus,
          product_description: "Hello",
        };
      } else {
        emailPayload = {
          productname: emailValues.name,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: emailValues.database,
          repo_type: emailValues.repo_type,
          frontend: emailValues.frontend,
          frontend_techstack: emailValues.frontend_techStack,
          frontend_service: emailValues.frontend_service,
          backend: emailValues.backend,
          microservices: emailValues.microservices,
          backend_service: emailValues.backend_service,
          backend_techstack: emailValues.backend_techstack,
          backend_microservices: emailValues.backend_microservices,
          status: prodStatus,
          product_description: "Hello",
        };
      }
    } else if (cloudtype === "gcp") {
      const backendValueGcp = emailValues.backend;
      const frontendValueGcp = emailValues.frontend;


      if (backendValueGcp === "No" && frontendValueGcp === "Yes") {
        emailPayload = {
          productname: emailValues.name,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: "Service not selected",
          repo_type: emailValues.repo_type,
          frontend: emailValues.frontend,
          frontend_techstack: emailValues.frontend_techStack,
          frontend_service: emailValues.frontend_service,
          backend: "Service not selected",
          backend_microservices: "Service not selected",
          microservices: "Service not selected",
          backend_service: "Service not selected",
          backend_techstack: "Service not selected",
          status: prodStatus,
          product_description: "Hello",
        };
      } else if (backendValueGcp === "Yes" && frontendValueGcp === "No") {
        emailPayload = {
          productname: emailValues.name,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: emailValues.database,
          repo_type: emailValues.repo_type,
          frontend: "Service not selected",
          frontend_techstack: "Service not selected",
          frontend_service: "Service not selected",
          backend: emailValues.backend,
          microservices: emailValues.microservices,
          backend_service: emailValues.backend_service,
          backend_techstack: emailValues.backend_techstack,
          backend_microservices: emailValues.backend_microservices,
          status: prodStatus,
          product_description: "Hello",
        };
      } else if (backendValueGcp === "No" && frontendValueGcp === "No") {
        emailPayload = {
          productname: emailValues.name,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: null,
          repo_type: emailValues.repoType,
          frontend: "Service not selected",
          backend: "Service not selected",

          status: prodStatus,
          product_description: "Hello",
        };
      } else {
        emailPayload = {
          productname: emailValues.name,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: emailValues.database,
          repo_type: emailValues.repo_type,
          frontend: emailValues.frontend,
          frontend_techstack: emailValues.frontend_techStack,
          frontend_service: emailValues.frontend_service,
          backend: emailValues.backend,
          microservices: emailValues.microservices,
          backend_service: emailValues.backend_service,
          backend_techstack: emailValues.backend_techstack,
          backend_microservices: emailValues.backend_microservices,
          status: prodStatus,
          product_description: "Hello",
        };
      }
    } else if (cloudtype === "azure") {
      const backendValueAzure = emailValues.backendAzure;
      const frontendValueAzure = emailValues.frontendAzure;
      if (backendValueAzure === "No" && frontendValueAzure === "Yes") {
        emailPayload = {
          productname: emailValues.name,
          cloud: cloudtype,
          username: userMail,
          uname: formattedName,
          mail_type: mailType,
          database: "Service not selected",
          repo_type: emailValues.repo_type,
          frontend: emailValues.frontend,
          frontend_techstack: emailValues.frontend_techStack,
          frontend_service: emailValues.frontend_service,
          backend: "Service not selected",
          microservices: "Service not selected",
          backend_service: "Service not selected",
          backend_techstack: "Service not selected",
          backend_microservices: 0,
          status: prodStatus,
          product_description: "Hello",
        };
      } else if (backendValueAzure === "Yes" && frontendValueAzure === "No") {
        emailPayload = {
          productname: emailValues.name,
          username: userMail,
          uname: formattedName,
          cloud: cloudtype,
          database: emailValues.database,
          mail_type: mailType,
          repo_type: emailValues.repo_type,
          frontend: "Service not selected",
          frontend_techstack: "Service not selected",
          frontend_service: "Service not selected",
          backend: emailValues.backend,
          microservices: emailValues.microservices,
          backend_service: emailValues.backend_service,
          backend_techstack: emailValues.backend_techstack,
          backend_microservices: emailValues.backend_microservices,
          status: prodStatus,
          product_description: "Hello",
        };
      } else if (backendValueAzure === "No" && frontendValueAzure === "No") {
        emailPayload = {
          productname: emailValues.name,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          database: null,
          mail_type: mailType,
          repo_type: emailValues.repoType,
          frontend: "Service not selected",
          backend: "Service not selected",
          status: prodStatus,
          product_description: "Hello",
        };
      } else {
        emailPayload = {
          productname: emailValues.name,
          username: userMail,
          cloud: cloudtype,
          uname: formattedName,
          mail_type: mailType,
          database: emailValues.database,
          repo_type: emailValues.repo_type,
          frontend: emailValues.frontend,
          frontend_techstack: emailValues.frontend_techStack,
          frontend_service: emailValues.frontend_service,
          backend: emailValues.backend,
          microservices: emailValues.microservices,
          backend_service: emailValues.backend_service,
          backend_techstack: emailValues.backend_techstack,
          backend_microservices: emailValues.backend_microservices,
          status: prodStatus,
          product_description: "Hello",
        };
      }
    }


    try {
      const emailResponse = await axios.post(
        `${baseURL}/Prod/send_mail`,
        JSON.stringify(emailPayload)
      );
    } catch (error) {
      console.log ("Error fetching",error);
    }
  };

  const handleApprovedClick = (record) => {
    setpendingLoading(true);


    const fullProduct = combinedData.find(
      (product) => product.name === record.name
    );


    let dispatchPayload = {};
    if (fullProduct.cloud === "aws") {
      dispatchPayload = {
        event_type: "aws-api",
        client_payload: {
          aws_parameter: {
            name: fullProduct.name,
            username: userMail,
            git_username: fullProduct.github_id,
            "repo-type": fullProduct.repo_type,
            action: "Create",
            frontend: fullProduct.frontend,
            "frontend-techStack": fullProduct.frontend_techStack,
            "frontend-service": fullProduct.frontend_service,
            backend: fullProduct.backend,
            microservices: fullProduct.microservices,
            "backend-microservices": fullProduct.backend_microservices,
            "backend-service": fullProduct.backend_service,
            "backend-techStack": fullProduct.backend_techstack,
            database: fullProduct.database,
          },
        },
      };
    } else if (fullProduct.cloud === "gcp") {
      dispatchPayload = {
        event_type: "gcp-api",
        client_payload: {
          gcp_parameter: {
            name: fullProduct.name,
            username: userMail,
            git_username: fullProduct.github_id,
            "repo-type": fullProduct.repo_type,
            action: "Create",
            frontend: fullProduct.frontend,
            "frontend-techStack": fullProduct.frontend_techStack,
            "frontend-service": fullProduct.frontend_service,
            backend: fullProduct.backend,
            microservices: fullProduct.microservices,
            "backend-microservices": fullProduct.backend_microservices,
            "backend-service": fullProduct.backend_service,
            "backend-techStack": fullProduct.backend_techstack,
            database: fullProduct.database,
          },
        },
      };
    } else if (fullProduct.cloud === "azure") {
      dispatchPayload = {
        event_type: "azure-api",
        client_payload: {
          azure_parameter: {
            name: fullProduct.name,
            username: userMail,
            git_username: fullProduct.github_id,
            "repo-type": fullProduct.repo_type,
            action: "Create",
            frontend: fullProduct.frontend,
            "frontend-techStack": fullProduct.frontend_techStack,
            "frontend-service": fullProduct.frontend_service,
            backend: fullProduct.backend,
            microservices: fullProduct.microservices,
            "backend-microservices": fullProduct.backend_microservices,
            "backend-service": fullProduct.backend_service,
            "backend-techStack": fullProduct.backend_techstack,
            database: fullProduct.database,
          },
        },
      };
    }



    try {
      const creationWorkFlowResponse = axios.post(
        ` https://api.github.com/repos/Deloitte/prod-Relay-2.0/dispatches`,
        JSON.stringify(dispatchPayload),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setSelectedProduct(fullProduct);

      emailApi(fullProduct, "Creating", "acknowledgement");
      setModalVisible(true);
    } catch (error) {
      console.log ("Error fetching",error);
    }
  };

  const navigateToCreatingPage = () => {

    if (selectedProduct) {
      const state = {
        expectedID: selectedProduct.name,
        frontService: selectedProduct.frontend_service,
      };
      navigate("/creating", { state });
    }
  };

  return (
    <div>
      {/* <Row align="center" style={{ marginTop: "40px" }}>
        <Col span={16}> */}
      {detailsLoading ? (
        <Spin size="medium" />
      ) : pendingData.length === 0 && approvedData.length === 0 ? (
        <Typography.Text>
          No products pending for Approval found
        </Typography.Text>
      ) : (
        <Table dataSource={dataSource} columns={columns} bordered />
      )}

      <Modal
        title={
          <p>
            <ExclamationCircleOutlined style={{ color: "#1677ff" }} /> Creating
            Infrastructure
          </p>
        }
        visible={modalVisible}
        onOk={handleModalClose}
      >
        <div>
          <p>
            Infrastructure Creation has been started. Click OK to check the
            Creation Progress.
          </p>
        </div>
      </Modal>
      {/* </Col>
      </Row> */}
    </div>
  );
};

export default PendingProducts;
