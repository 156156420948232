import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminActiveProducts from "./AdminActiveProducts";
import AdminAllProducts from "./AdminAllProducts";
import AdminInactiveProducts from "./AdminInactiveProducts";
import AdminPendingProducts from "./AdminPendingProducts";

const contentList = {
    tab1:<AdminAllProducts />,
    tab2: <AdminActiveProducts />,
    tab3: <AdminInactiveProducts />,
    tab4: <AdminPendingProducts />,
  };

const AdminProductLists = () => {
    const [activeTabKey1, setActiveTabKey1] = useState("tab1");
    const [activeCount, setactiveCount] = useState();
    const [inactiveCount, setinactiveCount] = useState();
    const [pendingCount, setpendingCount] = useState();
    const [approvedCount, setapprovedCount] = useState();
    const baseURL = process.env.REACT_APP_API_URL;

    useEffect (() =>{
        const fetchactiveCount =async () =>{
          let activePaylaod = {};
          activePaylaod = {
            method: "get",
            what_to_get: "productdetails_admin",
            status: "InProgress",
          };
          console.log(activePaylaod);
          try {
            const activeResponse = await axios.post(
              `${baseURL}/Prod/get`,
              JSON.stringify(activePaylaod)
            );
            const activedata = activeResponse.data.body;
            setactiveCount(Object.keys(activedata).length);
            console.log ("active Count:", activeCount);
          }
          catch(error){
            console.log("Error:", error);
          }
        };
        fetchactiveCount();
        const fetchInactiveCount =async () =>{
          let inActivePaylaod = {};
          inActivePaylaod = {
            method: "get",
        what_to_get: "productdetails_admin",
        status: "Deleted",
          };
          console.log(inActivePaylaod);
          try {
            const inActiveResponse = await axios.post(
              `${baseURL}/Prod/get`,
              JSON.stringify(inActivePaylaod)
            );
            const inActivedata = inActiveResponse.data.body;
            setinactiveCount(Object.keys(inActivedata).length);
            console.log ("inactive Count:", inactiveCount);
          }
          catch(error){
            console.log("Error:", error);
          }
        };
        fetchInactiveCount ();
        const fetchPendingCount =async () =>{
          let pendingPaylaod = {};
          pendingPaylaod = {
            method: "get",
            what_to_get: "productdetails_admin",
            status: "Pending",
          };
          console.log(pendingPaylaod);
          try {
            const pendingResponse = await axios.post(
              `${baseURL}/Prod/get`,
              JSON.stringify(pendingPaylaod)
            );
            const pendingdata = pendingResponse.data.body;
            setpendingCount(Object.keys(pendingdata).length);
            console.log ("pending Count:", pendingCount);
          }
          catch(error){
            console.log("Error:", error);
          }
        };
        fetchPendingCount();
    
        const fetchApprovedCount =async () =>{
          let approvedPaylaod = {};
          approvedPaylaod = {
            method: "get",
            what_to_get: "productdetails_admin",
            status: "Approved",
          };
          console.log(approvedPaylaod);
          try {
            const approvedResponse = await axios.post(
              `${baseURL}/Prod/get`,
              JSON.stringify(approvedPaylaod)
            );
            const approveddata = approvedResponse.data.body;
            setapprovedCount(Object.keys(approveddata).length);
            console.log ("approved Count:", approvedCount);
          }
          catch(error){
            console.log("Error:", error); 
          }
        };
        fetchApprovedCount();
    
      },[]);

    const tabList = [
        {
          key: "tab1",
          tab: <p>All ({activeCount+inactiveCount+pendingCount+approvedCount })</p>,
        },
        {
          key: "tab2",
          tab: <p> Active ({activeCount})</p>,
        },
        {
          key: "tab3",
          tab: <p>Inactive ({inactiveCount })</p>,
        },
        {
          key: "tab4",
          tab: <p>Pending ({pendingCount+approvedCount })</p>,
        },
      ];


    const onTab1Change = (key) => {
        setActiveTabKey1(key);
      };
  return (
    <>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col>
          <Card
            style={{
              width: "130vh",
            }}
            title={<h1><div>Overall Product Data</div></h1>}
            tabList={tabList}
            activeTabKey={activeTabKey1}
            onTabChange={onTab1Change}
          >
            {contentList[activeTabKey1]}
          </Card>
        </Col>
      </Row>
      
    </>
  );
};

export default AdminProductLists;
