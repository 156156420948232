import React from "react";
import LandingPage from "../Home/LandingPage";
import { UserContextProvider } from "../../Context/UserContext/UserContext";
import { FormValueContextProvider } from "../../Context/FormContext/FormContext";
import { WebSocketProvider } from "../../Context/WebsocketContext/WebsocketContext";
import { ApplogProvider } from "../../Context/AppLogsContext/ApplogContext";

export default function Login() {
  return (
    <div className="login" style={{ height: "100vh" }}>
      <UserContextProvider>
        <FormValueContextProvider>
          <WebSocketProvider>
          <ApplogProvider>
              <LandingPage />
            </ApplogProvider>
          </WebSocketProvider>
        </FormValueContextProvider>
      </UserContextProvider>
    </div>
  );
}
