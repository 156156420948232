import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"; // Import Chart.js library

ChartJS.register(ArcElement, Tooltip, Legend);


export const DoughNut = ({ awsCount, gcpCount, azureCount }) => {

  const data = {
    labels: ["AWS", "GCP", "Azure"],
    datasets: [
      {
        label: "Number of Products in Cloud",
        data: [awsCount, gcpCount, azureCount],
        backgroundColor: ["#FD9800", "#E94134", "#0376D4"],
        borderWidth: 1,
      },
    ],
  };

  
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    cutoutPercentage: 70,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          boxWidth: 15, // Adjust the legend width
        },
      },
      title: {
        display: true,
        text: "Number of Active Products using each Cloud",
      },
    },
  };

  return (
    <div style={{ display: "flex" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughNut;
