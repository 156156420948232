import React, { cloneElement, useContext, useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Divider,
  Typography,
  Tooltip,
  Spin,
  Table,
  Space,
} from "antd";
import axios from "axios";
import { RightCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import "../../Pages/UserProfile/UserProfile.css";
import Title from "antd/es/typography/Title";
import { UserContext } from "../../Context/UserContext/UserContext";

const { Text } = Typography;

const ActiveRecents = () => {
  const [activeLastTwo, setactiveLastTwo] = useState([]);
  const [activeNames, setactiveNames] = useState([]);
  const [links, setLinks] = useState([]);
  const [loading, setloading] = useState(false);
  const { user } = useContext(UserContext);
  const userMail = user.email;
  const baseURL = "https://z03o4eag1m.execute-api.us-east-1.amazonaws.com";

  useEffect(() => {
    const fetchActiveProducts = async () => {
      setloading(true);
      let activePaylaod = {};
      activePaylaod = {
        method: "get",
        what_to_get: "productdetails",
        username: userMail,
        status: "InProgress",
      };
      try {
        const activeResponse = await axios.post(
          // `${process.env.REACT_APP_API_URL}/Prod/get`,
          `${baseURL}/Prod/get`,
          JSON.stringify(activePaylaod)
        );
        
        const activedata = activeResponse.data.body;
        const sliced = activedata.slice(-2);
        setactiveLastTwo(sliced);
        const productNames = sliced.map((item) => item.name);
        
        setactiveNames(productNames);
        
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchActiveProducts();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const fetchDataPayload = {
        method: "get",
        what_to_get: "endpoint",
      };
      try {
        const response = await axios.post(
          // `${process.env.REACT_APP_API_URL}/Prod/get`,
          `${baseURL}/Prod/get`,
          JSON.stringify(fetchDataPayload)
        );
        const products = JSON.parse(response.data.body);
        const filteredProducts = products.filter((item) =>
          activeNames.includes(item.productname)
        );
        
        

        const formattedData = filteredProducts.map((item, index) => {
          const { productname, frontendurl, backendurl, repourl } = item;
          return {
            name: productname,
            frontendLink:
              frontendurl && frontendurl.length > 0 ? frontendurl[0] : [],
            backendLinks: backendurl && backendurl.length > 0 ? backendurl : [],
            repourl: repourl,
          };
        });
        setLinks(formattedData);
        setloading(false);
        
      } catch (error) {
        console.log ("Error fetching",error);
      }
    };
    fetchData();
  }, [activeLastTwo]);

  if (activeLastTwo.length === 0) {
    return <p>No products to display.</p>;
  }

  const dataSource = links.map((product) => ({
    key: product.name,
    name: product.name,
    frontend_link: product.frontendLink,
    backend_links: product.backendLinks,
    repo_link: product.repourl,
  }));

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Links",
      key: "links",
      render: (text, record) => (
        <div>
          {record.frontend_link.length >0 &&
            (
              <p>
                <strong>Frontend Link:</strong>{" "}
                <a target = "_blank" href={record.frontend_link}>{record.frontend_link}</a>
              </p>
            )}

          {record.backend_links.length > 0 &&
            record.backend_links.map((link, index) => (
              <p>
                <strong>Backend Link {index+1}: </strong>
                <a
                  key={index}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link}
                  {index !== record.backend_links.length - 1 && ", "}
                </a>{" "}
              </p>
            ))}
          <p>
            
            <strong>Repository Link:   </strong>  <a target="_blank" href={record.repo_link}>{record.repo_link}</a>
          </p>
        </div>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <Spin size="medium" />
      ) : (
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          bordered
        />
      )}
    </>
  );
};

const CardGrid = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={21} md={24} lg={24} xl={24}>
          <Card
            title="Recent Products"
            extra={<a href="/products-lists">Show All Products</a>}
          >
            <ActiveRecents />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Product Details"
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
      </Modal>
    </>
  );
};

export default CardGrid;
