import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: "right",
      labels: {
        padding: 10, // Adjust the padding between legend items
        boxWidth: 20, // Adjust the width of the legend item boxes
        fontSize: 12,
      },
    },
    title: {
      display: true,
      text: "Product Created over the time",
    },
  },
};

const getProductsByMonth = (combinedResponse) => {
  // Initialize an array with 12 zeros, one for each month.
  const productsByMonth = Array(12).fill(0);

  // Iterate through the combinedResponse and count products by month.
  combinedResponse.forEach((product) => {
    const date = new Date(product.date_time);
    const month = date.getMonth() + 1; // Adjust for months starting from 1.
    productsByMonth[month - 1]++;
  });

  return productsByMonth;
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Sept",
  "Oct",
  " Nov",
  "Dec",
];

const BarChart = ({ combinedResponse }) => {
  const data = {
    labels,
    datasets: [
      {
        label: "Number of Prods",
        data: getProductsByMonth(combinedResponse),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  return <Bar options={options} data={data} />;
};

export default BarChart;
