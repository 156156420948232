import React from "react";
import LoginContainer from "../../SSO/LoginContainer";
import { BrowserRouter } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import SideNav from "../../components/SideNav/SideNav";
import WebSocketClient from "../../components/WebSocketClient/WebSocketClient";


const LandingPage = () => {
  
  return (
    <div>
      <BrowserRouter basename="/">
        <LoginContainer>
          {/* <WebSocketClient /> */}
          <Navbar />
          <SideNav />
        </LoginContainer>
      </BrowserRouter>
    </div>
  );
};

export default LandingPage;
