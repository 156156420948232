export const calculateCost = (formValues) => {
  let totalCost = 0;
  let frontendCost=0;
  let backendCost=0;
  if (formValues.cloudProvider === "aws") {
    totalCost = 0;
    if (formValues.frontendAws === "Yes") {
      if (formValues.frontendServiceAws === "aws_s3_cf") {
        frontendCost = frontendCost + 5;

      } else if (formValues.frontendServiceAws === "aws_appruner")
        frontendCost = frontendCost + 20;
    }
    console.log (frontendCost);
    if (formValues.backendAws === "Yes") {
      if (formValues.backendServiceAws === "aws_apprunner") {
        backendCost = backendCost + 20;
      } else if (formValues.backendServiceAws === "aws_lambda_ecr") {
        backendCost = backendCost + 5;
      }

      if (formValues.microservicesAws === "Yes") {
        if (formValues.backendServiceAws === "aws_apprunner") {
          const msCost = formValues.backendMicroservicesAws * 20;
          backendCost = backendCost + msCost;
        } else if (formValues.backendServiceAws === "aws_lambda_ecr") {
          const msCost = formValues.backendMicroservicesAws * 5;
          backendCost = backendCost + msCost;
        }
      }
      if (formValues.dbTypeAws !== "No") {
        if (formValues.dbTypeAws === "mysql") {
          backendCost = backendCost + 10;
        } else if (formValues.dbTypeAws === "psql") {
          backendCost = backendCost + 10;
        }
      }
    }
    totalCost = frontendCost +backendCost;

    if ( formValues.frontendAws === "No"){
      totalCost = totalCost - frontendCost;
    }
    if (formValues.backendAws === "No") {
      totalCost = totalCost -backendCost;
    }
  }
  if (formValues.cloudProvider === "gcp") {
    totalCost = 0;
    if (formValues.frontendGcp === "Yes") {
      if (formValues.frontendServiceGcp === "gcs-cdn") {
        totalCost = totalCost + 5;
      } else if (formValues.frontendServiceGcp === "cloudrun") {
        totalCost = totalCost + 15;
      }
    }
    if (formValues.backendGcp === "Yes") {
      if (formValues.backendServiceGcp === "gcp_cloud_function")
        totalCost = totalCost + 5;
      else if (formValues.backendServiceGcp === "gcp_cloud_run")
        totalCost = totalCost + 15;
      if (formValues.microservicesGcp === "Yes") {
        if (formValues.backendServiceGcp === "gcp_cloud_function") {
          const msCost = formValues.backendMicroservicesGcp * 5;
          totalCost = totalCost + msCost;
        } else if (formValues.backendServiceGcp === "gcp_cloud_run") {
          const msCost = formValues.backendMicroservicesGcp * 15;
          totalCost = totalCost + msCost;
        }
      }
      if (formValues.dbTypeGcp !== "No") {
        if (formValues.dbTypeGcp === "mysql") {
          totalCost = totalCost + 10;
        } else if (formValues.dbTypeGcp === "psql") {
          totalCost = totalCost + 10;
        }
      }
    } else if (
      formValues.backendGcp === "No" &&
      formValues.frontendGcp === "No"
    ) {
      totalCost = totalCost * 0;
    }
  }
  if (formValues.cloudProvider === "azure") {
    totalCost = 0;
    if (formValues.frontendAzure === "Yes") {
      formValues.frontendServiceAzure === "azure_webapp"
        ? (totalCost = totalCost + 25)
        : (totalCost = totalCost + 10);
    }
    if (formValues.backendAzure === "Yes") {
      if (formValues.backendServiceAzure === "azure_webapp") {
        totalCost = totalCost + 25;
      } else if (formValues.backendServiceAzure === "azure_functions_zip") {
        totalCost = totalCost + 10;
      }
      if (formValues.microservicesAzure === "Yes") {
        if (formValues.backendServiceAzure === "azure_webapp") {
          const msCost = formValues.backendMicroservicesAzure * 25;
          totalCost = totalCost + msCost;
        } else if (formValues.backendServiceAzure === "azure_functions_zip") {
          const msCost = formValues.backendMicroservicesAzure * 10;
          totalCost = totalCost + msCost;
        }
      }
      if (formValues.dbTypeAzure !== "No") {
        if (formValues.dbTypeAzure === "mysql") {
          totalCost = totalCost + 10;
        } else if (formValues.dbTypeAzure === "psql") {
          totalCost = totalCost + 10;
        }
      }
    }
  }

  return totalCost;
};
