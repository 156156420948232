// export const home = "http://localhost:3000/";
export const home = "https://hicloud.hashedin.com/";
const Deloitte2 = { 
  ClientId: "2v13550dfl3l87ak0on5168aq4",
  loginUrl: `https://hicloud.auth.us-east-1.amazoncognito.com//login?client_id=2v13550dfl3l87ak0on5168aq4&response_type=code&scope=email+openid+profile&redirect_uri=${home}`,
  tokenEndPoint:
    "https://hicloud.auth.us-east-1.amazoncognito.com/oauth2/token",
  logoutUrl: `https://hicloud.auth.us-east-1.amazoncognito.com/logout?client_id=2v13550dfl3l87ak0on5168aq4&response_type=code&redirect_uri=${home}`,
};

export const activeAWS = Deloitte2; 

function isValid(token) {  
  const claim = parseJWTToken(token);

  if (!claim) {
    return false;
  }

  const currentSeconds = Math.floor(new Date().valueOf() / 1000);

  return currentSeconds < claim.exp;
}

function parseJWTToken(token) {
  if (!token) return null;
  const [, body] = token.split(".");
  if (!body || body === undefined) return null;
  
  try {
    return JSON.parse(atob(body));
  } catch (e) {
    return null;
    
  }
}

function getAuthCode() {
  let authCode = null;
  if (window.location.search.indexOf("code=") !== -1) {
    authCode = window.location.search.split("code=")[1];
  }
  return authCode;
}

function _formData(jsonData) {
  const data = new FormData();

  for (let i in jsonData) {
    data.append(i, jsonData[i]);
  }

  return new URLSearchParams([...data.entries()]);
}

function persist(tokenData) {
  localStorage.setItem("API_TOKEN", tokenData?.id_token);
  localStorage.setItem("REFRESH_TOKEN", tokenData?.refresh_token);
}

function load() {
  return {
    id_token: localStorage.getItem("API_TOKEN"),
    refresh_token: localStorage.getItem("REFRESH_TOKEN"),
  };
}

async function fetchToken(authCode) {
  let value = null;
  const response = await fetch(activeAWS.tokenEndPoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: _formData({
      grant_type: "authorization_code",
      client_id: activeAWS.ClientId,
      redirect_uri: home,
      code: authCode,
    }),
    mode: "cors",
  });
  if (response.ok) {
    value = await response.json();
  }

  return value;
}

async function refresh() {
  let tokenData = load();
  if (!tokenData.refresh_token) {
    localStorage.setItem("REFRESH_TOKEN", "");
    localStorage.setItem("API_TOKEN", "");
    return null;
  }

  const response = await fetch(activeAWS.tokenEndPoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: _formData({
      grant_type: "refresh_token",
      client_id: activeAWS.ClientId,
      refresh_token: tokenData?.refresh_token,
    }),
    mode: "cors",
  });

  if (!response.ok) {
    localStorage.setItem("REFRESH_TOKEN", "");
    localStorage.setItem("API_TOKEN", "");
    return null;
  }

  tokenData = await response.json();
  localStorage.setItem("API_TOKEN", tokenData?.id_token);
  return tokenData;
}

export async function Login() {
  const authCode = getAuthCode();
  let loggedIn = false,
    user = null,
    tokenData = null;
  if (authCode != null) {
    // window.history.replaceState({}, document.title, HOME);
    tokenData = await fetchToken(authCode);
    persist(tokenData);
  } else {
    tokenData = load();
    loggedIn = isValid(tokenData?.id_token);
    if (!loggedIn && tokenData?.refresh_token)
      tokenData = await refresh(tokenData?.refresh_token);
  }
  // await formatData(tokenData?.id_token);
  loggedIn = isValid(tokenData?.id_token);
  user = parseJWTToken(tokenData?.id_token);
  // emit(user);
  return { loggedIn, tokenData, user };
}

export function signOut() {
  localStorage.setItem("API_TOKEN", null);
  localStorage.setItem("REFRESH_TOKEN", null);
  localStorage.setItem("ROLE", null);
  window.location.replace(activeAWS.logoutUrl);
  return true;
}
